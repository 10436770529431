import React, { useEffect, useState } from 'react';
import { Modal, Tab } from 'react-bootstrap';
// import Decision from './Decision';
import DecisionV1 from './DecisionV1';
import ClosePosition from './ExitPosition/ExitPosition';
import UpdatePosition from './UpdatePosition/UpdatePosition';
import Repeat from './Repeat';
import OpenDecision from './OpenPosition/OpenDecision';
import EndNode from './EndNode/EndNode';
import Tags from './Tags/Tags';
const StrategyItems = (props) => {
    const [activeTab, setActiveTab] = useState('decision');
    const [showModal, setShowModal] = useState('');
    const [isStateUpdate, setIsStateUpdate] = useState(false);
    const handleTabSelect = (selectedTab) => {
        setActiveTab(selectedTab);
    };
    useEffect(() => {
        setShowModal(props.show);
        setActiveTab('repeat');
        // props.setBuildJSON(props.buildJSON);
        props.setUpdateNodes(true);
        setIsStateUpdate(true);
    }, [props])

    const handleClose = () => {
        setShowModal(false);
    };

    const handleShow = () => {
        setShowModal(true);
    };

    return (
        <>
            {props.show && <Modal show={props.show} onHide={props.handleClose} className='modal-lg'>
                {/* <Modal.Header closeButton>
                </Modal.Header> */}
                <Modal.Body>
                    <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
                        <div className='row'>
                            <div className='col-md-12'>
                                {props.selectedItem == 'decision' && <DecisionV1
                                    handleClose={props.handleClose}
                                    clickStr={props.clickStr}
                                    type="decision"
                                    buildJSON={props.buildJSON}
                                    setBuildJSON={props.setBuildJSON}
                                    iterateCnt={props.iterateCnt}
                                    setIterateCnt={props.setIterateCnt}
                                    parentNode={props.parentNode}
                                    setParentNode={props.setParentNode}
                                    nodeKey={props.nodeKey}
                                    conditionCount={props.conditionCount}
                                    setConditionCount={props.setConditionCount}
                                    id={props.id}
                                    openPositions={props.openPositions}
                                    tagNames={props.tagNames}
                                />}
                                {props.selectedItem == 'open-position' && <OpenDecision
                                    handleClose={props.handleClose}
                                    clickStr={props.clickStr}
                                    type="open-position"
                                    buildJSON={props.buildJSON}
                                    setBuildJSON={props.setBuildJSON}
                                    iterateCnt={props.iterateCnt}
                                    setIterateCnt={props.setIterateCnt}
                                    parentNode={props.parentNode}
                                    setParentNode={props.setParentNode}
                                    nodeKey={props.nodeKey}
                                    id={props.id}
                                    entryCnt={props.entryCnt}
                                    setEntryCnt={props.setEntryCnt}
                                    openPositions={props.openPositions}
                                    setOpenPositions={props.setOpenPositions}
                                />}
                                {props.selectedItem == 'close-position' && <ClosePosition
                                    handleClose={props.handleClose}
                                    clickStr={props.clickStr}
                                    type="close-position"
                                    buildJSON={props.buildJSON}
                                    setBuildJSON={props.setBuildJSON}
                                    iterateCnt={props.iterateCnt}
                                    setIterateCnt={props.setIterateCnt}
                                    parentNode={props.parentNode}
                                    setParentNode={props.setParentNode}
                                    nodeKey={props.nodeKey}
                                    id={props.id}
                                    closeCnt={props.closeCnt}
                                    setCloseCnt={props.setCloseCnt}
                                    openPositions={props.openPositions}
                                    setOpenPositions={props.setOpenPositions}
                                />}
                                {props.selectedItem == 'exist-position' && <UpdatePosition
                                    handleClose={props.handleClose}
                                    clickStr={props.clickStr}
                                    type="exist-position"
                                    buildJSON={props.buildJSON}
                                    setBuildJSON={props.setBuildJSON}
                                    iterateCnt={props.iterateCnt}
                                    setIterateCnt={props.setIterateCnt}
                                    parentNode={props.parentNode}
                                    setParentNode={props.setParentNode}
                                    nodeKey={props.nodeKey}
                                    id={props.id}
                                    updateCnt={props.updateCnt}
                                    setUpdateCnt={props.setUpdateCnt}
                                    openPositions={props.openPositions}
                                />}
                                {/* {props.selectedItem == 'exist-position' && <h1>Exists Content</h1>} */}
                                {props.selectedItem == 'notification' && <h1>Notification Content</h1>}
                                {/* {props.selectedItem == 'tags' && <h1>Tags</h1>} */}

                                {props.selectedItem == 'tags' && <Tags
                                    handleClose={props.handleClose}
                                    clickStr={props.clickStr}
                                    type={props.selectedItem}
                                    buildJSON={props.buildJSON}
                                    setBuildJSON={props.setBuildJSON}
                                    iterateCnt={props.iterateCnt}
                                    setIterateCnt={props.setIterateCnt}
                                    parentNode={props.parentNode}
                                    setParentNode={props.setParentNode}
                                    nodeKey={props.nodeKey}
                                    conditionCount={props.conditionCount}
                                    id={props.id}
                                />}

                                {props.selectedItem == 'repeat' && <Repeat
                                    handleClose={props.handleClose}
                                    clickStr={props.clickStr}
                                    type="repeat"
                                    buildJSON={props.buildJSON}
                                    setBuildJSON={props.setBuildJSON}
                                    iterateCnt={props.iterateCnt}
                                    setIterateCnt={props.setIterateCnt}
                                    parentNode={props.parentNode}
                                    setParentNode={props.setParentNode}
                                    nodeKey={props.nodeKey}
                                    conditionCount={props.conditionCount}
                                    id={props.id}
                                />}
                                {props.selectedItem == 'end' && <EndNode
                                    handleClose={props.handleClose}
                                    clickStr={props.clickStr}
                                    type="end"
                                    buildJSON={props.buildJSON}
                                    setBuildJSON={props.setBuildJSON}
                                    iterateCnt={props.iterateCnt}
                                    setIterateCnt={props.setIterateCnt}
                                    parentNode={props.parentNode}
                                    setParentNode={props.setParentNode}
                                    nodeKey={props.nodeKey}
                                    conditionCount={props.conditionCount}
                                    id={props.id}
                                />}
                            </div>
                        </div>
                    </Tab.Container>
                    <button className='hide' onClick={props.handleClose}>Close</button>
                </Modal.Body>
            </Modal>}
        </>
    );
};

export default StrategyItems;