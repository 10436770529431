import React, { useEffect, useRef, useState } from 'react';
import { Nav, Container, Row, Col, Button, Card } from 'react-bootstrap';
import '../home/Home.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faChevronLeft, faChevronRight, faExclamation, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import activity1 from '../../assets/images/activity1.png';
import activity2 from '../../assets/images/activity2.png';
import activity3 from '../../assets/images/activity3.png';
import { API_URL } from '../../config';
import banner from "../../assets/images/banner.svg";
import profit from "../../assets/images/profit.svg";
import win from "../../assets/images/win.svg";
import calendar from "../../assets/images/calendar.svg";
import { Bar, Chart } from 'react-chartjs-2';

function LeftNav(props) {
    const [firstName, setFirstName] = useState(localStorage.getItem("user_details") ? JSON.parse(localStorage.getItem("user_details")).name : 'John Mack');
    const [dashboardData, setDashboardData] = useState({});
    const navigate = useNavigate();
    const [chartData, setChartData] = useState({});
    const [profitableStrategies, setProfitableStrategies] = useState({});
    const chartRef = useRef(null);

    const redirectToPage = (endPoint) => {
        // Redirect to a different page 
        navigate(endPoint);
        props.changeUrl(!props.isUpdate);
    };

    const DrawActivities = ({ card }) => {
        return (
            <Col md={4}>
                <Card style={{ padding: '10px', background: '#FCFCFC', border: 'none' }}>
                    <div className="d-flex align-items-center">
                        <Card.Img variant="top"
                            src={card.id == 1 ? activity1 : card.id == 2 ? activity2 : activity3}
                            style={{ width: '50px' }} />
                        <div className="flex-grow-1">
                            <Card.Body>
                                <Card.Title className='text-primary'>{card.title}</Card.Title>
                                <Card.Text>
                                    {card.text}
                                </Card.Text>
                            </Card.Body>
                        </div>
                    </div>
                </Card>
            </Col>
        );
    };
    const DrawHighlights = ({ card }) => {
        return (
            <Col md={3} >
                <Card style={{ padding: '10px', background: '#FCFCFC', border: 'none' }}>
                    <div className="d-flex align-items-center">
                        <h1 style={{ 'color': 'var(--secondary-color)' }}>
                            {card.value}
                            +
                        </h1>
                        <div className="flex-grow-1">
                            <Card.Body>
                                <Card.Title className='text-muted'>{card.title}</Card.Title>
                                <Card.Text>
                                    {card.text}
                                </Card.Text>
                            </Card.Body>
                        </div>
                    </div>
                </Card>
            </Col>
        );
    };
    const DrawToday = ({ card }) => {
        return (
            <Col md={12} className='mt-3' >
                <Card className='dashboard-card' style={{ padding: '10px', background: '#FCFCFC', border: 'none' }}>
                    <div className="d-flex align-items-center">
                        <Row>
                            <Col md={1}>
                                <FontAwesomeIcon className='text-warning card-today-icon' icon={faExclamation} />
                            </Col>
                            <Col md={11}>
                                <Row>
                                    <Col md={10}>
                                        <Card.Title className='text-primary'>
                                            {card.title}</Card.Title>
                                    </Col>
                                    <Col md={2}>
                                        <h6 className='text-muted fw-normal'>{card.time}</h6>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={11} className='offset-md-1'>
                                <div className="flex-grow-1">
                                    <Card.Body className='p-1 text-muted'>
                                        <Card.Text>
                                            {card.text}
                                        </Card.Text>
                                    </Card.Body>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Card>
            </Col>
        );
    };

    useEffect(() => {
        fetch(`${API_URL}/dashboard/${JSON.parse(localStorage.getItem("user_details")).email}`, {
            "Content-Type": "application/json"
        }
        )
            .then(response => {
                // Check if the request was successful (status code 200)
                if (response.ok) {
                    // Parse the response data as JSON
                    return response.json();
                } else {
                    throw new Error(`Request failed with status code ${response.status}`);
                }
            })
            .then(res => {
                setDashboardData(res.data);
                setChartData(res.data.chart_data);
                if (res.data.chart_data) {
                    const barOptions = {
                        indexAxis: 'x',
                        borderRadius: 6,
                        scales: {
                            x: {
                                display: true,
                                grid: {
                                    display: false // Hide vertical gridlines
                                }
                            },
                            y: {
                                display: true,
                                grid: {
                                    display: true,
                                    color: '#f6f6f5',
                                }
                            },
                        },
                        plugins: {
                            legend: {
                                display: false, // Hide legend
                            },
                        },
                        layout: {
                            padding: 0, // Reduce padding
                        },
                        elements: {
                            bar: {
                                barThickness: 10,
                            }
                        }
                    };
                    const ctx = document.getElementById('weekly-chart');
                    new Chart(ctx, {
                        type: 'bar',
                        data: res.data.chart_data[0],
                        options: barOptions
                    });
                    const ctx2 = document.getElementById('weekly-chart2');
                    new Chart(ctx2, {
                        type: 'bar',
                        data: res.data.chart_data[1],
                        options: barOptions
                    });
                    const ctx3 = document.getElementById('weekly-chart3');
                    new Chart(ctx3, {
                        type: 'bar',
                        data: res.data.chart_data[2],
                        options: barOptions
                    });
                }
            })
            .catch(error => {
                console.error("Request failed:", error);
            });

        const weeklyData = {
            labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            datasets: [{
                label: 'Weekly P&L',
                backgroundColor: '#ffc833',
                borderColor: '#ffc833',
                borderWidth: 0,
                data: [10, 20, 30, 25, 35, 15, 5] // Sample data, replace with your own
            }]
        };

        setChartData(weeklyData);
        if (chartRef && chartRef.current && chartRef.current.chartInstance) {
            chartRef.current.chartInstance.destroy();
        }
    }, [])

    return (
        <Row>
            <Col xs={12}>
                <Row>
                    <Col md={12}>
                        <img src={banner} alt='banner' width={"100%"} className='main-banner' />
                    </Col>
                    {/* <Col md={12}>
                        <Row>
                            {dashboardData.activities?.map(card => (
                                <DrawActivities key={card.id} card={card} />
                            ))}
                        </Row>
                    </Col>
                    <Col md={12} className='mt-4 mb-4'>
                        <Row>
                            {dashboardData.highlights?.map(card => (
                                <DrawHighlights key={card.id} card={card} />
                            ))}
                        </Row>
                    </Col>
                    <Col md={8} className='mt-4 mb-4'>
                        <Row>
                            <Col md={12}>
                                <h3 className='text-primary'>TODAY</h3>
                                <h6 className='text-muted fw-normal'>1 April 2023, 10:40</h6>
                            </Col>
                        </Row>
                        <Row>
                            {dashboardData.today?.map(card => (
                                <DrawToday key={card.id} card={card} />
                            ))}
                        </Row>
                    </Col>
                    <Col md={4} className='mt-4 mb-4'>
                        <Row>
                            <Col md={12}>
                                <h3 className='text-primary'>Watchlist</h3>
                                <h6 className='text-muted fw-normal'>Lorem Ipsum Dolor</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Card style={{ padding: '10px', background: '#FCFCFC', border: 'none' }} className='dashboard-card mt-3'>
                                <div className="mt-2 mb-2">
                                    <Row className='pt-2 pb-2'>
                                        <Col md={3}>
                                            <Card.Title className='text-muted fs-6'>SYMBOL</Card.Title>
                                        </Col>
                                        <Col md={3}>
                                            <Card.Title className='text-muted fs-6'>LAST</Card.Title>
                                        </Col>
                                        <Col md={3}>
                                            <Card.Title className='text-muted fs-6'>DAY %</Card.Title>
                                        </Col>
                                        <Col md={3}>
                                            <Card.Title className='text-muted fs-6'>DAY</Card.Title>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {dashboardData.watchlist?.map(card => (
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={3}>
                                                        <span className='text-primary card-today-items'>{card.symbol}</span>
                                                    </Col>
                                                    <Col md={3}>
                                                        <span className='text-muted card-today-items'>{card.last}</span>
                                                    </Col>
                                                    <Col md={3}>
                                                        <span className={!card.day_per_pos ? 'text-danger card-today-items' : 'text-success card-today-items'}>{card.day_percent}</span>
                                                    </Col>
                                                    <Col md={3}>
                                                        <span className={!card.day_pos ? 'text-danger card-today-items' : 'text-success card-today-items'}>{card.day}</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </Card>
                        </Row>
                    </Col> */}
                    <Col md={12}>
                        <Row>
                            <Col md={5}>
                                {dashboardData.watchlist_data?.map(card => (
                                    <>
                                        <Card className='dashboard-card'>
                                            <div className="d-flex align-items-center">
                                                <div className={card.type === "profit" ? "img-wrapper-info" : card.type === "winning" ? "img-wrapper-win" : "img-wrapper-calendar"} >
                                                    <Card.Img variant="top"
                                                        src={card.type === "profit" ? profit : card.type === "winning" ? win : calendar}
                                                        style={{
                                                            width: '20px'
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex-grow-1 text-left text-grey">
                                                    <Card.Body className='card-body'>
                                                        <Card.Title className='text-secondary'>{card.title}</Card.Title>
                                                        <Card.Text className='card-value'>
                                                            {card.value}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </div>
                                            </div>
                                        </Card>
                                    </>
                                ))}
                            </Col>
                            <Col md={7}>
                                <div className='dashboard-chart-wrapper'>
                                    <div id="carouselExampleFade" class="carousel slide carousel-fade">
                                        <div class="carousel-inner">
                                            <div class="carousel-item active">
                                                <canvas ref={chartRef} id="weekly-chart" style={{
                                                    maxHeight: "250px"
                                                }}
                                                ></canvas>
                                            </div>
                                            <div class="carousel-item">
                                                <canvas ref={chartRef} id="weekly-chart2" style={{
                                                    maxHeight: "250px"
                                                }}
                                                ></canvas>
                                            </div>
                                            <div class="carousel-item">
                                                <canvas ref={chartRef} id="weekly-chart3" style={{
                                                    maxHeight: "250px"
                                                }}
                                                ></canvas>
                                            </div>
                                        </div>
                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev" fdprocessedid="2vnpi7">
                                            <span class="carousel-control-prev-icon" aria-hidden="true">
                                                <FontAwesomeIcon className='arrow-icon' icon={faChevronLeft} />
                                            </span>
                                        </button>
                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next" fdprocessedid="btn5y">
                                            <span class="carousel-control-next-icon" aria-hidden="true">
                                                <FontAwesomeIcon className='arrow-icon' icon={faChevronRight} />
                                            </span>
                                        </button>
                                    </div>


                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} className='text-left'>
                        <>
                            <Card className='m-3 p-3'>
                                <h5 className='font-700 mb-4'>Profitable Strategies</h5>
                                <Row>
                                    {dashboardData.profitable_strategies?.map((item, index) => (
                                        <>
                                            <Col md={3} sm={4} xs={12}>
                                                <Card className='profitable-strategies-wrapper'>
                                                    <div
                                                        className="profitable-strategies text-left"
                                                    >
                                                        <h6 className='p-0'>
                                                            {item.subscribed && <span className='text-muted p_card-label'>Subscribed</span>}
                                                        </h6>
                                                        <h5 className='pt-2'>
                                                            {item.strategy_name}
                                                        </h5>
                                                        <p className='pt-2'>
                                                            {item.description}
                                                        </p>
                                                        <div>
                                                            {<Button variant="contained" style={{
                                                                width: "100%"
                                                            }} className='btn btn-primary'>
                                                                {/* {!ite+m.isSubscribed && <Button variant="contained" className='btn btn-primary' onClick={() => subscribe(item)}> */}
                                                                Run Strategy
                                                            </Button>}
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </>
                                    ))}
                                    {dashboardData.profitable_strategies?.map((item, index) => (
                                        <>
                                            <Col md={3} sm={4} xs={12}>
                                                <Card className='profitable-strategies-wrapper'>
                                                    <div
                                                        className="profitable-strategies text-left"
                                                    >
                                                        <h6 className='p-0'>
                                                            {item.subscribed && <span className='text-muted p_card-label'>Subscribed</span>}
                                                        </h6>
                                                        <h5 className='pt-2'>
                                                            {item.strategy_name}
                                                        </h5>
                                                        <p className='pt-2'>
                                                            {item.description}
                                                        </p>
                                                        <div>
                                                            {<Button variant="contained" style={{
                                                                width: "100%"
                                                            }} className='btn btn-primary'>
                                                                {/* {!item.isSubscribed && <Button variant="contained" className='btn btn-primary' onClick={() => subscribe(item)}> */}
                                                                Run Strategy
                                                            </Button>}
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </>
                                    ))}
                                </Row>
                            </Card>
                        </>
                    </Col>
                </Row>
            </Col>
        </Row >
    );
}

export default LeftNav;
