import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Header from '../Header';
import Footer from '../Footer';
import { API_URL } from '../../config'
import Sidebar from '../Sidebar';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import PublishStrategyModal from '../PublishStrategyModal';

function MarketPlace() {

    const [data, setData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [showPublish, setShowPublish] = useState(false);
    const [currId, setCurrId] = useState("");

    const handlePublishModal = () => {
        setShowPublish(false);
    }

    useEffect(() => {
        fetch(`${API_URL}/marketplace/${JSON.parse(localStorage.getItem("user_details")).email}`, {
            "Content-Type": "application/json"
        }
        )
            .then(response => {
                setIsLoaded(true)
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(`Request failed with status code ${response.status}`);
                }
            })
            .then(res => {
                setData(res.data);
            })
            .catch(error => {
                console.error("Request failed:", error);
            });
    }, [])

    const SpriteImage = ({ caseNumber }) => {
        const getCaseClassName = () => {
            switch (caseNumber % 4) {
                case 0:
                    return 'case-0';
                case 1:
                    return 'case-1';
                case 2:
                    return 'case-2';
                case 3:
                    return 'case-3';
                // Add cases 3, 4, ..., 15 here with appropriate values
                default:
                    return 'case-1'; // Default to the first case
            }
        };

        const caseClassName = getCaseClassName();
        return <span className={`sprite-container ${caseClassName}`}></span>;
    }

    const subscribe = (item) => {
        setShowPublish(true)
        setCurrId(item.id)
    }
    return (
        <Container fluid style={{ background: '#efefef' }}>
            <Header />
            <Row style={{ background: '#fff' }}>
                <Col xs={1} style={{ padding: "0px", backgroundColor: "#f2f2f2", borderRight: "1px solid #EAEAEA" }}>
                    <Sidebar />
                </Col>
                <Col md={11}>
                    <h3 className='p-3'>MarketPlace</h3>
                    <div className='m-3' style={{ listStyle: 'none', padding: 0, paddingBottom: "35px" }}>
                        {data.length > 0 && data.map((item, index) => (
                            <div
                                className="strategy-card market-place text-left"
                            >
                                <h5 className='pt-3' style={{ marginLeft: '15px' }}>
                                    <SpriteImage caseNumber={index} />
                                    <span className='pl-3 pt-3 strategy-pub-name'> {item.strategy_name != "" ? item.strategy_name : 'Startegy - ' + (index + 1)} </span>
                                    <p>Owner: {item.user_name}</p>
                                </h5>
                                <div style={{ marginLeft: '15px' }}>
                                    {!item.isSubscribed && <Button variant="contained" className='btn btn-primary' onClick={() => subscribe(item)}>
                                        Subscribe
                                    </Button>}
                                    {item.isSubscribed && <span variant="contained" className='text-success small'>
                                        Subscribed
                                    </span>}
                                </div>
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
            {data.length === 0 && isLoaded && <div className='text-center bg-white' style={{ color: "#EAEAEA" }}>
                <h3>
                    No strategies found!
                </h3>
            </div>}
            {showPublish && <PublishStrategyModal
                type="subscribe"
                id={currId}
                showPublish={showPublish}
                setShowPublish={setShowPublish}
                handlePublishModal={handlePublishModal}
            />}
            <Footer />
        </Container >
    );
}

export default MarketPlace;
