import React, { useEffect, useState } from 'react';
import '../components/components.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
// import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import loginImg from '../assets/images/loginImg.png'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import { API_URL } from '../config'
import { OTP_REQUEST_URL } from '../config';
import { OTP_VALIDATE_URL } from '../config';
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import GoogleButton from 'react-google-button';

function Login({setLogin}) {


    const [mobNum, setMobNum] = useState('');
    const [OTP, setOTP] = useState('');
    const [wrongNumber, setWrongNumber] = useState('');
    const [otpError, setOtpError] = useState('');
    const [otpSucces, setOtpSuccess] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const navigate = useNavigate();
    const handleInputChange = (event) => {
        setMobNum(event.target.value);
    };
    const handleOTPChange = (event) => {
        setOTP(event.target.value);
    };
    const redirectToPage = (endPoint) => {
        // Redirect to a different page
        navigate(endPoint)
    };

    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationStatus, setVerificationStatus] = useState('');
    const [message, setMessage] = useState('');

    const handleSendOTP = () => {
        fetch(`${API_URL}/send_otp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ phoneNumber })
        })
            .then((response) => {
                //alert('Str:'+ JSON.stringify(response))
                //setMessage(response.data.message);
                setOtpSuccess("OTP sent successfully!");
                setOtpSent(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleVerifyOTP = () => {
       
        fetch(`${API_URL}/verify_otp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ phoneNumber, OTP })
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                  throw new Error('Error: ' + response.status);
                }
              })
            .then(data => {
                
                localStorage.setItem("user_details", JSON.stringify(data.data[0]))
                localStorage.setItem("login", true);
                setLogin(true)
                redirectToPage('/home');
              })
            .catch((error) => {
                console.error(error);
                setMessage('OTP verification failed');
            });
    };

    const isLogin = () => {
        if (localStorage.getItem("login") && localStorage.getItem("login") === "true" || (localStorage.getItem("login") && typeof localStorage.getItem("login") === 'boolean')) {
          return true;
        } else {
          return false;
        }
    }

    const googleLogin = useGoogleLogin({
        flow: "auth-code",
        onSuccess: async (codeResponse) => {
          //console.log(codeResponse.code);
          let postData = {
              "code": codeResponse.code,
            }

          const resp =  fetch(`${API_URL}/login`, {
              method: "POST",
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(postData)
            })
              .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                  throw new Error('Error: ' + response.status);
                }
              })
              .then(data => {
                
                localStorage.setItem("user_details", JSON.stringify(data.data[0]))
                localStorage.setItem("login", true);
                setLogin(true)
                redirectToPage('/home');
              })
              .catch(error => {
                console.log('Error:', error);
              });
        },
        onError: () => console.log("Login Failed")
      });

   const handleSuccess = (response) => {

    let userDetails = jwtDecode(response.credential);
    localStorage.setItem("user_details", JSON.stringify(userDetails))
   
    localStorage.setItem("login", true);
    //setLogin(true);
    setIsUpdate(!isUpdate);
    let postData = {
      "user_name": userDetails.name,
      "first_name": userDetails.given_name,
      "last_name": userDetails.family_name,
      "email": userDetails.email,
      "sso_login": 1,
    }

    fetch(`${API_URL}/login`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error: ' + response.status);
        }
      })
      .catch(error => {
        console.log('Error:', error);
      });
  };

  useEffect(() => {
    const isLogged = isLogin()
    if(isLogged){
        //alert('Logged in')
        redirectToPage('/home');
    }
    //setLogin(isLogin())
  }, [isUpdate, localStorage.getItem("login")])

  const handleFailure = (error) => {
    console.error('Google Sign-In Error:', error);
    //setLogin(false);
  };

    return (
        <div className='container-fluid'>
            <Row>
                <Col md={12} className='p-0'>
                    <Header />
                </Col>
            </Row>
            <Row>
                <Col md={7}>
                    <div className='text-center'>
                        <img src={loginImg} className='login-left-img' />
                    </div>
                </Col>
                <Col md={5} className='login-section-right' style={{ height: '85vh' }}>
                    { <div className='login-wrapper'>
                        <h2 className='text-center'>Login</h2>
                        <div className='row mt-4 text-center'>
                            <div className='col-md-12'>
                                <InputGroup size="md" className="mb-3">
                                    <InputGroup.Text id="inputGroup-sizing-sm">
                                        +91
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        value={phoneNumber}
                                        aria-label="Small"
                                        aria-describedby="inputGroup-sizing-sm"
                                        placeholder='Enter Mobile Number'
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                    />
                                </InputGroup>
                            </div>
                            <div className='col-md-12 text-center'>
                                <button onClick={handleSendOTP} className='btn btn-info get-otp'>Get OTP</button>
                            </div>
                            <div className='text-danger col-md-10  offset-md-1 text-left small'>{wrongNumber}</div>
                        </div>
                        <div className={otpSent ? 'show row mt-3' : 'hide row mt-3'}>
                            <div className=' col-md-12 text-center'>
                                <input
                                    type="password"
                                    value={OTP}
                                    placeholder='Enter the OTP'
                                    className='form-control small'
                                    onChange={(e) => setOTP(e.target.value)}
                                />
                            </div>
                            <div className=' col-md-12 text-left'>
                                <button onClick={handleVerifyOTP} className='btn btn-info get-otp form-control mt-3'>Validate OTP</button>
                            </div>
                            <div className='text-danger  col-md-10  offset-md-1 text-center mt-3'>{otpError}</div>
                            <div className='text-success  col-md-10  offset-md-1 text-center'>{otpSucces}</div>
                        </div>
                    </div> }
                    <div className="d-flex align-items-center justify-content-center gap-2 mb-3 pb-1 pb-md-2">
                  <span className="d-block login-or-line"></span>
                  <span className="text-uppercase">or</span>
                  <span className="d-block login-or-line"></span>
                </div>
                <div className="btn fs-6 gap-2 bg-transparent d-flex w-100 align-items-center justify-content-center" >
                    <GoogleButton onClick={googleLogin} />
                 </div>
                </Col>
            </Row>
            <Row className="login-footer">
                <Col md={12}>
                    <Footer className="login-footer" />
                </Col>
            </Row>
        </div>
    );
}

export default Login;