import React, { useState } from 'react';
import { Navbar, Nav, Container, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faUser } from '@fortawesome/free-regular-svg-icons';
import logo from '../assets/images/up1ne_logo.png'
import { faChevronDown, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import headerSvg from "../assets/images/file-list.svg"
import bellSvg from "../assets/images/bell.svg"
import ellipse from "../assets/images/ellipse.svg"
function Header(props) {

    //const navigate = useNavigate();

    const handleLogout = () => {
        window.google.accounts.id.disableAutoSelect();
        localStorage.setItem("login", false);
        localStorage.setItem("user_details", JSON.stringify({}));
        //props.setLogin(false)
        window.location.reload()
    };

    return (
        <nav class="navbar navbar-expand-lg bg-grey" style={{
            borderBottom: "1px solid #EAEAEA",
            height: "60px"
        }}>
            <div class="container-fluid">
                <a class="navbar-brand" href="#">
                    <button class="btn btn-link sidebar-toggle-btn" type="button" onClick={() => props.setExpanded(!props.expanded)} >
                        <ion-icon name="menu-outline" style={{ fontSize: "20px", float: "right", color: "#304982" }} ></ion-icon>
                    </button>
                </a>
                <span>
                    <div style={{ minWidth: "350px" }}>
                        <div class="row g-0">
                            <div class="col-md-2">
                                <div className='ellipse-img'>

                                    <img src={ellipse} class="img-fluid rounded-start " />
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="card-body">
                                    <h5 class="card-title text-left">Good morning,&nbsp;
                                        {localStorage.getItem("user_details") ? JSON.parse(localStorage.getItem("user_details")).name : 'User'}
                                    </h5>
                                    <p class="card-text text-left"><small class="text-body-secondary text-left">Have a look with your activity in dashboard.</small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
                <div class="collapse navbar-collapse" id="navbarNavDarkDropdown" style={{ flexDirection: "row-reverse" }}>
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown text-right" style={{
                            minWidth: "200px"
                        }}>
                            <div class="input-group">
                                <span class="input-group-text" style={{
                                    background: "#fff",
                                    border: "none",
                                    height: "44px"
                                }} id="basic-addon1">
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </span>
                                <input type="text" style={{
                                    border: "none"
                                }} class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                        </li>
                        <li class="nav-item dropdown text-right">
                            <button class="btn btn-white dropdown-toggle header-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <img className='header-img' height={"20px"} src={bellSvg} />
                            </button>
                            <ul class="dropdown-menu ">
                                <li><a class="dropdown-item" href="#">Action</a></li>
                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown text-right">
                            <button class="btn btn-white dropdown-toggle header-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <img className='header-img' height={"20px"} src={headerSvg} />
                            </button>
                            <ul class="dropdown-menu ">
                                <li><a class="dropdown-item" href="#">Action</a></li>
                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown text-right mr-0">
                            <button class="btn btn-white dropdown-toggle profile-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                {localStorage.getItem("user_details") === undefined && <FontAwesomeIcon icon={faUser} className='primary-text' />}
                                {localStorage.getItem("user_details") && <>
                                    <img src={JSON.parse(localStorage.getItem("user_details")).picture} className='profile-img' />
                                    <span style={{
                                        paddingLeft: "20px",
                                        textTransform: "capitalize"
                                    }}>{localStorage.getItem("user_details") ? JSON.parse(localStorage.getItem("user_details")).name : 'John Mack'}</span>
                                </>
                                }
                                <FontAwesomeIcon icon={faChevronDown} className='text-grey' style={{
                                    paddingLeft: "5px"
                                }} />
                            </button>
                            <ul class="dropdown-menu ">
                                <li><a class="dropdown-item" href="#">Action</a></li>
                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><a class="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleLogout(); }}>Logout</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Header;
