import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import decisionValues from "../../openPosition.json";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css'
const OpenDecisionInputs = (props) => {

    const [searchTerm, setSearchTerm] = useState('');
    // const [props.mainIndex, setprops.mainIndex] = useState('');
    const options = decisionValues.fieldData[props.label.split(",")[1]];
    // Filter the options based on the search term 
    const filteredOptions = (props.label.split(",")[1] !== "number" && props.label.split(",")[1] !== "dttm" && props.label.split(",")[1] !== "time" && props.label.split(",")[1] !== "text") ? options.filter(option =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState();

    const updateInput = (val, type) => {
        let preData = props.decisionValue;
        if (type == "date") {
            setSelectedDate(val);
            preData[props.mainIndex][props.index] = moment(val).format("MM-DD-YYYY HH:mm");
        } else if (type == "time") {
            setSelectedTime(val);
            preData[props.mainIndex][props.index] = moment(val).format("HH:mm");
        } else {
            preData[props.mainIndex][props.index] = val;
        }
        props.setDecisionValue(preData);
    }

    const handleSelectChange = (event) => {
        const { options } = event.target;
        const selectedValues = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedValues.push(options[i].value);
            }
        }
        let preData = props.decisionValue;
        preData[props.mainIndex][props.index] = event.target.value;
        props.setDecisionValue(preData);
    };

    useEffect(() => {
        let preData = localStorage.getItem('decisionValue');
        if (preData != null) {
            preData = JSON.parse(preData);
            if (preData[props.mainIndex] != undefined) {
                let newObj = {
                    [props.mainIndex]: preData[props.mainIndex]
                }
                props.setDecisionValue(newObj);
            }
        }
    }, []);

    return (
        <>
            <Form.Group as={Row} className='mt-2' >
                <span>
                    <Form.Label sm={2} className='font-weight-bold' style={{ fontWeight: '600', textTransform: "capitalize" }}>
                        {props.label?.split(",")[0]} </Form.Label>
                    {props.type == 'number' && <Col sm={10}>
                        <Form.Control type="number" value={props.decisionValue[props.mainIndex][props.index]}
                            onChange={e => updateInput(e.target.value)} placeholder="Enter a number" />
                    </Col>}
                </span>
                <span>
                    {props.type == 'search' && <Col sm={10}>
                        <input
                            type="text"
                            placeholder="Search"
                            size="sm"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            style={{
                                padding: '3px',
                                borderRadius: '5px',
                                border: '1px solid #EAEAEA',
                                width: '30%', display: 'none'
                            }}
                        />
                        <select className='form-control' onChange={e => updateInput(e.target.value)} value={props.decisionValue[props.mainIndex][props.index]}>
                            {filteredOptions.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </Col>}
                </span>
                <span>
                    {props.type == 'multiselect' && <Col sm={10}>
                        <Form.Select
                            multiple
                            value={props.decisionValue[props.mainIndex][props.index]}
                            onChange={handleSelectChange}
                        >
                            {filteredOptions.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>}
                </span>
                <span>
                    {props.type === 'select' && <Col sm={10}>
                        <Form.Select
                            value={props.decisionValue[props.mainIndex][props.index]}
                            onChange={e => updateInput(e.target.value)}
                        >
                            <option key="0" value="">
                                Select a vaue
                            </option>
                            {filteredOptions.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>}
                </span>
                <span>
                    {props.type == 'dttm' && <Col sm={10}>
                        <div className='date-time-picker'>
                            <DatePicker
                                selected={selectedDate}
                                // onChange={handleChange}
                                showTimeSelect
                                timeIntervals={5}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                className='form-control date-picker'
                                onChange={e => updateInput(e, "date")}
                            />
                        </div>
                    </Col>}
                </span>
                <span>
                    {props.type == 'time' && <Col sm={10}>
                        <div className='date-time-picker'>
                            <TimePicker
                                showHour
                                showSecond={false}
                                defaultValue={selectedTime}
                                onChange={e => updateInput(e, "time")}
                            />
                        </div>
                    </Col>}
                </span>
                <span>
                    {props.type == 'text' && <Col sm={10}>
                        <div>
                            <input type='text' className='form-control' onChange={(e) => updateInput(e.target.value)} />
                        </div>
                    </Col>}
                </span>
            </Form.Group>
        </>
    );
};

export default OpenDecisionInputs;
