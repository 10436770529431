// MyContext.js
import { createContext, useContext, useState } from 'react';

const MyContext = createContext();

export const MyProvider = ({ children }) => {
    const [decisionObject, setDecisionObject] = useState({});
    const [strategyObject, setStrategyObject] = useState({});
    const [updateObject, setUpdateObject] = useState({});

    return (
        <MyContext.Provider
            value={{
                decisionObject, setDecisionObject,
                strategyObject, setStrategyObject,
                updateObject, setUpdateObject
            }}>
            {children}
        </MyContext.Provider>
    );
};

export const useMyContext = () => useContext(MyContext);
