import React, { useState, useEffect, useCallback, useMemo } from "react";
import ReactFlow, {
    useNodesState,
    useEdgesState,
    addEdge,
} from "reactflow";
import "reactflow/dist/style.css";

import ColorSelectorNode from "./ColorSelectorNode";
import { Col, Row } from "react-bootstrap";
import { useMyContext } from "../../../context/MyContext";
import DecisionInputes from "../DecisionInputs/DecisionInputs";

const connectionLineStyle = { stroke: "#fff" };
const snapGrid = [20, 20];
// const nodeTypes = {
//     selectorNode: ColorSelectorNode
// };

const defaultViewport = { x: 0, y: 0, zoom: 1 };

const Flowchart = (propsData) => {
    const [nodes, setNodes, onNodesChange] = useNodesState(removeDuplicates(propsData.chartObj.value));
    const [edges, setEdges, onEdgesChange] = useEdgesState(removeDuplicates(propsData.chartObj.edgeObj));

    function removeDuplicates(array) {
        let seen = new Set();
        return array.filter(item => {
            let id = item.id;
            if (!seen.has(id)) {
                seen.add(id);
                return true;
            }
            return false;

        })
    }

    const nodeTypes = useMemo(() => {
        return {
            selectorNode: (props) => <ColorSelectorNode
                {...props}
                dataObj={propsData.data}
                isYes={nodes.reduce((acc, currentValue) => {
                    if (currentValue.id == props.id) {
                        return true;
                    }
                    return acc;
                }, false)}
                isNo={nodes.reduce((acc, currentValue) => {
                    if (currentValue.id == props.id) {
                        return true;
                    }
                    return acc;
                }, false)}
                setJsonKey={propsData.setJsonKey}
                setSelectedItem={propsData.setSelectedItem}
                handleOpenModal={propsData.handleOpenModal}
                setClickStr={propsData.setClickStr}
                setShowModal={propsData.setShowModal}
                // nodeNo={propsData.nodeNo}
                // setNodeNo={propsData.setNodeNo}
                updateNodes={props.updateNodes}
                setUpdateNodes={props.setUpdateNodes}
                setParentNode={propsData.setParentNode}
                setExpandedL={propsData.setExpandedL}
                setId={propsData.setId}
            />,
        };
    }, []);

    useEffect(() => {
        setNodes(removeDuplicates(propsData.chartObj.value));
        setEdges(propsData.chartObj.edgeObj);
    }, [propsData]);

    const onConnect = useCallback(
        (params) =>
            setEdges((eds) =>
                addEdge({ ...params, animated: true }, eds)
            ),
        []
    );

    function getAxis(obj, id, x, y) {
        function traverseTree(node) {
            if (node == null) {
                return '1'
            }
            let incomingId = node[Object?.keys(node)[0]]?.ID;
            // let lastDotIndex = node[Object?.keys(node)[0]]?.ID.lastIndexOf(".");
            // let incomingId = node[Object?.keys(node)[0]]?.ID.substring(0, lastDotIndex);
            if (incomingId == id) {
                node[Object?.keys(node)[0]].X = x;
                node[Object?.keys(node)[0]].Y = y;
                propsData.setBuildJSON(obj);
                return node[Object?.keys(node)[0]];
            }
            else {
                traverseTree(node[Object.keys(node)[0]]?.YES);
                if (node[Object.keys(node)[0]]?.NO != undefined && Object.keys(node[Object.keys(node)[0]]?.NO).length > 0) {
                    traverseTree(node[Object.keys(node)[0]]?.NO);
                }
            }
        }
        traverseTree(obj, id);
    }

    const onNodeDragStop = (event, node) => {
        getAxis(propsData.data, node.id, node.position.x, node.position?.y);
    };

    return (
        <>
            {/* <Row>
                <Col>
                    {decisionObject.decision?.map((object, index) => (
                        <div key={index}>
                            {Object.entries(object).map(([key, value]) => (
                                <>
                                    {(Object.keys(decisionObject.decisionValue).length > 0) && <DecisionInputes
                                        type={value}
                                        key={key}
                                        label={decisionObject.label[key]}
                                        decisionValue={decisionObject.decisionValue}
                                        setDecisionValue={decisionObject.setDecisionValue}
                                        index={key}
                                        mainIndex={decisionObject.mainIndex}
                                        selectedInput={decisionObject.selectedInput}
                                        openPositions={decisionObject.openPositions}
                                    />}
                                </>
                            ))}
                        </div>
                    ))}
                </Col>
            </Row> */}
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                connectionLineStyle={connectionLineStyle}
                onNodeDragStop={onNodeDragStop}
                snapToGrid={true}
                snapGrid={snapGrid}
                defaultViewport={defaultViewport}
                fitView={propsData.strategy_id ? true : false}
                // fitView
                attributionPosition="bottom-left"
            >
            </ReactFlow>
        </>
    );
};

export default Flowchart;

