import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../Strategy.css';
import UpdateInputs from './UpdateInputs';
import decisionValues from "../../openPosition.json";
import { useMyContext } from '../../../context/MyContext';

function UpdatePosition(props) {

    const [currentobj, setCurrentobj] = useState({});
    const [oldPosObj, setOldPosObj] = useState({});
    const [updatedObj, setUpdatedObj] = useState({});
    const [currentNode, setCurrentNode] = useState(false);
    const [templateData, setTemplateData] = useState({});
    let templateObj = decisionValues.templateObj;
    const [decisionValue, setDecisionValue] = useState({});
    const [inputItems, setInputItems] = useState([]);
    const [isUpdateposition, setIsUpdateposition] = useState(false);
    const [dataId, setDataId] = useState("");
    const [inputObjId, setInputObjId] = useState({});
    const [selectedInput, setSelectedInput] = useState({});
    const [dataObj, setDataObj] = useState({});
    const [isUpdateData, setIsUpdateData] = useState(false);
    const [adjustmentData, setAdjustmentData] = useState([]);
    const [selectedNodeData, setSelectedNodeData] = useState({
        VALUE_ID: "",
        data: {},
        currentPosId: ""
    });
    const [changedNodes, setChangedNodes] = useState([])
    const { updateObject, setUpdateObject } = useMyContext();

    const saveData = () => {
        if ((props.clickStr == 'start' || props.clickStr == 'YES' || props.clickStr == 'NO') && props.type == 'exist-position') {
            let count = props.updateCnt + 1;
            props.setUpdateCnt(count);
            let singleObject = { content: "" }
            for (const position in updatedObj) {
                const values = updatedObj[position];
                for (const field in values) {
                    const value = values[field];
                    if (value !== null) {
                        // singleObject[field] = value;
                        singleObject["content"] = singleObject["content"] + " " + value;
                    }
                }
            }
            const convertedObject = convertObject(updatedObj);
            function convertObject(originalObject) {
                const convertedObject = {
                    "UPDATE_POS": {},
                };
                let index = 1;
                for (const position in originalObject) {
                    convertedObject["UPDATE_POS"][`UPD_POS${index}`] = position;
                    convertedObject[`UPD_POS${index}`] = originalObject[position];
                    index++;
                }
                return convertedObject;
            }

            let json = {
                ['UPDATE' + count]: {
                    TMP_ID: 'UPDATE' + count,
                    CONTENT: singleObject,
                    UPDATES: convertedObject,
                    // UPDATES: updatedObj,
                    YES: {},
                    NO: {},
                    ID: props.clickStr == 'start' ? '1' : (props.id)
                },
                TYPE: "UPDATE",
                KEY: 'UPDATE' + count,
            }
            json = JSON.parse(JSON.stringify(json));
            if (props.clickStr === 'start') {
                props.setBuildJSON(json);
                let updateCnt = (props.iterateCnt + 1);
                props.setIterateCnt(updateCnt);
                props.setParentNode(json['UPDATE' + count]);
            } else if (props.clickStr === 'YES') {
                findAndUpdateObj(props, json, props.clickStr);
                props.setParentNode(json['UPDATE' + count]);
            } else if (props.clickStr === 'NO') {
                findAndUpdateObj(props, json, props.clickStr);
                props.setParentNode(json['UPDATE' + count]);
            }
        }
        // props.handleClose(true)
        props.setExpandedR(false);
    }

    useEffect(() => {
        let adjData = [];
        props.openPositions.forEach(item => {
            let compareId = '';
            if (window.location.search === "") {
                compareId = props.clickStr === 'start' ? '1' : (props.clickStr === 'YES' ? props.id + '.1' : props.id + '.2')
            } else {
                compareId = props.id;
            }
            let preCloseId = '';
            item.CLOSE_ID?.map((id) => {
                if (compareId.startsWith(id.toString())) {
                    preCloseId = id.toString();
                }
            })
            if (item.CLOSE === 0 && compareId.startsWith(item.ID)) {
                adjData.push(item)
                // Update values when CLOSE is 0
            } else if (((item.CLOSE === 1 && !compareId.startsWith(preCloseId)) || (item.CLOSE === 1 && preCloseId === "")) && compareId.startsWith(item.ID)) {
                adjData.push(item)
            }
        });
        setAdjustmentData(adjData)
    }, [])

    function findAndUpdateObj(obj, json, clickStr, action) {
        function traverseTree(node, json, id, nodeType) {
            if (node == null) {
                return;
            }
            if (changedNodes.includes(node.KEY)) {
                // if (node.KEY === selectedNodeData.currentPosId) {
                node[Object.keys(node)[0]]["UPDATE"] = "UPDATE" + (parseInt(props.updateCnt) + 1);
                if (node[Object.keys(node)[0]]["UPDATE_NODES"]) {
                    node[Object.keys(node)[0]]["UPDATE_NODES"] = [...node[Object.keys(node)[0]]["UPDATE_NODES"], { [json[json["KEY"]].ID]: "UPDATE" + (parseInt(props.updateCnt) + 1) }];
                } else {
                    node[Object.keys(node)[0]]["UPDATE_NODES"] = [{ [json[json["KEY"]].ID]: "UPDATE" + (parseInt(props.updateCnt) + 1) }];
                }
            }
            if (node[Object.keys(node)[0]]?.ID == id) {
                if (action && Object.keys(node).length > 0) {
                    node[Object.keys(node)[0]]["UPDATE"] = "UPDATE" + (parseInt(props.updateCnt) + 1);
                    props.setBuildJSON(props.buildJSON)
                } else {
                    if (nodeType == 'YES') {
                        node[Object.keys(node)[0]]['YES'] = json;
                        return obj;
                    } else {
                        node[Object.keys(node)[0]]['NO'] = json;
                        return obj;
                    }
                }
                return 0;
            }
            traverseTree(node[Object.keys(node)[0]]?.YES, json, id, clickStr);
            traverseTree(node[Object.keys(node)[0]]?.NO, json, id, clickStr);
        }

        let lastDotIndex = props.id.lastIndexOf(".");
        let desiredId = props.id.substring(0, lastDotIndex);
        if (action) traverseTree(obj.buildJSON, json, desiredId, clickStr);
        else traverseTree(obj.buildJSON, json, desiredId, clickStr);
        return obj;
    }

    let tempID = "";
    function getNodeData(obj, id, valueID) {
        function traverseTree(node, id) {
            if (node == null) {
                return;
            }
            if (node.KEY == id) {
                let preObj = node[node.KEY]["UPDATES"]["UPDATE_POS"];
                let matchingKey = null;
                for (let key in preObj) {
                    if (preObj.hasOwnProperty(key)) {
                        if (preObj[key] === "POSITION" + selectedNodeData.currentPosId.match(/\d+/g).join()) {
                            matchingKey = key;
                            break;
                        }
                    }
                }
                let innerNode = node[node.KEY]["UPDATES"][matchingKey];
                let objVal = innerNode;
                let data = selectedNodeData;
                data.VALUE_ID = valueID ? valueID : tempID;
                data.data = objVal
                setIsUpdateData(!isUpdateData);
                setSelectedNodeData(data);
                return 0;
            }
            traverseTree(node[Object.keys(node)[0]]?.YES, id);
            traverseTree(node[Object.keys(node)[0]]?.NO, id);
        }
        let lastDotIndex = props.id.lastIndexOf(".");
        let desiredId = props.id.substring(0, lastDotIndex);
        traverseTree(obj, id);
        return obj;
    }

    const getPositionInput = (val) => {
        let preData = selectedNodeData;
        preData.currentPosId = "ENTRY" + val;
        if (!(changedNodes.includes("ENTRY" + val))) {
            let nodes = [...changedNodes, "ENTRY" + val]
            setChangedNodes(nodes);
        }
        setSelectedNodeData(preData);
        getPositionObj("ENTRY" + val, "ENTRY" + val);
        props.setCurSelection("update-position");
        props.setExpandedR(true)
    }

    useEffect(() => {
        setCurrentobj(JSON.parse(JSON.stringify(currentobj)));
        setOldPosObj(oldPosObj);
        setInputItems(inputItems);
        setCurrentNode(currentNode);
    }, [isUpdateposition])

    useEffect(() => {
        setSelectedNodeData(selectedNodeData);
    }, [isUpdateData])

    const getPositionObj = (id, oldId, valueID, isUpdate = false) => {
        let inputObj = {}
        let data_id = ""
        let dataObj = {}
        function traverseTree(node) {
            if (node == null) {
                return '1'
            }
            let incomingId = Object?.keys(node)[0];
            if (incomingId == id) {
                setCurrentNode(incomingId);
                if (node[Object?.keys(node)[0]]["UPDATE"] !== undefined && !isUpdate) {
                    tempID = node[node.KEY].VALUE_ID ? node[node.KEY].VALUE_ID : tempID;
                    let isChanged = false;
                    node[Object?.keys(node)[0]]["UPDATE_NODES"]?.map((positionNodes) => {
                        if (props.id.startsWith(Object.keys(positionNodes)[0])) {
                            getPositionObj(positionNodes[Object.keys(positionNodes)[0]], oldId, node[node.KEY].VALUE_ID, true);
                            isChanged = true;
                        }
                    })
                    if (!isChanged) {
                        getPositionObj(oldId, oldId, node[node.KEY].VALUE_ID, true);
                    }
                } else {
                    setCurrentobj(JSON.parse(JSON.stringify(node[Object?.keys(node)[0]])));
                    const newOldPosObj = { ...oldPosObj };
                    newOldPosObj[incomingId] = JSON.parse(JSON.stringify(node[Object.keys(node)[0]]));
                    setOldPosObj(newOldPosObj);
                    let obj = node[Object?.keys(node)[0]];
                    let temData = {}
                    if (obj.VALUE_ID !== undefined) {
                        temData = decisionValues.templateData[obj.VALUE_ID.split("_")[1]];
                        setDataId(obj.VALUE_ID.split("_")[0]);
                        data_id = obj.VALUE_ID.split("_")[0]
                        setInputObjId(temData['id' + obj.VALUE_ID.split("_")[0]]['field_id']);
                        setSelectedInput(Object.keys(temData['id' + obj.VALUE_ID.split("_")[0]])[1])
                        setInputItems(templateObj[temData['id' + obj.VALUE_ID.split("_")[0]]
                        ['field_id']]);
                        inputObj = templateObj[temData['id' + obj.VALUE_ID.split("_")[0]]
                        ['field_id']]
                        setDataObj(obj["POSITION" + incomingId.match(/\d+/g)]);
                        dataObj = obj["POSITION" + incomingId.match(/\d+/g)];
                    } else {
                        getNodeData(props.buildJSON, id, valueID);
                        temData = decisionValues.templateData[selectedNodeData.VALUE_ID.split("_")[1]];
                        setDataId(selectedNodeData.VALUE_ID.split("_")[0]);
                        data_id = selectedNodeData.VALUE_ID.split("_")[0]
                        setInputObjId(temData['id' + selectedNodeData.VALUE_ID.split("_")[0]]['field_id']);
                        setSelectedInput(Object.keys(temData['id' + selectedNodeData.VALUE_ID.split("_")[0]])[1])
                        setInputItems(templateObj[temData['id' + selectedNodeData.VALUE_ID.split("_")[0]]['field_id']]);
                        inputObj = templateObj[temData['id' + selectedNodeData.VALUE_ID.split("_")[0]]['field_id']]
                        setDataObj(selectedNodeData.data);
                        dataObj = selectedNodeData.data
                    }
                    setTemplateData(temData);
                    setIsUpdateposition(!isUpdateposition);

                    let objData = { ...decisionValue };
                    for (let ind in temData) {
                        if (dataId == ind.split('')[ind.length - 1]) {
                            if (objData['dt' + dataId] == undefined) {
                                const filteredObject = Object.keys(temData[ind]).reduce((acc, key) => {
                                    if (key !== 'field_id') {
                                        acc[key] = temData[ind][key];
                                    }
                                    return acc;
                                }, {});
                                objData['dt' + dataId] = filteredObject
                                // for (let key in objData['dt' + dataId]) {
                                //     objData['dt' + dataId][key] = null;
                                // }
                            }
                        }
                    }
                    setDecisionValue(objData);

                    console.log(temData, { [incomingId]: JSON.parse(JSON.stringify(node[Object?.keys(node)[0]])) }, updatedObj, setUpdatedObj, objData, selectedNodeData.currentPosId, selectedNodeData.data, inputItems, data_id)
                    setUpdateObject({
                        templateData: temData,
                        valueObj: { [incomingId]: JSON.parse(JSON.stringify(node[Object?.keys(node)[0]])) },
                        updatedObj: updatedObj,
                        setUpdatedObj: setUpdatedObj,
                        decisionValue: objData,
                        curNode: selectedNodeData.currentPosId,
                        dataObj: dataObj,
                        inputItems: inputObj,
                        dataId: data_id,
                        changedNodes: changedNodes
                    })
                    // window.setTimeout(() => {
                    // }, 1000)
                }

                return node[Object?.keys(node)[0]];
            }
            else {
                traverseTree(node[Object.keys(node)[0]]?.YES);
                if (node[Object.keys(node)[0]]?.NO != undefined && Object.keys(node[Object.keys(node)[0]]?.NO).length > 0) {
                    traverseTree(node[Object.keys(node)[0]]?.NO);
                }
            }
        }
        return traverseTree(props.buildJSON, id);
    }

    const openPositions = adjustmentData.map((value, index) => (
        index != 'field_id' && <a key={index} className='position-items'
            onClick={() => getPositionInput(value.POS)}
        >
            <span
            // className={(selectedInput == key) ? 'item-selected' : ''}
            > Position {value.POS} </span>
        </a>
    ));

    return (
        <Row>
            <Col md={12}>
                <Row className='text-left'>
                    <Col md={12}>
                        <h4 className='mt-4 mb-4 small'>Update Exist Position</h4>
                    </Col>
                </Row>

                <Row className='text-left'>
                    <Col md={12}>
                        <span className='cursor-pointer primary-text position-item-wrapper' style={{ cursor: 'pointer' }}>
                            <div >{openPositions}</div>
                        </span>
                    </Col>
                </Row>
                <Row className='text-left mt-4'>
                    <Col md={12}>
                        {/* <UpdateInputs
                            templateData={templateData}
                            valueObj={{ [currentNode]: currentobj }}
                            updatedObj={updatedObj}
                            setUpdatedObj={setUpdatedObj}
                            decisionValue={decisionValue}
                            curNode={selectedNodeData.currentPosId}
                            dataObj={dataObj}
                            inputItems={inputItems}
                            dataId={dataId}
                        /> */}
                    </Col>
                </Row>
            </Col>
            {/* <Col md={12} className='text-right mt-3'>
                <button className='btn pl-0 btn-primary' onClick={saveData} >
                    Save
                </button>
            </Col> */}
        </Row>
    );
}

export default UpdatePosition;
