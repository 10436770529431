import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import tagsJSON from "../../tags.json"
import { Form } from 'react-bootstrap';
function Tags(props) {

    const [symbol, setSymbol] = useState("")
    const [value, setValue] = useState("")
    const [errorMsg, setErrorMsg] = useState("");
    const [tagName, setTagName] = useState("")

    console.log(props);
    const updateSymbol = (e) => {
        setSymbol(e)
    }
    const updateValue = (e) => {
        setValue(e)
    }

    const updateTagName = (e) => {
        setTagName(e)
    }

    const cancelData = () => {
        props.setExpandedR(!props.expandedR);
        props.setExpandedL(!props.expandedL);
    }

    const saveData = () => {
        let isValid = true;
        setErrorMsg("")

        if (symbol === "" || value === "") {
            isValid = false;
            setErrorMsg("Fields cannot be blank!")
        }
        if (isValid) {
            let count = props.tagsCnt + 1;
            props.setTagsCnt(count);
            if ((props.clickStr == 'start' || props.clickStr == 'YES' || props.clickStr == 'NO') && props.type == 'tags') {
                let tagNamesCnt = props.tagNames.length + 1;
                let tName = tagName
                if(tName == ''){
                    tName = "Tag"+tagNamesCnt
                }

                if (!props.tagNames.includes(tName)){
                    props.tagNames.push(tName)
                    props.setTagNames(props.tagNames)
                }
                let json = {
                    ['TAGS' + count]: {
                        TMP_ID: "tags",
                        CONTENT: { symbol: symbol, value: value, name: tName },
                        YES: {},
                        NO: {},
                        ID: props.clickStr == 'start' ? '1' : props.id
                    },
                    TYPE: "TAGS",
                    KEY: 'TAGS' + count,
                }
                if (props.clickStr == 'start') {
                    props.setBuildJSON(json);
                    // let updateCnt = (props.iterateCnt + 1);
                    // props.setIterateCnt(updateCnt);
                    props.setParentNode(json['TAGS']);
                } else if (props.clickStr == 'YES') {
                    findAndUpdateObj(props, json, props.clickStr);
                } else if (props.clickStr == 'NO') {
                    findAndUpdateObj(props, json, props.clickStr);
                }
            }
            props.setIsUpdateJson(!props.isUpdateJson);
            props.setExpandedR(false)
            props.setExpandedL(false)
            // props.hideFields(false);
            // props.handleClose(true)
        }
    }

    function findAndUpdateObj(obj, json, clickStr) {
        let updated = 0;
        function traverseTree(node, json, id, nodeType) {
            if (node == null) {
                return;
            }
            if (node[Object.keys(node)[0]]?.ID == id) {
                if (nodeType == 'YES') {
                    node[Object.keys(node)[0]]['YES'] = json;
                } else {
                    node[Object.keys(node)[0]]['NO'] = json;
                }
                return 0;
            }
            traverseTree(node[Object.keys(node)[0]]?.YES, json, id, clickStr);
            traverseTree(node[Object.keys(node)[0]]?.NO, json, id, clickStr);
        }
        let lastDotIndex = props.id.lastIndexOf(".");
        let desiredId = props.id.substring(0, lastDotIndex);
        traverseTree(obj.buildJSON, json, desiredId, clickStr);
        return obj;
    }

    return (
        <Row>
            {/* <Col sm={12}><h2>Tags</h2></Col> */}
            {<Col sm={10}>
                {/* <p className='mb-0 mt-2'>Symbol</p> */}
                <Form.Label sm={2} className='mb-0 mt-3 font-weight-bold' style={{ fontWeight: '600', textTransform: "capitalize" }}>
                    Symbol </Form.Label>
                <Form.Select
                    value={symbol}
                    onChange={e => updateSymbol(e.target.value)}
                >
                    <option key="0" value="">
                        Select a symbol
                    </option>
                    {tagsJSON.symbols.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </Form.Select>
            </Col>}
            {<Col sm={10}>
                {/* <p className='mb-0 mt-3'>Value</p> */}
                <Form.Label sm={2} className='mb-0 mt-3 font-weight-bold' style={{ fontWeight: '600', textTransform: "capitalize" }}>
                    Value </Form.Label>
                <Form.Select
                    value={value}
                    onChange={e => updateValue(e.target.value)}
                >
                    <option key="0" value="">
                        Select a vaue
                    </option>
                    {tagsJSON.values.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </Form.Select>
            </Col>}
            {<Col sm={10}>
                {/* <p className='mb-0 mt-3'>Value</p> */}
                <Form.Label sm={2} className='mb-0 mt-3 font-weight-bold' style={{ fontWeight: '600', textTransform: "capitalize" }}>
                    Action </Form.Label>
                <Form.Select
                    value={tagName}
                    onChange={e => updateTagName(e.target.value)}
                >
                    <option key="0" value="">
                        Set a new tag
                    </option>
                    {props.tagNames.map((option, index) => (
                        <option key={option} value={option}>
                            {'Replace '+(option)+' value'}
                        </option>
                    ))}
                </Form.Select>
            </Col>}
            <Col md={12}>
                {errorMsg !== "" && <p className='text-danger'>{errorMsg}</p>}
            </Col>
            <Col md={12} className='text-right mt-3'>
                <button className='btn pl-0 btn-transparent border text-dark' style={{ marginRight: "10px", padding: "8px 13px" }} onClick={cancelData} >
                    Cancel
                </button>
                <button className='btn pl-0 btn-primary' onClick={saveData} >
                    Save
                </button>
            </Col>
        </Row>
    );
}

export default Tags;