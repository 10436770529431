import React, { useEffect, useState } from 'react';
import { Nav, Container, Row, Col, Accordion, Button } from 'react-bootstrap';
import Header from './Header';
import Strategy from './strategy/Strategy';
import Home from './home/Home';
import '../components/components.css';
import Footer from './Footer';
import Strategies from './strategy/Strategies';
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHome, faUser, faCog } from '@fortawesome/free-solid-svg-icons';
import Sidebar from './Sidebar';

function LeftNav() {
    const [urlString, setUrlString] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const url = window.location.href;
        const endpoint = url.substring(url.lastIndexOf('/') + 1);
        setUrlString(endpoint);
        // if(endpoint.split('/').length == 1) {
        //     redirectToPage('/home');
        // }
    }, [isUpdate]);

    // const redirectToPage = (endPoint) => {
    //     navigate(endPoint)
    // };

    const [expanded, setExpanded] = useState(true);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <div class="container-fluid m-0 text-center p-0" style={{
                background: "#F8F8F8"
            }}>
                <div style={{ display: "flex" }}>
                    <div style={{
                        width: expanded ? "14%" : "7%"
                    }} className='bg-sidebar-theme'>
                        <Sidebar
                            expanded={expanded}
                            setExpanded={setExpanded}
                        />
                    </div>
                    <div style={{
                        width: expanded ? "85%" : "93%"
                    }}>
                        <Header
                            expanded={expanded}
                            setExpanded={setExpanded}
                        />
                        {(urlString.split('/').length == 1) && urlString.split('#')[0] == 'home' ? (<Home changeUrl={setIsUpdate} isUpdate={isUpdate} />) : ('')}
                        {((urlString.split('/').length == 1) && urlString.split('#')[0] != 'strategy' && urlString.split("=").length == 1) ? (<Home changeUrl={setIsUpdate} isUpdate={isUpdate} />) : ('')}
                        {(urlString.split('#')[0] == 'strategy' && urlString.split('/').length >= 1) ? (<Strategy changeUrl={setIsUpdate} isUpdate={isUpdate} />) : ('')}
                        {(urlString.split('#')[0] == 'strategies' && urlString.split('/').length != 1) ? (<Strategies expanded={expanded} setExpanded={setExpanded} changeUrl={setIsUpdate} isUpdate={isUpdate} />) : ('')}
                        {(urlString.split('#')[0] == 'home' && urlString.split('/').length != 1) ? (<Home changeUrl={setIsUpdate} isUpdate={isUpdate} />) : ('')}
                        {Number.isInteger(parseInt(urlString.split('')[urlString.split('').length - 1])) ? (<Strategy changeUrl={setIsUpdate} isUpdate={isUpdate} draw={urlString.split('')[urlString.split('').length - 1]} />) : ('')}
                    </div>
                </div>
            </div>

        </>
    );
}

export default LeftNav;
