// StrategyOutcomes
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { API_URL } from '../config';
import { useNavigate } from 'react-router-dom';
function PublishStrategyModal(props) {
    const [pubStartDate, setPubStartDate] = useState(new Date(moment()));
    var currentDate = moment();
    var oneYearFromNow = currentDate.add(1, 'years');
    const [pubEndDate, setPubEndDate] = useState(new Date(oneYearFromNow));

    const navigate = useNavigate();
    const updateInput = (date, type) => {
        if (type === "start") setPubStartDate(date);
        else setPubEndDate(date);
    }

    const handlePublish = async () => {

        let postData = {
            email: JSON.parse(localStorage.getItem("user_details")).email,
            id: props.id,
            start_date: pubStartDate,
            end_date: pubEndDate
        }
        const url = props.type === "publish" ? `${API_URL}/publish` : `${API_URL}/subscribe`
        const method = "POST";
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                props.setShowPublish(false);
                if (props.type !== "subscribe") window.location.reload();
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Error: ' + response.status);
                }
            })
            .then(data => {
                navigate('/strategies');
            })
            .catch(error => {
                // Handle any errors that occurred during the request
                console.log('Error:', error);
            });
    }
    return (
        <Modal show={props.showPublish} onHide={props.handlePublishModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.type === "subscribe" && "Subscribe Strategy"}
                    {props.type !== "subscribe" && "Publish Strategy"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className='date-time-picker'>
                            <p className='h6'>Start {props.type === "subscribe" && "Subscribe "}
                                {props.type !== "subscribe" && "Publish"}  Date</p>
                            <DatePicker
                                selected={pubStartDate}
                                // onChange={handleChange}
                                showTimeSelect
                                timeIntervals={5}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                className='form-control date-picker'
                                onChange={e => updateInput(e, "start")}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='date-time-picker mt-4'>
                            <p className='h6'>End {props.type === "subscribe" && "Subscribe "}
                                {props.type !== "subscribe" && "Publish "} Date</p>
                            <DatePicker
                                selected={pubEndDate}
                                // onChange={handleChange}
                                showTimeSelect
                                timeIntervals={5}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                className='form-control date-picker'
                                onChange={e => updateInput(e, "end")}
                            />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" style={{ borderRadius: "100px", padding: "3px 15px" }} onClick={props.handlePublishModal}>
                    Close
                </Button>
                <Button variant="primary" className='btn' onClick={handlePublish}>
                    {props.type === "subscribe" && "Subscribe "}
                    {props.type !== "subscribe" && "Publish "}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PublishStrategyModal;