import React, { useEffect, useRef, useState } from 'react';
import './Strategy.css';
import { Button, Col, Overlay, Row } from 'react-bootstrap';
import repeat from '../../assets/images/repeat.png'
import decision from '../../assets/images/decision1.png'
import open from '../../assets/images/add.png'
import close from '../../assets/images/close.png'
import { Dropdown } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import StrategyItems from './strategyItems';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// import decisionImg from '../../assets/images/decision.png';
import decisionImg1 from '../../assets/images/decision1.png';
import openPosition from '../../assets/images/add.png';
import closePosition from '../../assets/images/close.png';
import notification from '../../assets/images/notification.png';
import DecisionV1 from './DecisionV1';
import updatePosition from '../../assets/images/update.png';
import tags from '../../assets/images/tags.png';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';
import Flowchart from './DrawChart/Flowchart';
import ChartLoader from './DrawChart/ChartLoader';
import Toaster from './Toaster/Toast';
import PositionItems from "../../components/strategy/OpenPosition/OpenDecisionItems"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft, faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import OpenDecision from './OpenPosition/OpenDecision';
import { useMyContext } from '../../context/MyContext';
import DecisionItems from './DecisionItems';
import decisionValues from "../decision.json";
import UpdatePosition from './UpdatePosition/UpdatePosition';
import UpdateInputs from './UpdatePosition/UpdateInputs';
import Tags from './Tags/Tags';
import Repeat from './Repeat';
import EndNode from './EndNode/EndNode';
import ClosePosition from './ExitPosition/ExitPosition';

function Strategy(props) {
    const [cursorStyle, setCursorStyle] = useState("default");
    const [showModal, setShowModal] = useState(false);
    const [nodes, setNodes] = useState([]);
    const [clickedNodePosition, setClickedNodePosition] = useState(null);
    const [parentNode, setParentNode] = useState({});
    const [selectedItem, setSelectedItem] = useState('');
    const [clickStr, setClickStr] = useState("start");
    const [buildJSON, setBuildJSON] = useState({});
    const [openPositions, setOpenPositions] = useState([]);
    const [iterateCnt, setIterateCnt] = useState(0);
    const [nodeNo, setNodeNo] = useState('');
    const [conditionCount, setConditionCount] = useState(0);
    const [entryCnt, setEntryCnt] = useState(0);
    const [closeCnt, setCloseCnt] = useState(0);
    const [repeatCnt, setRepeatCnt] = useState(0);
    const [tagsCnt, setTagsCnt] = useState(0);
    const [tagNames, setTagNames] = useState([]);
    const [updateCnt, setUpdateCnt] = useState(0);
    const [jsonKey, setJsonKey] = useState(0);
    const [isShow, setIsShow] = useState(false);
    const [id, setId] = useState(0);
    const navigate = useNavigate();
    const [strategyName, setStrategyName] = useState('')
    const [sectionWidth, setSectionWidth] = useState(0)
    const [chartJson, setChartJson] = useState({
        value: [{
            id: "0",
            sourcePosition: 'bottom',
            type: 'selectorNode',
            data: {
                label: "Start",
                type: "start"
            },
            position: { x: window.innerWidth / 2 - 57, y: 0 },
        }],
        edgeObj: []
    })
    const [expandedL, setExpandedL] = useState(false);
    const [expandedR, setExpandedR] = useState(false);
    const [updateNodes, setUpdateNodes] = useState(false)
    const [isChart, setIsChart] = useState(true)
    const [curSelection, setCurSelection] = useState("")

    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [isUpdateJson, setIsUpdateJson] = useState(false);
    const [flowchartFlow, setFlowchartFlow] = useState("V");

    let [decitionContent, setDecitionContent] = useState(decisionValues.decitionItems[0].key);

    let displayObj = {
        TEXT: '',
        X: window.innerWidth / 2 - 170,
        Y: 0,
    }
    const handleDropdownSelect = (eventKey) => {
        setSelectedItem(eventKey);
        handleOpenModal();
    };

    const { decisionObject, setDecisionObject, strategyObject } = useMyContext();

    useEffect(() => {
        const url = window.location.href;
        const endpoint = url.substring(url.lastIndexOf('=') + 1);
        if (props.draw != undefined) {
            setIsChart(false);
            fetch(`${API_URL}/strategy/${endpoint}`, {
                "Content-Type": "application/json"
            }
            )
                .then(response => {
                    // Check if the request was successful (status code 200)
                    if (response.ok) {
                        // Parse the response data as JSON
                        return response.json();
                    } else {
                        throw new Error(`Request failed with status code ${response.status}`);
                    }
                })
                .then(res => {
                    const data = res.strategy.data;
                    setStrategyName(res.strategy.strategy_name);
                    setBuildJSON(JSON.parse(data));
                    let chartObj = flowchartFlow === "V" ? getNodes(JSON.parse(data)) : getNodesHorizontal(JSON.parse(data));
                    updateDefaultState(JSON.parse(data))
                    if (chartObj.value.length > 0) {
                        let updateVal = [
                            ...chartObj.value, ...chartJson.value
                        ]
                        chartObj.value = updateVal;
                        let updateObj = [
                            {
                                id: "0",
                                source: "0",
                                target: '1',
                                type: 'smoothstep',
                                // animated: true,
                                label: ''
                            }
                            , ...chartObj.edgeObj
                        ]
                        chartObj.edgeObj = updateObj;
                        setChartJson(chartObj);
                    }
                    setIsChart(true)
                })
                .catch(error => {
                    setIsChart(true)
                    console.error("Request failed:", error);
                });
        }
    }, [])

    const updateDefaultState = (json) => {
        setEntryCnt(0);
        setCloseCnt(0);
        let positionArr = [];
        function traverseTree(node) {
            if (node == null) {
                return value;
            }
            if (node[Object.keys(node)[0]] != undefined) {
                const parts = node[Object.keys(node)[0]].ID.split(".");
                if (parts?.join(".") === "1" || parts?.join(".") === "0") {
                    if (Object.keys(node)[0].replace(/\d+/g, '') == "ENTRY") {
                        setEntryCnt(preCunt => preCunt + 1);
                        let currPosArr = {
                            ID: node[Object.keys(node)[0]]["ID"],
                            CLOSE: 0,
                            POS: parseInt(parts?.join(".")),
                            CLOSE_ID: []
                        };
                        positionArr.push(currPosArr)
                    }
                } else {
                    parts?.pop(); // Remove the last element
                    const outputString = parts?.join(".");
                    if (outputString != "") {
                        let intVal = parseInt((Object.keys(node)[0]).replace(/\D/g, ''), 10)
                        if (Object.keys(node)[0].replace(/\d+/g, '') == "ENTRY") {
                            setEntryCnt(preCunt => preCunt + 1);
                            // positionArr.push(intVal)
                            let currPosArr = {
                                ID: node[Object.keys(node)[0]]["ID"],
                                CLOSE: 0,
                                POS: parseInt(intVal),
                                CLOSE_ID: []
                            };

                            positionArr.push(currPosArr)
                            // props.setOpenPositions([...props.openPositions, currPosArr]);
                        } else if (Object.keys(node)[0].replace(/\d+/g, '') == "EXIT") {
                            setCloseCnt(preCnt => preCnt + 1);
                            // let posArr = node[Object.keys(node)[0]][Object.keys(node)[0]]
                            // console.log(posArr)
                            // posArr?.map(position => {
                            positionArr.map((item) => {
                                node[node["KEY"]]["EXIT_POS"].map((pos) => {
                                    if (parseInt((pos).replace(/\D/g, ''), 10) === item.POS) {
                                        item.CLOSE = 1;
                                        item.CLOSE_ID = [...item.CLOSE_ID, node[Object.keys(node)[0]]["ID"]];
                                    }
                                })
                            });
                            // if (positionArr.includes(parseInt(position.replace(/\D/g, ''), 10)) !== -1) {
                            // positionArr.splice(positionArr.indexOf(parseInt(position.replace(/\D/g, ''), 10)), 1)
                            // }


                            // });
                        } else if (Object.keys(node)[0].replace(/\d+/g, '') == "UPDATE") {
                            setUpdateCnt(preCnt => preCnt + 1);
                        }
                    }
                }
            }
            if (node[Object.keys(node)[0]]?.YES != undefined) traverseTree(node[Object.keys(node)[0]]?.YES);
            if (node[Object.keys(node)[0]]?.NO != undefined) traverseTree(node[Object.keys(node)[0]]?.NO);
        }

        traverseTree(json);
        setOpenPositions(positionArr);
    }

    useEffect(() => {
        let chartObj = flowchartFlow === "V" ? getNodes(buildJSON) : getNodesHorizontal(buildJSON);
        if (chartObj.value.length > 0) {
            let updateVal = [
                ...chartObj.value, ...chartJson.value
            ]
            chartObj.value = updateVal;
            let updateObj = [
                {
                    id: "0",
                    source: "0",
                    target: '1',
                    type: 'smoothstep',
                    // animated: true,
                    label: ''
                }
                , ...chartObj.edgeObj
            ]
            chartObj.edgeObj = updateObj;
            setChartJson(chartObj);
        }

        console.log("buidJSONS", buildJSON)
    }, [buildJSON, isUpdateJson]);

    useEffect(() => {
        let chartObj = flowchartFlow === "V" ? getNodes(buildJSON) : getNodesHorizontal(buildJSON);
        if (chartObj.value.length > 0) {
            let updateVal = [
                ...chartObj.value,
                {
                    id: "0",
                    sourcePosition: 'bottom',
                    type: 'selectorNode',
                    data: {
                        label: "Start",
                        type: "start"
                    },
                    position: { x: flowchartFlow === "V" ? window.innerWidth / 2 - 57 : 0, y: flowchartFlow === "H" ? window.innerHeight / 2 - 57 : 0 },
                }
            ]
            chartObj.value = updateVal;
            let updateObj = [
                {
                    id: "0",
                    source: "0",
                    target: '1',
                    type: 'smoothstep',
                    // animated: true,
                    label: ''
                }
                , ...chartObj.edgeObj
            ]
            chartObj.edgeObj = updateObj;
            setChartJson(chartObj);
        }
    }, [showModal]);

    var obj = {}
    const DisplayStrategy = (props) => {

        const [upadteJson, setUpadteJson] = useState(0);
        var image = decision;
        let lineStr = "";
        const pathStyle = {
            fill: 'none',
            stroke: 'lightblue',
            strokeWidth: 0.5,
        };
        var xPos = window.innerWidth / 2 - 60;
        obj[1] = xPos;
        function manageAxis(json) {
            var value = [];
            var isUpdate = false;
            function traverseTree(node) {
                if (node == null) {
                    return value;
                }
                if (Object.keys(node).length > 0 && node[Object.keys(node)[0]].ID != '1') {
                    let matchingIds = [];
                    let preId = node[Object.keys(node)[0]].ID.split(".");
                    let nodeInd = node[Object.keys(node)[0]].ID;
                    preId.pop();
                    preId = preId.join('.');
                    let isCollide = false;
                    let [outerAxis, preNode] = getAxis(buildJSON, preId);
                    let compareVal = parseInt(obj[preId]);
                    if (nodeInd.split('.').at(-1) == '1') {
                        compareVal = parseInt(obj[preId]) - 75;
                    } else if (nodeInd.split('.').at(-1) == '2') {
                        compareVal = parseInt(obj[preId]) + 75;
                    }
                    for (const objectId in obj) {
                        if (obj[objectId] == compareVal && nodeInd.split('.').length == objectId.split('.').length && nodeInd != '1' && nodeInd != objectId) {
                            matchingIds.push(objectId);
                            isCollide = true;
                        }
                    }
                    if (isCollide && preId != "") {
                        obj[preId] = parseInt(obj[preId]) + 150;
                        let nextLId = preId + '.1';
                        let nextRId = preId + '.2';
                        obj[nextLId] = parseInt(obj[preId]) - 75;
                        obj[nextRId] = parseInt(obj[preId]) + 75;
                        // manageAxis(buildJSON);
                    } else if (preId != "") {
                        if (nodeInd.split('.').at(-1) == '1') {
                            if (Object?.keys(preNode)[0].replace(/\d+/g, '') == 'ENTRY' || Object?.keys(preNode)[0].replace(/\d+/g, '') == 'EXIT' || Object?.keys(preNode)[0].replace(/\d+/g, '') == 'REPEAT') {
                                obj[nodeInd] = parseInt(obj[preId]);
                            } else {
                                obj[nodeInd] = parseInt(obj[preId]) - 75;
                                let rightId = preId + '.2';
                                obj[rightId] = parseInt(obj[preId]) + 75;
                            }
                        } else if (nodeInd.split('.').at(-1) == '2') {
                            if (Object?.keys(preNode)[0].replace(/\d+/g, '') == 'ENTRY' || Object?.keys(preNode)[0].replace(/\d+/g, '') == 'EXIT' || Object?.keys(preNode)[0].replace(/\d+/g, '') == 'REPEAT') {
                                obj[nodeInd] = parseInt(obj[preId]);
                            } else {
                                if (obj[nodeInd] == 'undefined') obj[nodeInd] = parseInt(obj[preId]) - 75;
                            }
                        } else {
                            obj[nodeInd] = parseInt(obj[preId]) + 75;
                        }
                    } else {
                        obj[nodeInd] = preId != "" ? parseInt(obj[preId]) : xPos
                    }
                    isUpdate = true;
                }

                if (node[Object.keys(node)[0]]?.YES != undefined && Object.keys(node[Object.keys(node)[0]]?.YES).length > 0) {
                    traverseTree(node[Object.keys(node)[0]]?.YES);
                }
                if (node[Object.keys(node)[0]]?.NO != undefined && Object.keys(node[Object.keys(node)[0]]?.NO).length > 0) {
                    traverseTree(node[Object.keys(node)[0]]?.NO);
                }
            }
            traverseTree(json);
            return obj;
        }
        let pos = manageAxis(buildJSON);
        useEffect(() => {
            props.setData(buildJSON);
        }, [upadteJson])

        const handleStrategySelect = (eventKey, str, json) => {
            setJsonKey(json.ID);
            setSelectedItem(eventKey);
            handleOpenModal();
            handleFlowchart(str)
        };

        function getAxis(obj, id) {
            var update = 0;
            var value = window.innerWidth / 2 - 60;
            var curNode = '';
            function traverseTree(node) {
                if (node == null) {
                    return value;
                }
                let incomingId = node[Object?.keys(node)[0]]?.ID;
                if (incomingId == id && !update) {
                    update = 1;
                    value = node[Object?.keys(node)[0]]?.X;
                    curNode = node;
                    return node[Object?.keys(node)[0]]?.X;
                }
                else if (!update) {
                    traverseTree(node[Object.keys(node)[0]]?.YES, id);
                    if (node[Object.keys(node)[0]]?.NO != undefined && Object.keys(node[Object.keys(node)[0]]?.NO).length > 0) {
                        traverseTree(node[Object.keys(node)[0]]?.NO, id);
                    }
                }
            }
            traverseTree(obj, id);
            return ([value, curNode]);
        }

        const parts = props.data?.ID.split(".");
        parts?.pop(); // Remove the last element
        const outputString = parts?.join(".");
        let [outerAxis, node] = getAxis(buildJSON, outputString);
        let lPos = xPos;
        if (props.data != undefined && props.data["END"] != 'END') {
            displayObj.Y = 0;
            lineStr = "M18, 15 V8 V18";
            displayObj.X = outerAxis;
            props.data?.ID.split('.').map((index, key) => {
                displayObj.Y += 65;
                if (index == '1' && props.obj?.YES && Object.keys(props.obj?.YES).length > 0) {
                    if (Object?.keys(node)[0].replace(/\d+/g, '') == 'ENTRY' || Object?.keys(node)[0].replace(/\d+/g, '') == 'EXIT' || Object?.keys(node)[0].replace(/\d+/g, '') == 'REPEAT') {
                        lineStr = "M0, 15 V8 V18";
                        displayObj.X = outerAxis;
                        lPos = obj[outputString] + 50;
                    } else {
                        let xLen = (obj[outputString] - obj[props.data?.ID]) / 2;
                        displayObj.X = outerAxis - 50;
                        lineStr = `M15,25 V13 H${xLen} V8`;
                        lPos = obj[outputString] - 80;
                        // if (props.data?.ID == '1.1') {
                        //     lineStr = `M15,25 V13 H65 V8`;
                        // }
                    }
                } else if (index == '2' && props.obj?.NO && Object.keys(props.obj?.NO).length > 0) {
                    if (Object?.keys(node)[0].replace(/\d+/g, '') == 'ENTRY' || Object?.keys(node)[0].replace(/\d+/g, '') == 'EXIT' || Object?.keys(node)[0].replace(/\d+/g, '') == 'REPEAT') {
                        lineStr = "M0, 15 V8 V18";
                        displayObj.X = outerAxis;
                        lPos = obj[outputString]
                    } else {
                        let xLen = (obj[props.data?.ID] - 10 - obj[outputString] - 50) / 2;
                        displayObj.X = outerAxis - 50;
                        lineStr = `M0,5 V13 H${xLen} V20`;
                        displayObj.X = outerAxis + 100;
                        lPos = obj[outputString] + 70
                    }
                } else {
                    displayObj.X = outerAxis
                }
            })
            if (Object.keys(obj).length > 0) setSectionWidth(Object.values(obj)[Object.keys(obj).length - 1])
            props.data.X = obj[props.data?.ID];
            props.data.Y = displayObj.Y;
            let nodeStr = '';
            nodeStr = props.data.TMP_ID != 'REPEAT' ? Object.keys(props.data['CONTENT']).map(key => props.data['CONTENT'][key]).join(" ") : props.data["CONTENT"];
            displayObj.TEXT = nodeStr;
            if (props.data.TMP_ID != undefined && props.data.TMP_ID == 'OPEN') {
                image = open;
            } else if (props.data.TMP_ID != undefined && props.data.TMP_ID == 'CLOSE') {
                image = close;
            } else if (props.data.TMP_ID == "REPEAT") {
                if (displayObj.TEXT == "") {
                    displayObj.TEXT = "REPEAT";
                }
                image = repeat;
            }
        }

        const handleFlowchart = (val) => {
            setClickStr(val);
            if (val = 'NO') {
                let nodeKey = nodeNo + '.' + val;
                setNodeNo(nodeKey);
            }
            setShowModal(true);
        }

        const TooltipOnHover = (props) => {
            const tooltip = (
                <Tooltip id="tooltip-example">{props.text}</Tooltip>
            );
            return (
                <OverlayTrigger placement="top" overlay={tooltip}>
                    <span>
                        <img src={image} height={35} className='node-img' />
                        <p className={props.tmp_id == 'CLOSE' ? 'text-danger pt-3 node-text' : 'pt-3 text-muted node-text'}>{props.text}</p>
                    </span>
                </OverlayTrigger>
            )
        }

        function findAndUpdatePos(obj, id, X, Y) {
            var update = 0;
            function traverseTree(node) {
                if (node == null) {
                    return 0;
                }
                let incomingId = node[Object?.keys(node)[0]]?.ID;
                if (incomingId == id && !update) {
                    update = 1;
                    node[Object.keys(node)[0]].X = X;
                    node[Object.keys(node)[0]].Y = Y;
                    setUpadteJson(1)
                    return node;
                }
                else {
                    traverseTree(node[Object.keys(node)[0]]?.YES, id);
                    if (node[Object.keys(node)[0]]?.NO != undefined && Object.keys(node[Object.keys(node)[0]]?.NO).length > 0) {
                        traverseTree(node[Object.keys(node)[0]]?.NO, id);
                    }
                }
            }
            let nodes = traverseTree(obj, id);
            return nodes;
        }

        // const handleDragStart = (e, id) => {
        //     findAndUpdatePos(buildJSON, id,  e.clientX, e.clientY);
        //     console.log(e.clientX, e.clientY);
        //     props.setData(buildJSON);
        // }

        // const printDrag = (e) => {
        //     console.log('azsdsad', e);
        // }

        return (
            (displayObj['TEXT'] != '' && props.data != undefined) && <div>
                <div
                    className="node"
                    style={{ top: props.data.Y, left: props.data.X }}
                    draggable
                >
                    {/* onDragOver={(e) =>handleDragStart(e, props.data?.ID)}
                        onDrop={printDrag} */}
                    {/* onDragOver={handleDragStart} */}
                    <TooltipOnHover text={displayObj['TEXT']} tmp_id={props.data.TMP_ID} />
                    <div className='node-options'>
                        {(props.data.TMP_ID != undefined && props.data.TMP_ID != 'CLOSE' && props.data.TMP_ID != 'REPEAT') && <ToggleButtonGroup
                            type="radio"
                            name="options"
                            value={selectedOption}
                        >
                            <ToggleButton
                                variant={selectedOption === 'yes' ? 'outline-primary' : 'outline-secondary'}
                                value='yes'
                                className={selectedOption === 'no' ? 'active' : ''}
                            // onClick={() => handleFlowchart('YES')}
                            >
                                <Dropdown className='btn-sm' onSelect={(e) => handleStrategySelect(e, 'YES', props.data)}>
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                        Y
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="border-0 shadow-none">
                                        <Dropdown.Item href="#" eventKey="decision">
                                            {/* {activeTab == "decision" && <img src={decisionImg} width='18' />} */}
                                            {<img src={decisionImg1} width='18' />} Decision
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" eventKey="open-position">
                                            <img src={openPosition} width='18' /> Open Position
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" eventKey="close-position">
                                            <img src={closePosition} width='18' /> Close Position
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" eventKey="exist-position">
                                            <img src={updatePosition} width='18' /> Update Exist Positions
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" eventKey="notification">
                                            <img src={notification} width='18' /> Notification
                                        </Dropdown.Item >
                                        <Dropdown.Item href="#" eventKey="tags">
                                            <img src={tags} width='18' /> Tags
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" eventKey="repeat">
                                            <img src={tags} width='18' /> Repeat
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ToggleButton>
                            {props.data?.NO != undefined && <ToggleButton
                                variant={selectedOption === 'yes' ? 'outline-primary' : 'outline-secondary'}
                                value='no'
                                className={selectedOption === 'no' ? 'active' : ''}
                            // onClick={() => handleFlowchart('NO')}
                            // onClick={() => handleNoNode('NO')}
                            >
                                <Dropdown className=' btn-sm' onSelect={(e) => handleStrategySelect(e, 'NO', props.data)}>
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                        N
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="border-0 shadow-none">
                                        <Dropdown.Item href="#" eventKey="decision">
                                            {/* {activeTab == "decision" && <img src={decisionImg} width='18' />} */}
                                            {<img src={decisionImg1} width='18' />} Decision
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" eventKey="open-position">
                                            <img src={openPosition} width='18' /> Open Position
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" eventKey="close-position">
                                            <img src={closePosition} width='18' /> Close Position
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" eventKey="exist-position">
                                            <img src={updatePosition} width='18' /> Update Exist Positions
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" eventKey="notification">
                                            <img src={notification} width='18' /> Notification
                                        </Dropdown.Item >
                                        <Dropdown.Item href="#" eventKey="tags">
                                            <img src={tags} width='18' /> Tags
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" eventKey="repeat">
                                            <img src={tags} width='18' /> Repeat
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ToggleButton>}
                        </ToggleButtonGroup>}
                    </div>
                </div>
                <svg viewBox="0 0 100 100" style={{ width: '300', height: '300', position: 'absolute', left: lPos, top: displayObj.Y - 50 }}>
                    <path
                        d={lineStr}
                        style={pathStyle}
                        strokeDasharray="2 1"
                    />
                </svg>
                {/* <CurvedLines width={25} /> */}
                {(props.data != null && props.data != undefined && Object.keys(props.data.YES).length != 0) && (<DisplayStrategy data={props.data.YES[Object.keys(props.data.YES)[0]]} nodeType='YES' obj={props.data} setData={setBuildJSON} />)}
                {(props.data != null && props.data.NO != undefined && Object.keys(props.data?.NO)?.length != 0) && (<DisplayStrategy data={props.data.NO[Object.keys(props.data.NO)[0]]} nodeType='NO' obj={props.data} setData={setBuildJSON} />)}
            </div>
        );
    };

    const saveJson = () => {
        let postData = {
            user_id: 100,
            email: JSON.parse(localStorage.getItem("user_details")).email,
            subscription_id: 0,
            data: buildJSON,
            strategy_name: strategyName,
            status: 0,
        }
        const url = window.location.search.split("=")[1] == undefined ? `${API_URL}/saveStrategy` : `${API_URL}/saveStrategy/${window.location.search.split("=")[1]}`;
        const method = window.location.search.split("=")[1] == undefined ? "POST" : "PUT";

        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Error: ' + response.status);
                }
            })
            .then(data => {
                navigate('/strategies');
            })
            .catch(error => {
                // Handle any errors that occurred during the request
                console.log('Error:', error);
            });
    }

    const navigateToStrategy = () => {
        navigate('/strategies')
    }

    const [selectedOption, setSelectedOption] = useState('');

    const handleOpenModal = () => {
        setShowModal(true);
        setClickStr('start');
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    var value = [];
    var edgeObj = [];
    let X = 0;
    let Y = 0;

    function getNodes(obj) {
        value = [];
        edgeObj = [];
        function traverseTree(node) {
            if (node == null) {
                return value;
            }
            if (node[Object.keys(node)[0]] != undefined) {
                let nodeStr = node[Object.keys(node)[0]].TMP_ID !== 'REPEAT' ? Object.keys(node[Object.keys(node)[0]]['CONTENT']).map(key => node[Object.keys(node)[0]]['CONTENT'][key]).join(" ") : node[Object.keys(node)[0]]["CONTENT"];
                const parts = node[Object.keys(node)[0]].ID.split(".");
                if (parts?.join(".") === "1" || parts?.join(".") === "0") {
                    Y = 75;
                    X = window.innerWidth / 2 - 50;
                    updateAxis(buildJSON, node[Object.keys(node)[0]].ID, X, Y)
                    // if (Object.keys(node)[0].replace(/\d+/g, '') == "ENTRY") {
                    //     setEntryCnt(preCunt => preCunt + 1)
                    // }
                } else {
                    parts?.pop(); // Remove the last element
                    const outputString = parts?.join(".");
                    if (outputString != "" && node[Object.keys(node)[0]].X == undefined) {
                        let [axisX, axisY, curNode] = getAxis(buildJSON, outputString);
                        if (axisX === 0 || axisX === undefined) {
                            X = 0
                        } else if (clickStr == "YES" && Object.keys(curNode)[0].replace(/\d+/g, '') == "CONDITION") {
                            X = axisX - 100;
                        } else if (clickStr == "NO" && Object.keys(curNode)[0].replace(/\d+/g, '') == "CONDITION") {
                            X = axisX + 100;
                        } else {
                            X = axisX;
                        }
                        Y = (axisY === 0 || axisY === undefined) ? 0 : axisY + 100;

                        // if (Object.keys(node)[0].replace(/\d+/g, '') == "ENTRY") {
                        //     setEntryCnt(preCunt => preCunt + 1);
                        //     console.log(entryCnt)
                        // } else if (Object.keys(node)[0].replace(/\d+/g, '') == "EXIT") {
                        //     setCloseCnt(preCnt => preCnt + 1)
                        // }
                        updateAxis(buildJSON, node[Object.keys(node)[0]].ID, X, Y)
                    }
                }

                let newObj = {
                    id: node[Object.keys(node)[0]]?.ID,
                    sourcePosition: 'bottom',
                    targetPosition: 'top',
                    type: 'selectorNode',
                    data: {
                        label: nodeStr.trim(),
                        type: Object.keys(node)[0]
                    },
                    position: { x: node[Object.keys(node)[0]].X ? node[Object.keys(node)[0]].X : X, y: node[Object.keys(node)[0]].Y ? node[Object.keys(node)[0]].Y : Y }
                }
                let isPresent = value.some(obj => obj.id === newObj.id);
                if (!isPresent) {
                    value.push(newObj);
                }
                // value.push(newObj);

                if (Object.keys(node[Object.keys(node)[0]]["YES"]).length == 0 && Object.keys(node)[0].replace(/\d+/g, '') !== "REPEAT" && Object.keys(node)[0].replace(/\d+/g, '') !== "END") {
                    let obj_y = {
                        id: node[Object.keys(node)[0]]?.ID + ".1",
                        targetPosition: 'top',
                        type: 'selectorNode',
                        // sourcePosition: 'bottom',
                        connectable: false,
                        data: {
                            label: "Y",
                            type: Object.keys(node)[0],
                            category: "sub",
                        },
                        position: { x: (Object.keys(node)[0].replace(/\d+/g, '') !== "CONDITION") ? node[Object.keys(node)[0]].X + 25 : (node[Object.keys(node)[0]].X) ? node[Object.keys(node)[0]].X - 50 : X - 50, y: node[Object.keys(node)[0]].Y ? node[Object.keys(node)[0]].Y + 100 : Y + 100 }
                    }
                    // value.push(obj_y);
                    let isPresent = value.some(obj => obj.id === obj_y.id);
                    if (!isPresent) {
                        value.push(obj_y);
                    }
                    // edge
                    let edgeObj_y = {
                        id: node[Object.keys(node)[0]]?.ID,
                        source: node[Object.keys(node)[0]]?.ID,
                        target: node[Object.keys(node)[0]]?.ID + '.1',
                        type: 'step',
                        animated: true,
                        label: 'Yes'
                    }
                    edgeObj.push(edgeObj_y);
                }

                if (Object.keys(node[Object.keys(node)[0]]["NO"]).length == 0 && Object.keys(node)[0].replace(/\d+/g, '') === "CONDITION" && Object.keys(node)[0].replace(/\d+/g, '') !== "REPEAT" && Object.keys(node)[0].replace(/\d+/g, '') !== "END") {
                    let obj_n = {
                        id: node[Object.keys(node)[0]]?.ID + ".2",
                        targetPosition: 'top',
                        type: 'selectorNode',
                        data: {
                            label: "N",
                            type: Object.keys(node)[0],
                            category: "sub",
                        },
                        position: { x: node[Object.keys(node)[0]].X ? node[Object.keys(node)[0]].X + 100 : X + 100, y: node[Object.keys(node)[0]].Y ? node[Object.keys(node)[0]].Y + 100 : Y + 100 }
                    }

                    let edgeObj_n = {
                        id: node[Object.keys(node)[0]]?.ID,
                        source: node[Object.keys(node)[0]]?.ID,
                        target: node[Object.keys(node)[0]]?.ID + '.2',
                        type: 'step',
                        animated: true,
                        label: 'No'
                    }
                    edgeObj.push(edgeObj_n);
                    // value.push(obj_n);
                    let isPresent = value.some(obj => obj.id === obj_n.id);
                    if (!isPresent) {
                        value.push(obj_n);
                    }
                }

                let newEdge1 = {};
                let newEdge2 = {};
                if (node[Object.keys(node)[0]]?.YES && Object.keys(node[Object.keys(node)[0]]?.YES).length > 0 && node[Object.keys(node)[0]]?.ID != "0") {
                    newEdge1 = {
                        id: node[Object.keys(node)[0]]?.ID,
                        source: node[Object.keys(node)[0]]?.ID,
                        target: node[Object.keys(node)[0]]?.ID + '.1',
                        type: 'smoothstep',
                        label: 'Yes'
                    }

                }
                if (node[Object.keys(node)[0]]?.NO && Object.keys(node[Object.keys(node)[0]]?.NO).length > 0 && node[Object.keys(node)[0]]?.ID != "0") {
                    newEdge2 = {
                        id: node[Object.keys(node)[0]]?.ID,
                        source: node[Object.keys(node)[0]]?.ID,
                        target: node[Object.keys(node)[0]]?.ID + '.2',
                        type: 'smoothstep',
                        label: 'No'
                    }
                }

                if (newEdge1 && Object.keys(newEdge1).length > 0) edgeObj.push(newEdge1);
                if (newEdge2 && Object.keys(newEdge2).length > 0) edgeObj.push(newEdge2);
            }
            if (node[Object.keys(node)[0]]?.YES != undefined) traverseTree(node[Object.keys(node)[0]]?.YES);
            if (node[Object.keys(node)[0]]?.NO != undefined) traverseTree(node[Object.keys(node)[0]]?.NO);
        }
        traverseTree(obj);
        return ({ value, edgeObj });
    }

    function getNodesHorizontal(obj) {
        value = [];
        edgeObj = [];
        function traverseTree(node) {
            if (node == null) {
                return value;
            }
            if (node[Object.keys(node)[0]] != undefined) {
                let nodeStr = node[Object.keys(node)[0]].TMP_ID !== 'REPEAT' ? Object.keys(node[Object.keys(node)[0]]['CONTENT']).map(key => node[Object.keys(node)[0]]['CONTENT'][key]).join(" ") : node[Object.keys(node)[0]]["CONTENT"];
                const parts = node[Object.keys(node)[0]].ID.split(".");
                if (parts?.join(".") === "1" || parts?.join(".") === "0") {
                    Y = window.innerHeight / 2 - 50;
                    X = 75;
                    updateAxis(buildJSON, node[Object.keys(node)[0]].ID, X, Y);
                    // if (Object.keys(node)[0].replace(/\d+/g, '') == "ENTRY") {
                    //     setEntryCnt(preCunt => preCunt + 1)
                    // }
                } else {
                    parts?.pop(); // Remove the last element
                    const outputString = parts?.join(".");
                    if (outputString != "" && node[Object.keys(node)[0]].X == undefined) {
                        let [axisX, axisY, curNode] = getAxis(buildJSON, outputString);
                        if (axisX === 0 || axisX === undefined) {
                            X = 0
                        } else if (clickStr == "YES" && Object.keys(curNode)[0].replace(/\d+/g, '') == "CONDITION") {
                            Y = axisY - 50;
                        } else if (clickStr == "NO" && Object.keys(curNode)[0].replace(/\d+/g, '') == "CONDITION") {
                            Y = axisY + 50;
                        } else {
                            Y = axisY;
                        }
                        X = (axisX === 0 || axisX === undefined) ? 0 : axisX + 50;

                        // if (Object.keys(node)[0].replace(/\d+/g, '') == "ENTRY") {
                        //     setEntryCnt(preCunt => preCunt + 1);
                        //     console.log(entryCnt)
                        // } else if (Object.keys(node)[0].replace(/\d+/g, '') == "EXIT") {
                        //     setCloseCnt(preCnt => preCnt + 1)
                        // }
                        updateAxis(buildJSON, node[Object.keys(node)[0]].ID, X, Y)
                    }
                }

                let newObj = {
                    id: node[Object.keys(node)[0]]?.ID,
                    sourcePosition: 'left',
                    targetPosition: 'right',
                    type: 'selectorNode',
                    data: {
                        label: nodeStr.trim(),
                        type: Object.keys(node)[0]
                    },
                    position: { x: node[Object.keys(node)[0]].X ? node[Object.keys(node)[0]].X : X, y: node[Object.keys(node)[0]].Y ? node[Object.keys(node)[0]].Y : Y }
                }
                let isPresent = value.some(obj => obj.id === newObj.id);
                if (!isPresent) {
                    value.push(newObj);
                }
                // value.push(newObj);

                if (Object.keys(node[Object.keys(node)[0]]["YES"]).length == 0 && Object.keys(node)[0].replace(/\d+/g, '') !== "REPEAT" && Object.keys(node)[0].replace(/\d+/g, '') !== "END") {
                    let obj_y = {
                        id: node[Object.keys(node)[0]]?.ID + ".1",
                        targetPosition: 'top',
                        type: 'selectorNode',
                        // sourcePosition: 'bottom',
                        connectable: false,
                        data: {
                            label: "Y",
                            type: Object.keys(node)[0],
                            category: "sub",
                        },
                        position: { x: (Object.keys(node)[0].replace(/\d+/g, '') !== "CONDITION") ? node[Object.keys(node)[0]].X + 25 : (node[Object.keys(node)[0]].X) ? node[Object.keys(node)[0]].X - 50 : X - 50, y: node[Object.keys(node)[0]].Y ? node[Object.keys(node)[0]].Y + 50 : Y + 50 }
                    }
                    // value.push(obj_y);
                    let isPresent = value.some(obj => obj.id === obj_y.id);
                    if (!isPresent) {
                        value.push(obj_y);
                    }
                    // edge
                    let edgeObj_y = {
                        id: node[Object.keys(node)[0]]?.ID,
                        source: node[Object.keys(node)[0]]?.ID,
                        target: node[Object.keys(node)[0]]?.ID + '.1',
                        type: 'step',
                        animated: true,
                        label: 'Yes'
                    }
                    edgeObj.push(edgeObj_y);
                }

                if (Object.keys(node[Object.keys(node)[0]]["NO"]).length == 0 && Object.keys(node)[0].replace(/\d+/g, '') === "CONDITION" && Object.keys(node)[0].replace(/\d+/g, '') !== "REPEAT" && Object.keys(node)[0].replace(/\d+/g, '') !== "END") {
                    let obj_n = {
                        id: node[Object.keys(node)[0]]?.ID + ".2",
                        targetPosition: 'top',
                        type: 'selectorNode',
                        data: {
                            label: "N",
                            type: Object.keys(node)[0],
                            category: "sub",
                        },
                        position: { x: node[Object.keys(node)[0]].X ? node[Object.keys(node)[0]].X + 100 : X + 50, y: node[Object.keys(node)[0]].Y ? node[Object.keys(node)[0]].Y + 50 : Y + 50 }
                    }

                    let edgeObj_n = {
                        id: node[Object.keys(node)[0]]?.ID,
                        source: node[Object.keys(node)[0]]?.ID,
                        target: node[Object.keys(node)[0]]?.ID + '.2',
                        type: 'step',
                        animated: true,
                        label: 'No'
                    }
                    edgeObj.push(edgeObj_n);
                    // value.push(obj_n);
                    let isPresent = value.some(obj => obj.id === obj_n.id);
                    if (!isPresent) {
                        value.push(obj_n);
                    }
                }

                let newEdge1 = {};
                let newEdge2 = {};
                if (node[Object.keys(node)[0]]?.YES && Object.keys(node[Object.keys(node)[0]]?.YES).length > 0 && node[Object.keys(node)[0]]?.ID != "0") {
                    newEdge1 = {
                        id: node[Object.keys(node)[0]]?.ID,
                        source: node[Object.keys(node)[0]]?.ID,
                        target: node[Object.keys(node)[0]]?.ID + '.1',
                        type: 'smoothstep',
                        label: 'Yes'
                    }

                }
                if (node[Object.keys(node)[0]]?.NO && Object.keys(node[Object.keys(node)[0]]?.NO).length > 0 && node[Object.keys(node)[0]]?.ID != "0") {
                    newEdge2 = {
                        id: node[Object.keys(node)[0]]?.ID,
                        source: node[Object.keys(node)[0]]?.ID,
                        target: node[Object.keys(node)[0]]?.ID + '.2',
                        type: 'smoothstep',
                        label: 'No'
                    }
                }

                if (newEdge1 && Object.keys(newEdge1).length > 0) edgeObj.push(newEdge1);
                if (newEdge2 && Object.keys(newEdge2).length > 0) edgeObj.push(newEdge2);
            }
            if (node[Object.keys(node)[0]]?.YES != undefined) traverseTree(node[Object.keys(node)[0]]?.YES);
            if (node[Object.keys(node)[0]]?.NO != undefined) traverseTree(node[Object.keys(node)[0]]?.NO);
        }
        traverseTree(obj);
        return ({ value, edgeObj });
    }

    function updateAxis(obj, id, x, y) {
        function traverseTree(node) {
            if (node == null) {
                return '1'
            }
            let incomingId = node[Object?.keys(node)[0]]?.ID;
            if (incomingId == id) {
                node[Object?.keys(node)[0]].X = x;
                node[Object?.keys(node)[0]].Y = y;
                setBuildJSON(obj);
                return node[Object?.keys(node)[0]];
            }
            else {
                traverseTree(node[Object.keys(node)[0]]?.YES);
                if (node[Object.keys(node)[0]]?.NO != undefined && Object.keys(node[Object.keys(node)[0]]?.NO).length > 0) {
                    traverseTree(node[Object.keys(node)[0]]?.NO);
                }
            }
        }
        traverseTree(obj, id);
    }

    function getAxis(obj, id) {
        var update = 0;
        var valueX = window.innerWidth / 2 - 60;
        var valueY = 0;
        var curNode = '';
        function traverseTree(node) {
            if (node == null) {
                return value;
            }
            let incomingId = node[Object?.keys(node)[0]]?.ID;
            if (incomingId == id && !update) {
                update = 1;
                valueX = node[Object?.keys(node)[0]]?.X;
                valueY = node[Object?.keys(node)[0]]?.Y;
                curNode = node;
                return node[Object?.keys(node)[0]]?.X;
            }
            else if (!update) {
                traverseTree(node[Object.keys(node)[0]]?.YES, id);
                if (node[Object.keys(node)[0]]?.NO != undefined && Object.keys(node[Object.keys(node)[0]]?.NO).length > 0) {
                    traverseTree(node[Object.keys(node)[0]]?.NO, id);
                }
            }
        }
        traverseTree(obj, id);
        return ([valueX, valueY, curNode]);
    }

    const handleJsonSave = () => {
        if (validateJson()) {
            setShow(!show);
            setIsShow(false)
        }
        else
            setIsShow(true)
    }

    const validateJson = () => {
        var isTrueJson = true;
        function traverseTree(node) {
            if (node == null || Object.keys(node).length === 0) {
                return null;
            }
            let incomingId = node[Object?.keys(node)[0]]?.ID;
            let isValid = node["TYPE"] === "END" || node["TYPE"] === "REPEAT";
            if (Object.keys(node[Object.keys(node)[0]]?.YES).length === 0 && !isValid) {
                isTrueJson = false;
                return false;
            } else if (Object.keys(node[Object.keys(node)[0]]?.NO).length === 0 && !isValid && node["TYPE"] === "CONDITION") {
                isTrueJson = false;
                return false;
            } else {
                traverseTree(node[Object.keys(node)[0]]?.YES, incomingId);
                if (node[Object.keys(node)[0]]?.NO != undefined && Object.keys(node[Object.keys(node)[0]]?.NO).length > 0) {
                    traverseTree(node[Object.keys(node)[0]]?.NO, incomingId);
                }
            }
        }
        traverseTree(buildJSON, "1");
        return isTrueJson
    }

    const LeftPanel = (props) => {

        const handleTags = () => {
            props.setExpandedR(true);
            props.setCurSelection("tags");
        }

        const handleExitPosition = () => {
            props.setExpandedR(true);
            props.setCurSelection("close-position");
        }

        const handleRepeat = () => {
            props.setExpandedR(true);
            props.setCurSelection("repeat");
        }

        const handleEnd = () => {
            props.setExpandedR(true);
            props.setCurSelection("end");
        }
        return (
            <Row>
                <Col xs={2} style={{ backgroundColor: '#f8f9fa', padding: '10px', transition: 'width 0.5s' }}>
                    {!props.expandedL && <button class="btn flowchart-sidebar left" onClick={() => setExpandedL(!expandedL)}>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </button>}
                    {expandedL && <div>
                        <div >
                            <div class="card card-body flowchart-sidebar-wrapper" style={{ width: "300px", zIndex: "99999" }}>
                                <Row>
                                    <Col sm={8}>
                                        <h5>Action Panel</h5>
                                    </Col>
                                    <Col sm={4}>
                                        <button class="btn flowchart-sidebar right"
                                            style={{
                                                position: "absolute",
                                                right: "0px",
                                                top: "0px"
                                            }}
                                            onClick={() => setExpandedL(!expandedL)}
                                        >
                                            <FontAwesomeIcon icon={faAngleLeft} />
                                        </button>
                                    </Col>
                                    <Col sm={12}>
                                        <div class="accordion accordion-flush" id="accordionFlushDecision">
                                            <div class="accordion-item bg-gray" style={{
                                                borderRadius: "10px",
                                                border: "1px solid #eee"
                                            }}>
                                                <h2 class="accordion-header">
                                                    <button class=" b-none bg-white accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseDecision" aria-expanded="false" aria-controls="flush-collapseDecision" style={{
                                                        borderRadius: "10px",
                                                    }}>
                                                        Decision
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseDecision" class="bg-gray accordion-collapse collapse-" data-bs-parent="#accordionFlushDecision">
                                                    <DecisionV1
                                                        setExpandedL={setExpandedL}
                                                        handleClose={props.handleClose}
                                                        clickStr={props.clickStr}
                                                        type="decision"
                                                        buildJSON={props.buildJSON}
                                                        setBuildJSON={props.setBuildJSON}
                                                        iterateCnt={props.iterateCnt}
                                                        setIterateCnt={props.setIterateCnt}
                                                        parentNode={props.parentNode}
                                                        setParentNode={props.setParentNode}
                                                        nodeKey={props.nodeKey}
                                                        conditionCount={props.conditionCount}
                                                        setConditionCount={props.setConditionCount}
                                                        id={props.id}
                                                        setId={props.setId}
                                                        openPositions={props.openPositions}
                                                        decitionContent={decitionContent}
                                                        setDecitionContent={setDecitionContent}
                                                        tagNames={tagNames}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div class="accordion accordion-flush mt-2" id="accordionFlushOpenPosition">
                                            <div class="accordion-item" style={{
                                                borderRadius: "10px",
                                                border: "1px solid #eee"
                                            }}>
                                                <h2 class="accordion-header">
                                                    <button class=" b-none bg-white accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOpenPosition" aria-expanded="false" aria-controls="flush-collapseOpenPosition" style={{
                                                        borderRadius: "10px"
                                                    }}>
                                                        Open Positions
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOpenPosition" class=" bg-gray accordion-collapse collapse" data-bs-parent="#accordionFlushOpenPosition">
                                                    <OpenDecision
                                                        expandedR={expandedR}
                                                        setExpandedR={setExpandedR}
                                                        setExpandedL={setExpandedL}
                                                        handleClose={props.handleClose}
                                                        clickStr={props.clickStr}
                                                        type="open-position"
                                                        buildJSON={props.buildJSON}
                                                        setBuildJSON={props.setBuildJSON}
                                                        iterateCnt={props.iterateCnt}
                                                        setIterateCnt={props.setIterateCnt}
                                                        parentNode={props.parentNode}
                                                        setParentNode={props.setParentNode}
                                                        nodeKey={props.nodeKey}
                                                        id={props.id}
                                                        entryCnt={props.entryCnt}
                                                        setEntryCnt={props.setEntryCnt}
                                                        openPositions={props.openPositions}
                                                        setOpenPositions={props.setOpenPositions}
                                                        curSelection={curSelection}
                                                        setCurSelection={setCurSelection}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div class="accordion accordion-flush mt-2" id="accordionFlushClosePosition">
                                            <div class="accordion-item" style={{
                                                borderRadius: "10px",
                                                border: "1px solid #eee"
                                            }}>
                                                <h2 class="accordion-header">
                                                    <button class=" b-none bg-white accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseClosePosition" aria-expanded="false" aria-controls="flush-collapseClosePosition" style={{
                                                        borderRadius: "10px"
                                                    }} onClick={handleExitPosition}>
                                                        Close Position
                                                    </button>
                                                </h2>
                                            </div>
                                        </div>

                                        <div class="accordion accordion-flush mt-2" id="accordionFlushUpdatePosition">
                                            <div class="accordion-item" style={{
                                                borderRadius: "10px",
                                                border: "1px solid #eee"
                                            }}>
                                                <h2 class="accordion-header">
                                                    <button class=" b-none bg-white accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseUpdatePosition" aria-expanded="false" aria-controls="flush-collapseUpdatePosition" style={{
                                                        borderRadius: "10px"
                                                    }}>
                                                        Adjust Positions
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseUpdatePosition" class=" bg-gray accordion-collapse collapse" data-bs-parent="#accordionFlushUpdatePosition">
                                                    <UpdatePosition
                                                        expandedR={expandedR}
                                                        setExpandedR={setExpandedR}
                                                        setExpandedL={setExpandedL}
                                                        handleClose={props.handleClose}
                                                        clickStr={props.clickStr}
                                                        type="exist-position"
                                                        buildJSON={props.buildJSON}
                                                        setBuildJSON={props.setBuildJSON}
                                                        iterateCnt={props.iterateCnt}
                                                        setIterateCnt={props.setIterateCnt}
                                                        parentNode={props.parentNode}
                                                        setParentNode={props.setParentNode}
                                                        nodeKey={props.nodeKey}
                                                        id={props.id}
                                                        updateCnt={props.updateCnt}
                                                        setUpdateCnt={props.setUpdateCnt}
                                                        openPositions={props.openPositions}
                                                        curSelection={curSelection}
                                                        setCurSelection={setCurSelection}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion accordion-flush mt-2" id="accordionFlushTags">
                                            <div class="accordion-item" style={{
                                                borderRadius: "10px",
                                                border: "1px solid #eee"
                                            }}>
                                                <h2 class="accordion-header">
                                                    <button class=" b-none bg-white accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTags" aria-expanded="false" aria-controls="flush-collapseTags" style={{
                                                        borderRadius: "10px"
                                                    }} onClick={handleTags}>
                                                        Tags
                                                    </button>
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="accordion accordion-flush mt-2" id="accordionFlushRepeat">
                                            <div class="accordion-item" style={{
                                                borderRadius: "10px",
                                                border: "1px solid #eee"
                                            }}>
                                                <h2 class="accordion-header">
                                                    <button class=" b-none bg-white accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseRepeat" aria-expanded="false" aria-controls="flush-collapseRepeat" style={{
                                                        borderRadius: "10px"
                                                    }} onClick={handleRepeat}>
                                                        Repeat
                                                    </button>
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="accordion accordion-flush mt-2" id="accordionFlushEnd">
                                            <div class="accordion-item" style={{
                                                borderRadius: "10px",
                                                border: "1px solid #eee"
                                            }}>
                                                <h2 class="accordion-header">
                                                    <button class=" b-none bg-white accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEnd" aria-expanded="false" aria-controls="flush-collapseEnd" style={{
                                                        borderRadius: "10px"
                                                    }} onClick={handleEnd}>
                                                        End
                                                    </button>
                                                </h2>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>}
                </Col>
            </Row>
        );
    };

    const RightPanel = (props) => {

        return (
            <Row>
                <Col xs={2} style={{ backgroundColor: '#f8f9fa', float: "right", padding: '10px', transition: 'width 0.5s' }}>
                    {/* {!expandedR && <button class="btn flowchart-sidebar right" onClick={() => setExpandedR(!expandedR)}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>} */}
                    {expandedR && <div>
                        <div >
                            <div class="card card-body flowchart-sidebar-wrapper" style={{ width: "300px", zIndex: "99999" }}>
                                <Row>
                                    <Col sm={8}>
                                        <h5>Properties</h5>
                                    </Col>
                                    <Col sm={4}>
                                        <button class="btn flowchart-sidebar right"
                                            style={{
                                                position: "absolute",
                                                right: "0px",
                                                top: "0px"
                                            }}
                                            onClick={() => setExpandedR(!expandedR)}
                                        >
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </button>

                                    </Col>
                                    {curSelection === "open-position" && <Col sm={12}>
                                        <PositionItems
                                            clickStr={props.clickStr}
                                            type="open-position"
                                            buildJSON={props.buildJSON}
                                            setBuildJSON={props.setBuildJSON}
                                            iterateCnt={props.iterateCnt}
                                            setIterateCnt={props.setIterateCnt}
                                            parentNode={props.parentNode}
                                            setParentNode={props.setParentNode}
                                            closeModal={props.handleClose}
                                            nodeKey={props.nodeKey}
                                            entryCnt={props.entryCnt}
                                            setEntryCnt={props.setEntryCnt}
                                            id={props.id}
                                            setId={props.setId}
                                            openPositions={props.openPositions}
                                            setOpenPositions={props.setOpenPositions}
                                            expandedR={props.expandedR}
                                            expandedL={props.expandedL}
                                            setExpandedL={props.setExpandedL}
                                            setExpandedR={props.setExpandedR}
                                            isUpdateJson={isUpdateJson}
                                            setIsUpdateJson={setIsUpdateJson}
                                        />
                                    </Col>}

                                    {curSelection === 'close-position' && <Col sm={12}>
                                        <ClosePosition
                                            handleClose={props.handleClose}
                                            clickStr={props.clickStr}
                                            type="close-position"
                                            buildJSON={props.buildJSON}
                                            setBuildJSON={props.setBuildJSON}
                                            iterateCnt={props.iterateCnt}
                                            setIterateCnt={props.setIterateCnt}
                                            parentNode={props.parentNode}
                                            setParentNode={props.setParentNode}
                                            nodeKey={props.nodeKey}
                                            id={props.id}
                                            closeCnt={props.closeCnt}
                                            setCloseCnt={props.setCloseCnt}
                                            openPositions={props.openPositions}
                                            setOpenPositions={props.setOpenPositions}
                                            expandedR={props.expandedR}
                                            expandedL={props.expandedL}
                                            setExpandedL={props.setExpandedL}
                                            setExpandedR={props.setExpandedR}
                                            isUpdateJson={isUpdateJson}
                                            setIsUpdateJson={setIsUpdateJson}
                                        />
                                    </Col>}

                                    {curSelection === "update-position" && <Col sm={12}>
                                        <UpdateInputs
                                            clickStr={props.clickStr}
                                            type="exist-position"
                                            isUpdateJson={isUpdateJson}
                                            setIsUpdateJson={setIsUpdateJson}
                                            updateCnt={props.updateCnt}
                                            setUpdateCnt={props.setUpdateCnt}
                                            parentNode={parentNode}
                                            setParentNode={setParentNode}
                                            iterateCnt={props.iterateCnt}
                                            buildJSON={props.buildJSON}
                                            setBuildJSON={props.setBuildJSON}
                                            id={props.id}
                                            expandedR={props.expandedR}
                                            expandedL={props.expandedL}
                                            setExpandedL={props.setExpandedL}
                                            setExpandedR={props.setExpandedR}
                                        />
                                    </Col>}

                                    {curSelection === "tags" && <Col sm={12}>
                                        <Tags
                                            expandedR={expandedR}
                                            setExpandedR={setExpandedR}
                                            setExpandedL={setExpandedL}
                                            clickStr={props.clickStr}
                                            type="tags"
                                            buildJSON={props.buildJSON}
                                            setBuildJSON={props.setBuildJSON}
                                            iterateCnt={props.iterateCnt}
                                            setIterateCnt={props.setIterateCnt}
                                            parentNode={props.parentNode}
                                            setParentNode={props.setParentNode}
                                            nodeKey={props.nodeKey}
                                            conditionCount={props.conditionCount}
                                            id={props.id}
                                            isUpdateJson={isUpdateJson}
                                            setIsUpdateJson={setIsUpdateJson}
                                            tagsCnt={props.tagsCnt}
                                            setTagsCnt={props.setTagsCnt}
                                            tagNames={tagNames}
                                            setTagNames={setTagNames}
                                        />

                                    </Col>}
                                    {curSelection === "repeat" && <Col sm={12}>
                                        <Repeat
                                            expandedR={expandedR}
                                            setExpandedR={setExpandedR}
                                            setExpandedL={setExpandedL}
                                            clickStr={props.clickStr}
                                            type="repeat"
                                            buildJSON={props.buildJSON}
                                            setBuildJSON={props.setBuildJSON}
                                            iterateCnt={props.iterateCnt}
                                            setIterateCnt={props.setIterateCnt}
                                            parentNode={props.parentNode}
                                            setParentNode={props.setParentNode}
                                            nodeKey={props.nodeKey}
                                            conditionCount={props.conditionCount}
                                            id={props.id}
                                            isUpdateJson={isUpdateJson}
                                            setIsUpdateJson={setIsUpdateJson}
                                            repeatCnt={props.repeatCnt}
                                            setRepeatCnt={props.setRepeatCnt}
                                        />
                                    </Col>}
                                    {curSelection === "end" && <Col sm={12}>
                                        <EndNode
                                            expandedR={expandedR}
                                            setExpandedR={setExpandedR}
                                            setExpandedL={setExpandedL}
                                            clickStr={props.clickStr}
                                            type="end"
                                            buildJSON={props.buildJSON}
                                            setBuildJSON={props.setBuildJSON}
                                            iterateCnt={props.iterateCnt}
                                            setIterateCnt={props.setIterateCnt}
                                            parentNode={props.parentNode}
                                            setParentNode={props.setParentNode}
                                            nodeKey={props.nodeKey}
                                            conditionCount={props.conditionCount}
                                            id={props.id}
                                            isUpdateJson={isUpdateJson}
                                            setIsUpdateJson={setIsUpdateJson}
                                        />
                                    </Col>}
                                </Row>
                            </div>
                        </div>
                    </div>}
                </Col>
            </Row >
        )
    };

    const convertToVerticalView = () => {
        if (flowchartFlow === "H") {
            setFlowchartFlow("V");
            let chartObj = getNodes(buildJSON);
            if (chartObj.value.length > 0) {
                let updateVal = [
                    ...chartObj.value, ...chartJson.value
                ]
                chartObj.value = updateVal;
                let updateObj = [
                    {
                        id: "0",
                        source: "0",
                        target: '1',
                        type: 'smoothstep',
                        // animated: true,
                        label: ''
                    }
                    , ...chartObj.edgeObj
                ]
                chartObj.edgeObj = updateObj;
                setChartJson(chartObj);
            }
        }
    }

    const convertToHorizontalView = () => {
        if (flowchartFlow === "V") {
            setFlowchartFlow("H")
            let chartObj = getNodesHorizontal(buildJSON);
            if (chartObj.value.length > 0) {
                let updateVal = [
                    ...chartObj.value, ...chartJson.value
                ]
                chartObj.value = updateVal;
                let updateObj = [
                    {
                        id: "0",
                        source: "0",
                        target: '1',
                        type: 'smoothstep',
                        // animated: true,
                        label: ''
                    }
                    , ...chartObj.edgeObj
                ]
                chartObj.edgeObj = updateObj;
                console.log("chartObj", chartObj)
                setChartJson(chartObj);
            }
        }
    }

    return (
        <div className="bg-white flowchart-bg" style={{ minWidth: sectionWidth != 0 ? sectionWidth + 300 : "100%", border: 'none' }}>
            <Toaster setIsShow={setIsShow} isShow={isShow} />
            <div className='text-right chart-header-row' >
                <Col md={12} className='text-right'>
                    <span className='btn-orientation-wrapper'>
                        <Button className={flowchartFlow === "H" ? "btn-selected" : "btn-transparent"} onClick={convertToHorizontalView}>
                            <FontAwesomeIcon className='text-white' icon={faArrowRight} />
                        </Button>
                        <Button ref={target} onClick={convertToVerticalView} className={flowchartFlow === "V" ? "btn-selected" : "btn-transparent"}>
                            <FontAwesomeIcon className='text-white' icon={faArrowDown} />
                        </Button>
                    </span>
                    <Button className='btn-transparent btn m-1' onClick={navigateToStrategy}>
                        Close
                    </Button>
                    <Button variant="primary" ref={target} onClick={handleJsonSave}>
                        Save
                    </Button>
                    <Overlay target={target.current} show={show} placement="bottom">
                        {({
                            placement: _placement,
                            arrowProps: _arrowProps,
                            show: _show,
                            popper: _popper,
                            hasDoneInitialMeasure: _hasDoneInitialMeasure,
                            ...props
                        }) => (
                            <div
                                {...props}
                                style={{
                                    position: 'absolute',
                                    backgroundColor: 'var(--primary-color)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    ...props.style,
                                }}
                            >
                                <Form.Control type="text" value={strategyName} className='strategy-input'
                                    onChange={e => setStrategyName(e.target.value)} placeholder="Enter strategy name" />
                                {strategyName == "" && <Button className='btn-white btn  m-1' onClick={saveJson} disabled>Submit
                                </Button>}
                                {strategyName != "" && <Button className='btn-white btn  m-1' onClick={saveJson}>
                                    Submit
                                </Button>}
                            </div>
                        )}
                    </Overlay>
                </Col>
            </div>
            <Row>
                <Col md={12} style={{ cursor: cursorStyle, maxHeight: "420px" }} className='text-center flowchart-container '>
                    <LeftPanel
                        expandedL={expandedL}
                        setExpandedL={setExpandedL}
                        id={id}
                        setId={setId}
                        setCurSelection={setCurSelection}
                        expandedR={expandedR}
                        setExpandedR={setExpandedR}
                        clickStr={clickStr}
                        buildJSON={buildJSON}
                        setBuildJSON={setBuildJSON}
                        iterateCnt={iterateCnt}
                        setIterateCnt={setIterateCnt}
                        parentNode={parentNode}
                        setParentNode={setParentNode}
                        entryCnt={entryCnt}
                        setEntryCnt={setEntryCnt}
                        openPositions={openPositions}
                        setOpenPositions={setOpenPositions}
                        updateCnt={updateCnt}
                        setUpdateCnt={setUpdateCnt}
                        conditionCount={conditionCount}
                        closeCnt={closeCnt}
                        setCloseCnt={setCloseCnt}

                    />
                    <Row>
                        <Col md={12} className='text-left'>
                            {(decisionObject.showFields && decisionObject.selectedObj != '') && <DecisionItems
                                decitionContent={decitionContent}
                                dataObj={decisionObject.selectedObj}
                                // updateObj={handleObj}
                                isOpen={decisionObject.showFields}
                                clickStr={clickStr}
                                type='decision'
                                decisionObject={decisionObject}
                                hideFields={setDecisionObject}
                                buildJSON={buildJSON}
                                setBuildJSON={setBuildJSON}
                                iterateCnt={iterateCnt}
                                setIterateCnt={setIterateCnt}
                                parentNode={parentNode}
                                setParentNode={setParentNode}
                                // closeModal={handleClose}
                                // nodeKey={nodeKey}
                                conditionCount={conditionCount}
                                setConditionCount={setConditionCount}
                                id={id}
                                setId={setId}
                                openPositions={openPositions}
                                expandedL={expandedL}
                                setExpandedL={setExpandedL}
                                isUpdateJson={isUpdateJson}
                                setIsUpdateJson={setIsUpdateJson}
                                tagNames={tagNames}
                            />}
                        </Col>
                    </Row>

                    <div style={{
                        position: "absolute",
                        right: "310px",
                    }}>
                        <div className='text-left'>
                            <RightPanel
                                expandedL={expandedL}
                                setExpandedL={setExpandedL}
                                expandedR={expandedR}
                                setExpandedR={setExpandedR}
                                id={id}
                                setId={setId}
                                clickStr={clickStr}
                                buildJSON={buildJSON}
                                setBuildJSON={setBuildJSON}
                                iterateCnt={iterateCnt}
                                setIterateCnt={setIterateCnt}
                                parentNode={parentNode}
                                setParentNode={setParentNode}
                                entryCnt={entryCnt}
                                setEntryCnt={setEntryCnt}
                                openPositions={openPositions}
                                setOpenPositions={setOpenPositions}
                                updateCnt={updateCnt}
                                setUpdateCnt={setUpdateCnt}
                                conditionCount={conditionCount}
                                repeatCnt={repeatCnt}
                                setRepeatCnt={setRepeatCnt}
                                tagsCnt={tagsCnt}
                                setTagsCnt={setTagsCnt}
                                tagNames={tagNames}
                                setTagNames={setTagNames}
                                closeCnt={closeCnt}
                                setCloseCnt={setCloseCnt}

                            />
                        </div>
                    </div>
                    {Object.keys(chartJson).length > 0 && chartJson.value.length > 0 && isChart && <span className="flow-chart"><Flowchart
                        strategy_id={window.location.search.split("=")[1]}
                        data={buildJSON}
                        setBuildJSON={setBuildJSON}
                        chartObj={chartJson}
                        setJsonKey={setJsonKey}
                        setSelectedItem={setSelectedItem}
                        handleOpenModal={handleOpenModal}
                        setClickStr={setClickStr}
                        setShowModal={setShowModal}
                        nodeNo={nodeNo}
                        setNodeNo={setNodeNo}
                        updateNodes={updateNodes}
                        setUpdateNodes={setUpdateNodes}
                        setParentNode={setParentNode}
                        expandedL={expandedL}
                        setExpandedL={setExpandedL}
                        id={id}
                        setId={setId}

                    /></span>}
                    {!isChart && <ChartLoader />}
                </Col>
            </Row>
            <StrategyItems
                show={showModal}
                handleClose={handleCloseModal}
                clickStr={clickStr}
                buildJSON={buildJSON}
                setBuildJSON={setBuildJSON}
                iterateCnt={iterateCnt}
                setIterateCnt={setIterateCnt}
                parentNode={parentNode}
                setParentNode={setParentNode}
                nodeKey={nodeNo}
                conditionCount={conditionCount}
                setConditionCount={setConditionCount}
                entryCnt={entryCnt}
                setEntryCnt={setEntryCnt}
                closeCnt={closeCnt}
                setCloseCnt={setCloseCnt}
                updateCnt={updateCnt}
                setUpdateCnt={setUpdateCnt}
                selectedItem={selectedItem}
                repeatCnt={repeatCnt}
                setRepeatCnt={setRepeatCnt}
                tagsCnt={tagsCnt}
                setTagsCnt={setTagsCnt}
                tagNames={tagNames}
                setTagNames={setTagNames}
                id={jsonKey}
                updateNodes={updateNodes}
                setUpdateNodes={setUpdateNodes}
                openPositions={openPositions}
                setOpenPositions={setOpenPositions}
            />
        </div>
    );
}

export default Strategy;
