import React, { useEffect, useState } from 'react';
import { Nav, Container, Row, Col, Button, Form, Dropdown, Tab, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faChevronLeft, faGreaterThan, faGreaterThanEqual, faLessThan, faLessThanEqual, faList, faPlus } from '@fortawesome/free-solid-svg-icons';
import './Strategy.css';

function Repeat(props) {

    const [repeatStr, setRepeatStr] = useState('');
    // const [positionVal, setPositionVal] = useState('');
    const [update, setUpdate] = useState(false);

    const updateInput = (val) => {
        setRepeatStr(val);
    }

    const cancelData = () => {
        props.setExpandedR(!props.expandedR);
        props.setExpandedL(!props.expandedL);
    }

    const saveData = () => {
        let count = props.repeatCnt + 1;
        props.setRepeatCnt(count);
        let json = {
            ["REPEAT" + count]: { TMP_ID: 'REPEAT', "CONTENT": repeatStr, ID: props.clickStr == 'start' ? '1' : (props.id), NO: {}, YES: {} },
            TYPE: "REPEAT",
            KEY: "REPEAT" + count,
        };
        if (props.type == 'repeat') {
            // const updatedJSON = findAndUpdateInnermostNoObject(props.buildJSON, json);
            findAndUpdateObj(props, json, props.clickStr);
        }
        props.setIsUpdateJson(!props.isUpdateJson);
        // props.handleClose(true);
    }

    function findAndUpdateObj(obj, json, clickStr) {
        function traverseTree(node, json, id, nodeType) {
            if (node == null || Object.keys(node) == 0) {
                return;
            }
            if (node[Object.keys(node)[0]]?.ID == id) {
                node[Object.keys(node)[0]][nodeType] = json;
                props.setExpandedR(false)
                props.setExpandedL(false)
                return 0;
            }
            traverseTree(node[Object.keys(node)[0]]?.YES, json, id, clickStr);
            traverseTree(node[Object.keys(node)[0]]?.NO, json, id, clickStr);
            traverseTree(node[Object.keys(node)[0]]?.ENTRY, json, id, clickStr);
        }
        let lastDotIndex = props.id.lastIndexOf(".");
        let desiredId = props.id.substring(0, lastDotIndex);
        traverseTree(obj.buildJSON, json, desiredId, clickStr);
        return obj;
    }

    const ConditionCnt = () => {
        const numbers = [
            <option key='0' value=''>Select repeat condition</option>
        ];
        for (let i = 1; i <= props.conditionCount; i++) {
            numbers.push(<option key={i} value={`CONDITION${i}`}>CONDITION{i}</option>);
        }
        return <div>
            <select className='form-control' onChange={e => updateInput(e.target.value)} value={repeatStr} >{numbers}</select>
        </div>;
    }

    return (
        <Row>
            <Col md={12}>
                <Form.Group as={Row} className='mt-3' >
                    {/* <Form.Label column sm={3}> POSITION SIZE : </Form.Label> */}
                    <Col sm={9}>
                        {/* <Form.Control type="text" value={repeatStr}
                            onChange={e => updateInput('size', e.target.value)} placeholder="Repeat to" /> */}
                        <ConditionCnt />

                    </Col>
                </Form.Group>
            </Col>
            <Col md={12} className='text-right mt-3'>
                <button className='btn pl-0 btn-transparent border text-dark' style={{ marginRight: "10px", padding: "8px 13px" }} onClick={cancelData} >
                    Cancel
                </button>
                <button className='btn pl-0 btn-primary' onClick={saveData} >
                    Save
                </button>
            </Col>
        </Row >
    );
}

export default Repeat;
