import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import './../Strategy.css';

function EndNode(props) {

    const cancelData = () => {
        props.setExpandedR(!props.expandedR);
        props.setExpandedL(!props.expandedL);
    }

    const saveData = () => {
        let json = {
            "END": { TMP_ID: 'END', "CONTENT": "END", ID: props.clickStr == 'start' ? '1' : props.id, NO: {}, YES: {} },
            TYPE: "END",
            KEY: 'END',
        };
        if (props.type == 'end') {
            // const updatedJSON = findAndUpdateInnermostNoObject(props.buildJSON, json);
            findAndUpdateObj(props, json, props.clickStr);
        }
        props.setIsUpdateJson(!props.isUpdateJson);
        // props.handleClose(true);
    }


    function findAndUpdateObj(obj, json, clickStr) {
        function traverseTree(node, json, id, nodeType) {
            if (node == null || Object.keys(node) == 0) {
                return;
            }
            if (node[Object.keys(node)[0]]?.ID == id) {
                node[Object.keys(node)[0]][nodeType] = json;
                props.setExpandedR(false)
                props.setExpandedL(false)
                return 0;
            }
            traverseTree(node[Object.keys(node)[0]]?.YES, json, id, clickStr);
            traverseTree(node[Object.keys(node)[0]]?.NO, json, id, clickStr);
            traverseTree(node[Object.keys(node)[0]]?.ENTRY, json, id, clickStr);
        }
        let lastDotIndex = props.id.lastIndexOf(".");
        let desiredId = props.id.substring(0, lastDotIndex);
        traverseTree(obj.buildJSON, json, desiredId, clickStr);
        return obj;
    }

    return (
        <Row>
            <Col md={12}>
                <Form.Group as={Row} className='mt-3' >
                    {/* <Form.Label column sm={3}> POSITION SIZE : </Form.Label> */}
                    <Col sm={9}>
                        <h4>Are you sure you want to end the node?</h4>
                    </Col>
                </Form.Group>
            </Col>
            <Col md={12} className='text-right mt-3'>
                <button className='btn pl-0 btn-transparent border text-dark' style={{ marginRight: "10px", padding: "8px 13px" }} onClick={cancelData} >
                    Cancel
                </button>
                <button className='btn pl-0 btn-primary' onClick={saveData} >
                    Save
                </button>
            </Col>
        </Row >
    );
}

export default EndNode;
