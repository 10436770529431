import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Login from './components/Login';
import Main from './components/Main';
import Strategies from './components/strategy/Strategies';
import MarketPlace from './components/strategy/MarketPlace';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { Col, Row } from 'react-bootstrap';
import Header from './components/Header';
import loginImg from '../src/assets/images/loginImg.png'
import Footer from './components/Footer';
import { MyProvider } from './context/MyContext';

function App() {
  const [login, setLogin] = useState(localStorage.getItem("login") ? localStorage.getItem("login") : false);
//alert(login+'=='+localStorage.getItem("login"))
  return (
    <>
    <MyProvider>
      <Router>
        <Routes>
           <Route path="/" element={login ? <Main /> : <Login setLogin={setLogin}/>} />
           <Route path="/home" element={login ? <Main setLogin={setLogin} login={login} /> : <Login setLogin={setLogin}/>} />
           <Route path="/strategies" element={login ? <Strategies /> : <Login setLogin={setLogin}/>} />
           <Route path="/strategy" element={login ? <Main setLogin={setLogin} /> : <Login setLogin={setLogin}/>} />
           <Route path="/marketplace" element={login ? <MarketPlace /> : <Login setLogin={setLogin}/>} />
        </Routes>
      </Router>
      </MyProvider>
    </>
  );
}

export default App;