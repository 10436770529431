import React, { useState } from 'react';
import { Row, Col, Tab, ListGroup, Tabs } from 'react-bootstrap';
import '../Strategy.css';
import PositionItems from './OpenDecisionItems'
import decisionValues from "../../openPosition.json";
import { useMyContext } from '../../../context/MyContext';

function OpenDecision(props) {
    let [selectedObj, setSelectedObj] = useState('');
    let [showFields, setShowFields] = useState(false);
    let [decitionContent, setDecitionContent] = useState(decisionValues.decitionItems[0].key);

    const { strategyObject, setStrategyObject } = useMyContext();
    const handleState = (obj) => {
        setSelectedObj(obj);
        props.setExpandedR(true);
        props.setCurSelection("open-position")
        props.setExpandedL(false);
        setShowFields(true);

        setStrategyObject({ dataObj: obj, showFields: true, type: props.type, nodeKey: props.nodeKey, id: props.id, decitionContent: decitionContent })
    }

    const handleSelect = (key) => {
        // Handle tab selection if needed
        setDecitionContent(key);
        setShowFields(false);
    };

    return (
        <Row>
            <Col md={12}>

                <Tabs defaultActiveKey={decisionValues.decitionItems[0].key} onSelect={handleSelect} className='decition-tabs'>
                    {decisionValues.decitionItems.map((tab) => (
                        <Tab key={tab.key} eventKey={tab.key} title={tab.title}>
                            <ListGroup className={showFields ? 'hide' : 'show'}>
                                {(decisionValues.decitionData[tab.key])?.map((item, index) => (
                                    <ListGroup.Item style={{ cursor: 'pointer' }} onClick={() => handleState(item)}>
                                        {item.value}
                                    </ListGroup.Item>
                                ))}

                            </ListGroup>
                        </Tab>
                    ))}
                </Tabs>

                {(showFields && selectedObj != '') && <PositionItems
                    decitionContent={decitionContent}
                    dataObj={selectedObj}
                    // updateObj={handleObj}
                    isOpen={showFields}
                    clickStr={props.clickStr}
                    type={props.type}
                    hideFields={setShowFields}
                    buildJSON={props.buildJSON}
                    setBuildJSON={props.setBuildJSON}
                    iterateCnt={props.iterateCnt}
                    setIterateCnt={props.setIterateCnt}
                    parentNode={props.parentNode}
                    setParentNode={props.setParentNode}
                    closeModal={props.handleClose}
                    nodeKey={props.nodeKey}
                    entryCnt={props.entryCnt}
                    setEntryCnt={props.setEntryCnt}
                    id={props.id}
                    openPositions={props.openPositions}
                    setOpenPositions={props.setOpenPositions}
                />}
            </Col >
        </Row >
    );
}

export default OpenDecision;
