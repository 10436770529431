import React, { useEffect, useState } from 'react';
import { Nav, Container, Row, Col, Button, Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faChevronLeft, faGreaterThan, faGreaterThanEqual, faLessThan, faLessThanEqual, faList, faPlus } from '@fortawesome/free-solid-svg-icons';
import './Strategy.css';
import DecisionInputes from './DecisionInputs/DecisionInputs'
import decisionValues from "../decision.json";
import { useMyContext } from '../../context/MyContext';
import { updateEdge } from 'reactflow';

function DecisionItems(props) {

    const [activeSection, setActiveSection] = useState(1);
    const [decisionData, setDecisionData] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);
    const [inputItems, setInputItems] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [conditionCnt, setConditionCnt] = useState(0);
    const [errorMsg, setErrorMsg] = useState("");
    const { decisionObject, setDecisionObject } = useMyContext();
    let templateData = decisionValues.templateData[props.decitionContent];
    let templateObj = decisionValues.templateObj;
    const [decisionValue, setDecisionValue] = useState({});
    console.log(props.decitionContent, templateData, props.dataObj?.id, templateData['id' + props.dataObj?.id]);
    const [inputObjId, setInputObjId] = useState(templateData['id' + props.dataObj?.id]['field_id'])

    useEffect(() => {
        setDecisionValue(decisionValue)
    }, [decisionValue])

    const [selectedInput, setSelectedInput] = useState(Object.keys(templateData['id' + props.dataObj.id])[1]);


    const cancelData = () => {
        props.hideFields(prevState => ({
            ...prevState,
            showFields: false
        }));
    }

    const saveData = () => {
        props.setExpandedL(false)
        let isValid = true;
        setErrorMsg("")
        Object.keys(decisionValue[Object.keys(decisionValue)[0]]).map((key) => {
            if (decisionValue[Object.keys(decisionValue)[0]][key] === null) {
                isValid = false;
                setErrorMsg("Fields cannot be blank!")
            }
        })
        if (isValid) {
            if ((props.clickStr == 'start' || props.clickStr == 'YES' || props.clickStr == 'NO') && props.type == 'decision') {
                let count = props.conditionCount + 1;
                props.setConditionCount(count);
                setConditionCnt(count);
                let json = {
                    ['CONDITION' + count]: {
                        TMP_ID: Object.keys(decisionValue)[0],
                        CONTENT: decisionValue[Object.keys(decisionValue)[0]],
                        YES: {},
                        NO: {},
                        // ID: props.clickStr == 'start' ? '1' : (props.clickStr == 'YES' ? decisionData.id + '.1' : decisionData.id + '.2')
                        ID: props.clickStr == 'start' ? '1' : (props.id)
                    },
                    TYPE: "CONDITION",
                    KEY: 'CONDITION' + count,
                }
                if (props.clickStr == 'start') {
                    props.setBuildJSON(json);
                    let updateCnt = (props.iterateCnt + 1);
                    props.setIterateCnt(updateCnt);
                    props.setParentNode(json['CONDITION' + updateCnt]);
                } else if (props.clickStr == 'YES') {
                    findAndUpdateObj(props, json, props.clickStr);
                } else if (props.clickStr == 'NO') {
                    findAndUpdateObj(props, json, props.clickStr);
                }
            }

            let obj = props.dataObj
            obj.showFields = false;
            setDecisionObject(obj);
            props.setIsUpdateJson(!props.isUpdateJson);
            // props.closeModal(true)
        }
    }

    const [checkedItems, setCheckedItems] = useState([selectedInput]);
    function findAndUpdateObj(obj, json, clickStr) {
        let updated = 0;
        function traverseTree(node, json, id, nodeType) {
            if (node == null) {
                return;
            }
            if (node[Object.keys(node)[0]]?.ID == id) {
                if (nodeType == 'YES') {
                    node[Object.keys(node)[0]]['YES'] = json;
                } else {
                    node[Object.keys(node)[0]]['NO'] = json;
                }
                return 0;
            }
            traverseTree(node[Object.keys(node)[0]]?.YES, json, id, clickStr);
            traverseTree(node[Object.keys(node)[0]]?.NO, json, id, clickStr);
        }
        let lastDotIndex = props.id.lastIndexOf(".");
        let desiredId = props.id.substring(0, lastDotIndex);
        traverseTree(obj.buildJSON, json, desiredId, clickStr);
        return obj;
    }
    useEffect(() => {
        setDecisionData(props.dataObj);
        setSelectedTemplate(templateObj['id' + props.dataObj.id]);
        setInputItems(templateObj['ft' + props.dataObj.id]);
        setIsUpdate(true)
    }, []);

    useEffect(() => {
        setDecisionData(props.dataObj);
        setInputItems(templateObj['ft' + props.dataObj.id]);
        setSelectedTemplate(templateObj['id' + props.dataObj.id]);

        let objData = { ...decisionValue };
        for (let ind in templateData) {
            let regex = /(\d+)/g;
            let intVal = ind.match(regex);
            if (decisionData.id == intVal.join("")) {
                if (objData['dt' + decisionData.id] == undefined) {
                    const filteredObject = Object.keys(templateData[ind]).reduce((acc, key) => {
                        if (key !== 'field_id') {
                            acc[key] = templateData[ind][key];
                        }
                        return acc;
                    }, {});
                    objData['dt' + decisionData.id] = filteredObject
                    for (let key in objData['dt' + decisionData.id]) {
                        objData['dt' + decisionData.id][key] = null;
                    }
                }
            }
        }

        setDecisionValue(objData);
    }, [isUpdate, props])

    const updateInput = (val) => {
        let items = checkedItems;
        items.push(val)
        setCheckedItems(items);
        setSelectedInput(val)
    }
    const mappedElements = Object.entries(templateData['id' + props.dataObj.id]).map(([key, value]) => (
        key != 'field_id' && <a key={key} onClick={() => updateInput(key)} className={checkedItems.includes(key) ? 'item-checked' : ''}>
            <span className={(selectedInput == key) ? 'item-selected' : ''}> {value.split(",")[0]} </span>
        </a>
    ));

    const handleFields = () => {
        let obj = props.dataObj
        obj.showFields = false;
        setDecisionObject(obj);
    }

    return (
        <Row className={props.isOpen ? 'show decition-itemswrapper' : 'hide decition-itemswrapper'} >
            <Col md={9}>
                {/* <Row className='text-left'>
                    <Col md={12}>
                        <span className='cursor-pointer primary-text decision-item-wrapper' style={{ cursor: 'pointer' }}>
                            <div>{mappedElements}</div>
                        </span>
                    </Col>
                </Row> */}
                {templateObj[inputObjId].map((object, index) => (
                    <span key={index}>
                        {Object.entries(object).map(([key, value]) => (
                            <>
                                {(Object.keys(decisionValue).length > 0) && <DecisionInputes
                                    type={value}
                                    key={key}
                                    label={templateData['id' + props.dataObj.id][key]}
                                    decisionValue={decisionValue}
                                    setDecisionValue={setDecisionValue}
                                    index={key}
                                    mainIndex={'dt' + props.dataObj.id}
                                    selectedInput={selectedInput}
                                    openPositions={props.openPositions}
                                    tagNames={props.tagNames}
                                />}
                            </>
                        ))}
                    </span>
                ))}
            </Col>
            <Col md={3} className='text-right'>
                <button className='btn btn-transparent text-dark border small' style={{ marginRight: "10px", padding: "8px 12px" }} onClick={cancelData} >
                    Cancel
                </button>
                <button className='btn pl-0 btn-primary small' onClick={saveData} >
                    Save
                </button>
            </Col>
            <Col md={12}>
                {errorMsg !== "" && <p className='text-danger'>{errorMsg}</p>}
            </Col>
            {/* <Col md={6}>
                <button className='btn pl-0 btn-link' onClick={handleFields} >
                    <FontAwesomeIcon icon={faChevronLeft} className='text-secondary p-0' /> Back
                </button>
            </Col> */}

        </Row>
    );
}

export default DecisionItems;
