import React, { useEffect, useState } from 'react';
import { Nav, Container, Row, Col, Button, Form, Dropdown, Tab, ListGroup, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faChevronLeft, faGreaterThan, faGreaterThanEqual, faLessThan, faLessThanEqual, faList, faPlus } from '@fortawesome/free-solid-svg-icons';
import './Strategy.css';
import DecisionItems from './DecisionItems'
import decisionValues from "../decision.json";
import { useMyContext } from '../../context/MyContext';

function Decision(props) {

    let [selectedObj, setSelectedObj] = useState('');
    let [showFields, setShowFields] = useState(false);
    let [decitionContent, setDecitionContent] = useState(props.decitionContent ? props.decitionContent : decisionValues.decitionItems[0].key);
    const { decisionObject, setDecisionObject } = useMyContext();

    const handleState = (obj) => {
        setSelectedObj(obj);
        // setShowFields(true);
        setDecisionObject({ selectedObj: obj, showFields: true, type: props.type, nodeKey: props.nodeKey, id: props.id })
        props.setExpandedL(false)
    }

    const handleSelect = (key) => {
        // Handle tab selection if needed
        props.setDecitionContent(key)
        setDecitionContent(key);
        // setShowFields(false);
    };

    return (
        <Row>
            <Col md={12}>
                <Tabs defaultActiveKey={decitionContent} onSelect={handleSelect} className='decition-tabs'>
                    {decisionValues.decitionItems.map((tab) => (
                        <Tab key={tab.key} eventKey={tab.key} title={tab.title}>
                            <ListGroup>
                                {(decisionValues.decitionData[tab.key])?.map((item, index) => (
                                    <ListGroup.Item style={{ cursor: 'pointer' }} onClick={() => handleState(item)}>
                                        {item.value}
                                    </ListGroup.Item>
                                ))}

                            </ListGroup>
                        </Tab>
                    ))}
                </Tabs>

                {(showFields && selectedObj != '') && <DecisionItems
                    decitionContent={decitionContent}
                    dataObj={selectedObj}
                    // updateObj={handleObj}
                    isOpen={showFields}
                    clickStr={props.clickStr}
                    type={props.type}
                    hideFields={setShowFields}
                    buildJSON={props.buildJSON}
                    setBuildJSON={props.setBuildJSON}
                    iterateCnt={props.iterateCnt}
                    setIterateCnt={props.setIterateCnt}
                    parentNode={props.parentNode}
                    setParentNode={props.setParentNode}
                    closeModal={props.handleClose}
                    nodeKey={props.nodeKey}
                    conditionCount={props.conditionCount}
                    setConditionCount={props.setConditionCount}
                    id={props.id}
                    openPositions={props.openPositions}
                    tagNames={props.tagNames}
                />}
            </Col >
        </Row >
    );
}

export default Decision;
