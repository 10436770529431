import React, { useState } from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faUser, faCopyright } from '@fortawesome/free-regular-svg-icons';
import logo from '../assets/images/up1ne_logo.png'
function Footer() {
    return (
        <Row bg="light" className='bg-light' style={{
            position: 'fixed',
            bottom: '0px',
            width: '100%',
            zIndex: 999,
        }}>
            <Col md={12} className='pt-3'>
                <p>
                    <span>
                        <FontAwesomeIcon icon={faCopyright} /> {new Date().getFullYear()} Trade Automation. All Rights Reserved.
                    </span>

                </p>
            </Col>
        </Row>
    );
}

export default Footer;
