// StrategyOutcomes
import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
function StrategyOutcomes(props) {

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.strategyName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col style={{ marginRight: "1px solid #EAEAEA" }}>
                        {props.data.split(",")[0]}
                    </Col>
                    <Col>
                        {props.data.split(",")[1]}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default StrategyOutcomes;