import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import decisionValues from "../../openPosition.json";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css'
import { useMyContext } from '../../../context/MyContext';
const UpdateInputs = (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [updateval, setUpdateval] = useState(false);
    const [obj, setObj] = useState([]);
    const { updateObject } = useMyContext();

    useEffect(() => {
        let preObj = []
        updateObject?.inputItems.map((object, index) => {
            Object.entries(object).map(([key, value]) => {
                let data = [];
                const options = decisionValues.fieldData[updateObject.templateData['id' + updateObject.dataId][key].split(",")[1]];
                if (updateObject.templateData['id' + updateObject.dataId][key].split(",")[1] !== "number" && updateObject.templateData['id' + updateObject.dataId][key].split(",")[1] !== "dttm" && updateObject.templateData['id' + updateObject.dataId][key].split(",")[1] !== "time" && updateObject.templateData['id' + updateObject.dataId][key].split(",")[1] !== "text") {
                    data = options.filter(option =>
                        option?.toLowerCase().includes(searchTerm?.toLowerCase())
                    );
                }
                let arrItem = {
                    label: updateObject.templateData["id" + updateObject.dataId][key],
                    value: updateObject?.dataObj[key],
                    data: data,
                    type: value
                }
                preObj.push(arrItem)
            })
        })
        setUpdateval(!updateval)
        setObj(preObj);
    }, [props]);

    const newPosObj = updateObject?.updatedObj;
    const updateInput = (index, val, type) => {
        let preObj = obj;
        if (type == "date") {
            setSelectedDate(val);
            preObj[index].value = moment(val).format("MM-DD-YYYY HH:mm");
        } else if (type == "time") {
            setSelectedTime(val);
            preObj[index].value = moment(val).format("HH:mm");
        } else {
            preObj[index].value = val
        }
        setObj(preObj)
        setUpdateval(!updateval)

        const convertedObject = {};
        preObj.forEach((item, index) => {
            convertedObject[`field${index + 1}`] = item.value;
        });
        newPosObj["POSITION" + updateObject.curNode.match(/\d+/g)] = convertedObject;
        updateObject.setUpdatedObj(newPosObj);
    }

    const cancelData = () => {
        props.setExpandedR(!props.expandedR);
        props.setExpandedL(!props.expandedL);
    }

    const saveData = () => {
        if ((props.clickStr == 'start' || props.clickStr == 'YES' || props.clickStr == 'NO') && props.type == 'exist-position') {
            let count = props.updateCnt + 1;
            props.setUpdateCnt(count);
            let singleObject = { content: "" }
            for (const position in updateObject.updatedObj) {
                const values = updateObject?.updatedObj[position];
                for (const field in values) {
                    const value = values[field];
                    if (value !== null) {
                        singleObject["content"] = singleObject["content"] + " " + value;
                    }
                }
            }
            const convertedObject = convertObject(updateObject?.updatedObj);
            function convertObject(originalObject) {
                const convertedObject = {
                    "UPDATE_POS": {},
                };
                let index = 1;
                for (const position in originalObject) {
                    convertedObject["UPDATE_POS"][`UPD_POS${index}`] = position;
                    convertedObject[`UPD_POS${index}`] = originalObject[position];
                    index++;
                }
                return convertedObject;
            }

            let json = {
                ['UPDATE' + count]: {
                    TMP_ID: 'UPDATE' + count,
                    CONTENT: singleObject,
                    UPDATES: convertedObject,
                    // UPDATES: updatedObj,
                    YES: {},
                    NO: {},
                    // ID: props.clickStr == 'start' ? '1' : (updateObject.dataId)
                    ID: props.clickStr == 'start' ? '1' : (props.id)
                },
                TYPE: "UPDATE",
                KEY: 'UPDATE' + count,
            }
            json = JSON.parse(JSON.stringify(json));
            if (props.clickStr === 'start') {
                props.setBuildJSON(json);
                let updateCnt = (props.iterateCnt + 1);
                props.setIterateCnt(updateCnt);
                props.setParentNode(json['UPDATE' + count]);
            } else if (props.clickStr === 'YES') {
                findAndUpdateObj(props, json, props.clickStr);
                props.setParentNode(json['UPDATE' + count]);
            } else if (props.clickStr === 'NO') {
                findAndUpdateObj(props, json, props.clickStr);
                props.setParentNode(json['UPDATE' + count]);
            }
        }
        // props.handleClose(true)
    }

    function findAndUpdateObj(obj, json, clickStr, action) {
        function traverseTree(node, json, id, nodeType) {
            if (node == null) {
                return;
            }
            if (updateObject.changedNodes.includes(node.KEY)) {
                // if (node.KEY === selectedNodeData.currentPosId) {
                node[Object.keys(node)[0]]["UPDATE"] = "UPDATE" + (parseInt(props.updateCnt) + 1);
                if (node[Object.keys(node)[0]]["UPDATE_NODES"]) {
                    node[Object.keys(node)[0]]["UPDATE_NODES"] = [...node[Object.keys(node)[0]]["UPDATE_NODES"], { [json[json["KEY"]].ID]: "UPDATE" + (parseInt(props.updateCnt) + 1) }];
                } else {
                    node[Object.keys(node)[0]]["UPDATE_NODES"] = [{ [json[json["KEY"]].ID]: "UPDATE" + (parseInt(props.updateCnt) + 1) }];
                }
            }

            if (node[Object.keys(node)[0]]?.ID == id) {
                if (action && Object.keys(node).length > 0) {
                    node[Object.keys(node)[0]]["UPDATE"] = "UPDATE" + (parseInt(props.updateCnt) + 1);
                    props.setBuildJSON(props.buildJSON)
                    props.setIsUpdateJson(!props.isUpdateJson)
                } else {
                    if (nodeType == 'YES') {
                        node[Object.keys(node)[0]]['YES'] = json;
                        props.setBuildJSON(props.buildJSON);
                        props.setIsUpdateJson(!props.isUpdateJson)
                        return obj;
                    } else {
                        node[Object.keys(node)[0]]['NO'] = json;
                        props.setIsUpdateJson(!props.isUpdateJson)
                        return obj;
                    }
                }
                props.setExpandedR(false);
                props.setExpandedL(false);
                return 0;
            }
            traverseTree(node[Object.keys(node)[0]]?.YES, json, id, clickStr);
            traverseTree(node[Object.keys(node)[0]]?.NO, json, id, clickStr);
        }

        let lastDotIndex = props.id.lastIndexOf(".");
        let desiredId = props.id.substring(0, lastDotIndex);
        if (action) traverseTree(obj.buildJSON, json, desiredId, clickStr);
        else traverseTree(obj.buildJSON, json, desiredId, clickStr);
        return obj;
    }


    const handleSelectChange = (index, event) => {
        const { options } = event.target;
        const selectedValues = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedValues.push(options[i].value);
            }
        }
        let preObj = obj;
        preObj[index].value = event.target.value;
        setObj(preObj)
        setUpdateval(!updateval)
    };

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState();

    return (
        <>
            {obj.map((item, index) => (
                <>
                    <Form.Group as={Row} className='mt-2' key={index} >
                        <span>
                            <Form.Label sm={2} className='font-weight-bold' style={{ fontWeight: '600', textTransform: 'capitalize' }}>
                                {item.label.split(",")[0]} </Form.Label>
                            {item.type == 'number' && <Col sm={10}>
                                <Form.Control type="number" value={item.value}
                                    onChange={e => updateInput(index, e.target.value)} placeholder="Enter a number" />
                            </Col>}
                        </span>
                        <span>
                            {item.type == 'search' && <Col sm={10}>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    size="sm"
                                    value={item.value}
                                    onChange={e => setSearchTerm(e.target.value)}
                                    style={{
                                        padding: '3px',
                                        borderRadius: '5px',
                                        border: '1px solid #EAEAEA',
                                        width: '30%', display: 'none'
                                    }}
                                />
                                <select className='form-control' onChange={e => updateInput(index, e.target.value)} value={item.value}>
                                    {item.data.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </Col>}
                        </span>
                        <span>
                            {item.type == 'multiselect' && <Col sm={10}>
                                <Form.Select
                                    multiple
                                    value={item.value}
                                    onChange={e => handleSelectChange(index, e)}
                                >
                                    {item.data.map((option, ind) => (
                                        <option key={ind} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>}
                        </span>
                        <span>
                            {item.type == 'select' && <Col sm={10}>
                                <Form.Select
                                    value={item.value}
                                    onChange={e => updateInput(index, e.target.value)}
                                >
                                    {item.data.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>}
                        </span>
                        <span>
                            {item.type == 'dttm' && <Col sm={10}>
                                <div className='date-time-picker'>
                                    <DatePicker
                                        selected={item.value}
                                        // onChange={handleChange}
                                        showTimeSelect
                                        timeIntervals={5}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        className='form-control date-picker'
                                        onChange={e => updateInput(index, e, "date")}
                                    />
                                </div>
                            </Col>}
                        </span>
                        <span>
                            {item.type == 'time' && <Col sm={10}>
                                <div className='date-time-picker'>
                                    <TimePicker
                                        showHour
                                        showSecond={false}
                                        defaultValue={item.value}
                                        onChange={e => updateInput(index, e, "time")}
                                    />
                                </div>
                            </Col>}
                        </span>
                        <span>
                            {item.type == 'text' && <Col sm={10}>
                                <div>
                                    <input type='text' value={item.value} className='form-control' onChange={(e) => updateInput(index, e.target.value)} />
                                </div>
                            </Col>}
                        </span>
                    </Form.Group>
                </>)
            )}
            <Col md={12} className='text-right mt-3'>
                <button className='btn pl-0 btn-transparent border text-dark' style={{ marginRight: "10px", padding: "8px 13px" }} onClick={cancelData} >
                    Cancel
                </button>
                <button className='btn pl-0 btn-primary' onClick={saveData} >
                    Save
                </button>
            </Col>
        </>
    );
};

export default UpdateInputs;
