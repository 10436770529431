import React, { useEffect, useState } from 'react';
import { Nav, Container, Row, Col, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faChevronLeft, faGreaterThan, faGreaterThanEqual, faLessThan, faLessThanEqual, faList, faPlus } from '@fortawesome/free-solid-svg-icons';
import '../Strategy.css';
import DecisionInputes from './OpenDecisionInputs'
import decisionValues from "../../openPosition.json";
import { useMyContext } from '../../../context/MyContext';

function OpenDecisionItems(props) {
    const { strategyObject, setStrategyObject } = useMyContext();
    const [decisionData, setDecisionData] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);
    let templateData = decisionValues.templateData[strategyObject.decitionContent];
    let templateObj = decisionValues.templateObj;
    const [decisionValue, setDecisionValue] = useState({});
    const [inputObjId, setInputObjId] = useState(templateData['id' + strategyObject.dataObj?.id]['field_id'])
    const [selectedInput, setSelectedInput] = useState(Object.keys(templateData['id' + strategyObject.dataObj.id])[1]);
    const [postionType, setPostionType] = useState("");
    const [errorMsg, setErrorMsg] = useState("");


    const options = {
        OTP: 'OTP',
        IND: 'IND',
        FUT: 'FUT',
        EQT: 'EQT',
    };

    const handleChange = (event) => {
        setPostionType(event.target.value);
    };

    const cancelData = () => {
        props.setExpandedR(!props.expandedR)
    }

    const saveData = () => {
        let isValid = true;
        setErrorMsg("");
        Object.keys(decisionValue[Object.keys(decisionValue)[0]]).map((key) => {
            if (decisionValue[Object.keys(decisionValue)[0]][key] === null) {
                isValid = false;
                setErrorMsg("Fields cannot be blank!")
            }
        })
        if (postionType === "") {
            isValid = false;
            setErrorMsg("Fields cannot be blank!")
        }

        if (isValid) {
            if ((props.clickStr == 'start' || props.clickStr == 'YES' || props.clickStr == 'NO') && props.type == 'open-position') {
                let count = props.entryCnt + 1;
                props.setEntryCnt(count);
                // let currPosArr = [...props.openPositions, count];
                let currPosArr = {
                    ID: props.clickStr == 'start' ? '1' : (props.clickStr == 'YES' ? props.id + '.1' : props.id + '.2'),
                    CLOSE: 0,
                    POS: count,
                    CLOSE_ID: []
                };
                props.setOpenPositions([...props.openPositions, currPosArr]);
                let json = {
                    ['ENTRY' + count]: {
                        TMP_ID: Object.keys(decisionValue)[0],
                        CONTENT: decisionValue[Object.keys(decisionValue)[0]],
                        "ENTRY_POS": ["POSITION" + count],
                        ["POSITION" + count]: decisionValue[Object.keys(decisionValue)[0]],
                        VALUE_ID: strategyObject.dataObj.id + "_" + strategyObject.decitionContent,
                        TYPE: postionType,
                        YES: {},
                        NO: {},
                        ID: props.clickStr == 'start' ? '1' : (props.id)
                        // ID: props.clickStr == 'start' ? '1' : (props.clickStr == 'YES' ? props.id + '.1' : props.id + '.2')
                    },
                    TYPE: "ENTRY",
                    KEY: 'ENTRY' + count,
                }
                if (props.clickStr == 'start') {
                    props.setBuildJSON(json);
                    let updateCnt = (props.iterateCnt + 1);
                    props.setIterateCnt(updateCnt);
                    props.setParentNode(json['ENTRY' + count]);
                } else if (props.clickStr == 'YES') {
                    findAndUpdateObj(props, json, props.clickStr);
                    props.setParentNode(json['ENTRY' + count]);
                } else if (props.clickStr == 'NO') {
                    findAndUpdateObj(props, json, props.clickStr);
                    props.setParentNode(json['ENTRY' + count]);
                }
            }
            props.setExpandedR(false)
            props.setExpandedL(false)
            props.setIsUpdateJson(!props.isUpdateJson);
        }
    }
    const [checkedItems, setCheckedItems] = useState([selectedInput]);
    function findAndUpdateObj(obj, json, clickStr) {
        let updated = 0;
        function traverseTree(node, json, id, nodeType) {
            if (node == null) {
                return;
            }
            if (node[Object.keys(node)[0]]?.ID == id) {
                if (nodeType == 'YES') {
                    node[Object.keys(node)[0]]['YES'] = json;
                } else {
                    node[Object.keys(node)[0]]['NO'] = json;
                }
                return 0;
            }
            traverseTree(node[Object.keys(node)[0]]?.YES, json, id, clickStr);
            traverseTree(node[Object.keys(node)[0]]?.NO, json, id, clickStr);
        }
        let lastDotIndex = strategyObject.id.lastIndexOf(".");
        let desiredId = strategyObject.id.substring(0, lastDotIndex);
        traverseTree(obj.buildJSON, json, desiredId, clickStr);
        return obj;
    }
    useEffect(() => {
        setDecisionData(strategyObject.dataObj);
        setIsUpdate(true)
    }, []);

    useEffect(() => {
        setDecisionData(strategyObject.dataObj);
        let objData = { ...decisionValue };
        for (let ind in templateData) {
            if (decisionData.id == ind.split('')[ind.length - 1]) {
                if (objData['dt' + decisionData.id] == undefined) {
                    const filteredObject = Object.keys(templateData[ind]).reduce((acc, key) => {
                        if (key !== 'field_id') {
                            acc[key] = templateData[ind][key];
                        }
                        return acc;
                    }, {});
                    objData['dt' + decisionData.id] = filteredObject
                    for (let key in objData['dt' + decisionData.id]) {
                        objData['dt' + decisionData.id][key] = null;
                    }
                }
            }
        }

        setDecisionValue(objData);
    }, [isUpdate, props])

    const updateInput = (val) => {
        let items = checkedItems;
        items.push(val)
        setCheckedItems(items);
        setSelectedInput(val)
    }
    const mappedElements = Object.entries(templateData['id' + strategyObject.dataObj.id]).map(([key, value]) => (
        key != 'field_id' && <a key={key} onClick={() => updateInput(key)} className={checkedItems.includes(key) ? 'item-checked' : ''}>
            <span className={(selectedInput == key) ? 'item-selected convert-to-upper' : 'convert-to-upper'}> {value.split(",")[0]} </span>
        </a>
    ));

    return (
        <Row>
            <Col md={12}>
                <Row className='text-left'>
                    <Col md={10} className='mb-4'>
                        <label className='bold'>Type</label>
                        <Form.Select
                            value={postionType}
                            onChange={handleChange}
                        >
                            <option key="" value="">
                                Select a value
                            </option>
                            {Object.keys(options).map((key) => (
                                <option key={key} value={key}>
                                    {key}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                    {/* <Col md={12}>
                        <span className='cursor-pointer primary-text decision-item-wrapper' style={{ cursor: 'pointer' }}>
                            <div>{mappedElements}</div>
                        </span>
                    </Col> */}
                </Row>

                {templateObj[inputObjId].map((object, index) => (
                    <div key={index}>
                        {Object.entries(object).map(([key, value]) => (
                            <>
                                {(Object.keys(decisionValue).length > 0) && <DecisionInputes type={value} key={key} label={templateData['id' + strategyObject.dataObj.id][key]} decisionValue={decisionValue} setDecisionValue={setDecisionValue} index={key} mainIndex={'dt' + strategyObject.dataObj.id} selectedInput={selectedInput} />}
                            </>
                        ))}
                    </div>
                ))}

            </Col>
            <Col md={12}>
                {errorMsg !== "" && <p className='text-danger'>{errorMsg}</p>}
            </Col>
            {/* <Col md={6}>
                <button className='btn pl-0 btn-link' >
                    <FontAwesomeIcon icon={faChevronLeft} className='text-secondary p-0' /> Back
                </button>
            </Col> */}
            <Col md={12} className='text-right mt-3'>
                <button className='btn pl-0 btn-transparent border text-dark' style={{ marginRight: "10px", padding: "8px 13px" }} onClick={cancelData} >
                    Cancel
                </button>
                <button className='btn pl-0 btn-primary' onClick={saveData} >
                    Save
                </button>
            </Col>
        </Row>
    );
}

export default OpenDecisionItems;
