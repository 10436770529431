import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

function Toaster(props) {
    // const [show, setShow] = useState(false);

    return (
        <Row style={{
            position: "absolute",
            top: "0px",
            right: "0px"
        }}>
            <Col xs={12} >
                <Toast onClose={() => props.setIsShow(false)} show={props.isShow} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto  text-warning">Alert</strong>
                    </Toast.Header>
                    <Toast.Body>{props.msg ? props.msg : "Please complete the strategy before saving!"}</Toast.Body>
                </Toast>
            </Col>
        </Row>
    );
}

export default Toaster;