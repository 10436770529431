import React, { useEffect, useState } from "react";
import { Nav, Container, Row, Col, Button, Card, Modal, Table, DropdownButton, Dropdown, Collapse } from "react-bootstrap";
import Header from "../Header";
// import Strategy from "./Strategy";
// import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import { API_URL, ENGINE_URL } from "../../config";
import grid from "../../assets/images/grid.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCirclePlay } from "@fortawesome/free-regular-svg-icons";
import Sidebar from "../Sidebar";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import StrategyOutcomes from "./StrategyOutcomes";
import { faArrowDown, faEllipsis, faList, faMagnifyingGlass, faPlus, faSquarePollVertical, faUpload } from "@fortawesome/free-solid-svg-icons";
import PublishStrategyModal from "../PublishStrategyModal";
import Toaster from "./Toaster/Toast";
function Strategies(props) {
    const [isUpdate, setIsUpdate] = useState(false);
    const [strategies, setStrategies] = useState([]);
    const [subStrategies, setSubStrategies] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [strategyData, setStrategyData] = useState({});
    const [strategyName, setStrategyName] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [currStrategyId, setCurrStrategyId] = useState("");

    const [show, setShow] = useState(false);
    const [showPublish, setShowPublish] = useState(false);
    const [viewType, setViewType] = useState("list");

    const [showModal, setShowModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [resultRow, setResultRow] = useState([]);
    const handleModalClose = () => setShowModal(false);
    const [isShow, setIsShow] = useState(false);


    const handleModalShow = (id, userId) => {
        // /api/strategy/results/<int:user_id>/<int:strategy_id>
        fetch(
            `${API_URL}/strategy/results/${userId}/${id}`,
            {
                "Content-Type": "application/json",
            }
        )
            .then((response) => {
                if (response.ok) {
                    // Parse the response data as JSON
                    return response.json();
                } else {
                    throw new Error(`Request failed with status code ${response.status}`);
                }
            })
            .then((res) => {
                setResultRow(res.data);
                if (res.data.length > 0) {
                    setIsShow(false)
                    setShowModal(true);
                }
                else {
                    setIsShow(true)
                }
            })
            .catch((error) => {
                console.error("Request failed:", error);
            });
    };
    const [expanded, setExpanded] = useState(false);
    const [openRows, setOpenRows] = useState({});
    const toggleRow = (index) => {
        setOpenRows((prevOpenRows) => ({
            ...prevOpenRows,
            [index]: !prevOpenRows[index],
        }));
    };

    const navigator = useNavigate();
    const handleToggle = () => {
        setExpanded(!expanded);
    };

    const handleClose = () => {
        setStrategyData({});
        setStrategyName("");
        setShow(false);
    };

    const handlePublishModal = () => {
        setShowPublish(false);
    };

    const handleShow = (data, name) => {
        setStrategyData(data);
        setStrategyName(name);
        setShow(true);
    };

    const navigate = useNavigate();
    const redirectToPage = (index) => {
        // localStorage.setItem(cur_strategy)
        navigate(`/strategy?strategy=${index}`);
    };

    const LoadRangePicker = ({ data }) => {
        return (
            <DateRangePicker
                initialSettings={{
                    startDate: startDate,
                    endDate: endDate,
                    timePicker: false,
                    timePicker24Hour: true,
                }}
                onApply={(event, picker) => runStrategy(data, event, picker)}
            >
                <button className="btn btn-primary white-bg" style={{ width: "100%" }}>
                    Run Strategy
                </button>
            </DateRangePicker>
        );
    };

    const publishStrategy = (strategy_id) => {
        setShowPublish(true);
        setCurrStrategyId(strategy_id);
    };

    useEffect(() => {
        fetch(
            `${API_URL}/strategies/${JSON.parse(localStorage.getItem("user_details")).email
            }`,
            {
                "Content-Type": "application/json",
            }
        )
            .then((response) => {
                // Check if the request was successful (status code 200)
                setIsLoaded(true);
                if (response.ok) {
                    // Parse the response data as JSON
                    return response.json();
                } else {
                    throw new Error(`Request failed with status code ${response.status}`);
                }
            })
            .then((res) => {
                // Process the data as needed
                setStrategies(res.data?.my_strategies);
                setSubStrategies(res.data?.sub_strategies);
                setIsUpdate(true);
            })
            .catch((error) => {
                console.error("Request failed:", error);
            });
    }, [isUpdate]);

    const runStrategy = (data, event, picker) => {
        let postData = {
            startDate: moment(picker.startDate).format("DD-MM-YYYY"),
            endDate: moment(picker.endDate).format("DD-MM-YYYY"),
            user_id: data.user_id,
        };
        fetch(`${ENGINE_URL}/execute/${data.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
        })
            .then((response) => {
                if (response.ok) {
                    window.location.reload();
                    return response.json();
                } else {
                    throw new Error("Error: " + response.status);
                }
            })
            .catch((error) => {
                console.log("Error:", error);
            });
    };

    const handleActionClick = (event, id, type) => {
        if (type === "view-strategy") {
            navigate(`/strategy?strategy=${id}`)
        }
    };

    const [isPosModalOpen, setIsPosModalOpen] = useState(false);
    const [posContent, setPosContent] = useState("Initial content");

    const updatePosContent = () => {
        setPosContent("Updated content");
    };

    const handleOpenPosModal = (strategy_bt_id, run_date) => {
        //alert('open'+strategy_bt_id+'='+ run_date)

        let posData = {
            strategy_bt_id: strategy_bt_id,
            run_date: run_date,
        };

       fetch(`${API_URL}/strategy/position`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(posData),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Error: " + response.status);
                }
            }).then((data) => {
           
            setPosContent(data.data[0].position);
            setInnerOpen(true);
            })
            .catch((error) => {
                console.log("Error:", error);
            });



    };

    const handleClosePosModal = () => {
        setIsPosModalOpen(false);
    };

    const [isInnerOpen, setInnerOpen] = useState(false);
    const InnerModal = ({ isOpen, onClose }) => {
      if (!isOpen) return null;
      return (
        <div style={{
          position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
          background: 'white', padding: '20px', zIndex: 1001
        }}>
          <p>{posContent}</p>
          <button onClick={onClose}>Close</button>
        </div>
       );
    };


    const DataTable = ({ data }) => {
        const [sortConfig, setSortConfig] = useState(null);

        const requestSort = key => {
            let direction = 'ascending';
            if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };

        const sortedData = () => {
            if (!sortConfig) return data;

            return [...data].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        };

        return (
            <Table bordered hover>
                <thead>
                    <tr className="strategy-table-header">
                        <th onClick={() => requestSort('strategy_name')}>Name</th>
                        <th onClick={() => requestSort('status')}>Info</th>
                        <th onClick={() => requestSort('created')}>Created</th>
                        <th onClick={() => requestSort('live_deployment')}>Live Deployment</th>
                        <th onClick={() => requestSort('tags')}>Tags</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData().map(item => (
                        <tr key={item.id}>
                            <td>{item.strategy_name}</td>
                            <td>
                                <div className="description">
                                    <span
                                        className={
                                            expanded
                                                ? "expanded-view"
                                                : "collapsed-view"
                                        }
                                    >
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit. Nullam at fermentum
                                        dolor, nec tincidunt turpis. Ut in eros
                                        nec risus dictum blandit. Duis rutrum
                                        tincidunt lacinia.{item.description}
                                    </span>
                                    <button
                                        className="toggle-btn"
                                        onClick={handleToggle}
                                    >
                                        {expanded ? "Read less" : "Read more"}
                                    </button>
                                </div></td>
                            <td>{item.id}</td>
                            <td>{item.live_deployment}</td>
                            <td>{!item.subscribed && (
                                <span className="text-muted p_card-label">
                                    Subscribed
                                </span>
                            )}</td>
                            <td>
                                <td>
                                    <DropdownButton className="strategy-table-dropdown" id={`dropdown-${item.id}`} title={<FontAwesomeIcon icon={faEllipsis} style={{
                                        border: "1px solid #ccc",
                                        borderRadius: "100px",
                                        padding: "5px",
                                    }} />}>
                                        {/* <Dropdown.Item onClick={(e) => handleActionClick(e, item.id)} name="edit">Edit</Dropdown.Item> */}
                                        <Dropdown.Item onClick={(e) => handleActionClick(e, item.id, 'view-strategy')} name="view">View</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleModalShow(item.id, item.user_id)} name="Result">Result</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    return (
        <>
            <div
                class="container-fluid m-0 text-center p-0"
                style={{
                    background: "#F8F8F8",
                }}
            >
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            width: props.expanded ? "14%" : "7%",
                        }}
                        className="bg-sidebar-theme"
                    >
                        <Sidebar
                            expanded={props.expanded}
                            setExpanded={props.setExpanded}
                        />
                    </div>
                    <div
                        style={{
                            width: props.expanded ? "85%" : "93%",
                        }}
                    >
                        <Header expanded={props.expanded} setExpanded={props.setExpanded} />
                        <Col md={12} className="text-left">
                            <>
                                <Card className="m-3 p-3">
                                    <Row>
                                        <Col>
                                            <nav
                                                class="navbar navbar-expand"
                                                style={{
                                                    borderBottom: "none",
                                                    height: "60px",
                                                }}
                                            >
                                                <div class="container-fluid">
                                                    <a class="navbar-brand" href="#">
                                                        <div class="input-group">
                                                            <span
                                                                class="input-group-text"
                                                                style={{
                                                                    background: "#F8F8F8",
                                                                    border: "none",
                                                                    height: "44px",
                                                                }}
                                                                id="basic-addon1"
                                                            >
                                                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                            </span>
                                                            <input
                                                                type="text"
                                                                style={{
                                                                    border: "none",
                                                                    background: "#F8F8F8",
                                                                }}

                                                                class="form-control"
                                                                placeholder="Search"
                                                                aria-label="Username"
                                                                aria-describedby="basic-addon1"
                                                            />
                                                        </div>
                                                    </a>
                                                    <div
                                                        class="collapse navbar-collapse"
                                                        id="navbarNavDarkDropdown"
                                                        style={{ flexDirection: "row-reverse" }}
                                                    >
                                                        <ul class="navbar-nav">
                                                            <li
                                                                class="nav-item dropdown text-left"
                                                                style={{
                                                                    minWidth: "200px",
                                                                }}
                                                            >

                                                            </li>
                                                            <li class="nav-item dropdown text-right">
                                                                <button
                                                                    class={viewType === "list" ? "btn view-type activeType" : "view-type"}
                                                                    style={{
                                                                        border: "1px solid #ccc",
                                                                        padding: "8px 15px 10px 15px",
                                                                        marginRight: "5px",
                                                                        borderRadius: "8px"
                                                                    }}
                                                                    onClick={() => setViewType("list")}
                                                                >
                                                                    <FontAwesomeIcon icon={faList} />
                                                                </button>
                                                                <button
                                                                    class={viewType === "grid" ? "btn view-type activeType" : "view-type"}
                                                                    style={{
                                                                        border: "1px solid #ccc",
                                                                        padding: "8px 15px 10px 15px",
                                                                        borderRadius: "8px"
                                                                    }}
                                                                    onClick={() => setViewType("grid")}
                                                                >
                                                                    <img src={grid} width={"15px"} />
                                                                </button>
                                                            </li>
                                                            <li class="nav-item text-right">
                                                                <button class="btn btn-primary" onClick={() => navigator("/strategy")} >
                                                                    <FontAwesomeIcon icon={faPlus} /> New Strategy
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </nav>
                                        </Col>
                                    </Row>
                                    <h5 className="font-700 mt-3 mb-2">Created By Me</h5>
                                    <Row>
                                        {strategies.length > 0 && viewType === "grid" &&
                                            strategies.map((item, index) => (
                                                <>
                                                    <Col sm={4} xs={12}>
                                                        <Card className="profitable-strategies-wrapper">
                                                            <div className="profitable-strategies text-left">
                                                                <h6 className="p-0">
                                                                    {!item.subscribed && (
                                                                        <span className="text-muted p_card-label">
                                                                            Subscribed
                                                                        </span>
                                                                    )}
                                                                    <span style={{
                                                                        cursor: "pointer",
                                                                        float: "right",
                                                                    }}
                                                                        onClick={() => handleModalShow(item.id, item.user_id)} >
                                                                        <FontAwesomeIcon icon={faSquarePollVertical} style={{
                                                                            fontSize: "20px",
                                                                            color: "#4dd5b8"
                                                                        }} />
                                                                    </span>
                                                                </h6>
                                                                <h5 className="pt-2 cursor-pointer" onClick={() => navigate(`/strategy?strategy=${item.id}`)} >{item.strategy_name}</h5>
                                                                <p className="pt-2 ">
                                                                    <div className="description">
                                                                        <span
                                                                            className={
                                                                                expanded
                                                                                    ? "expanded-view"
                                                                                    : "collapsed-view"
                                                                            }
                                                                        >
                                                                            Lorem ipsum dolor sit amet, consectetur
                                                                            adipiscing elit. Nullam at fermentum
                                                                            dolor, nec tincidunt turpis. Ut in eros
                                                                            nec risus dictum blandit. Duis rutrum
                                                                            tincidunt lacinia.{item.description}
                                                                        </span>
                                                                        <button
                                                                            className="toggle-btn"
                                                                            onClick={handleToggle}
                                                                        >
                                                                            {expanded ? "Read less" : "Read more"}
                                                                        </button>
                                                                    </div>
                                                                </p>
                                                                <Row className="pb-3">
                                                                    <Col md={6}>
                                                                        <div
                                                                            style={{
                                                                                background: "#4DD5B833",
                                                                                borderRadius: "100px",
                                                                                padding: "3px 10px",
                                                                            }}
                                                                        >
                                                                            <span className="bold">
                                                                                Created: {item.created}10 Months ago
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div
                                                                            style={{
                                                                                background: "#FFC8331A",
                                                                                borderRadius: "100px",
                                                                                padding: "3px 10px",
                                                                                color: "#FFBA00",
                                                                            }}
                                                                        >
                                                                            <span className="bold">
                                                                                Live development: {item.development}10
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <div>
                                                                    <LoadRangePicker data={item} />
                                                                    {/* {<Button variant="contained" style={{
                                                                    width: "100%"
                                                                }} className='btn btn-primary'>
                                                                    Run Strategy
                                                                </Button>} */}
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                </>
                                            ))}

                                        {strategies.length > 0 && viewType === "list" &&
                                            <DataTable data={strategies} />
                                        }
                                    </Row>

                                    <hr className="h-line" />
                                    <Row>
                                        <h5 className="font-700 mb-2 mt-3">From Marketplace</h5>
                                        <Row>
                                            {subStrategies.length > 0 && viewType === "grid" &&
                                                subStrategies.map((item, index) => (
                                                    <>
                                                        <Col sm={4} xs={12}>
                                                            <Card className="profitable-strategies-wrapper">
                                                                <div className="profitable-strategies text-left">
                                                                    <h6 className="p-0">
                                                                        {!item.subscribed && (
                                                                            <span className="text-muted p_card-label">
                                                                                Subscribed
                                                                            </span>
                                                                        )}
                                                                    </h6>
                                                                    <h5 className="pt-2">{item.strategy_name}</h5>
                                                                    <p className="pt-2 ">
                                                                        <div className="description">
                                                                            <span
                                                                                className={
                                                                                    expanded
                                                                                        ? "expanded-view"
                                                                                        : "collapsed-view"
                                                                                }
                                                                            >
                                                                                Lorem ipsum dolor sit amet, consectetur
                                                                                adipiscing elit. Nullam at fermentum
                                                                                dolor, nec tincidunt turpis. Ut in eros
                                                                                nec risus dictum blandit. Duis rutrum
                                                                                tincidunt lacinia.{item.description}
                                                                            </span>
                                                                            <button
                                                                                className="toggle-btn"
                                                                                onClick={handleToggle}
                                                                            >
                                                                                {expanded ? "Read less" : "Read more"}
                                                                            </button>
                                                                        </div>
                                                                    </p>
                                                                    <Row className="pb-3">
                                                                        <Col md={6}>
                                                                            <div
                                                                                style={{
                                                                                    background: "#4DD5B833",
                                                                                    borderRadius: "100px",
                                                                                    padding: "3px 10px",
                                                                                }}
                                                                            >
                                                                                <span className="bold">
                                                                                    Created: {item.created}10 Months ago
                                                                                </span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div
                                                                                style={{
                                                                                    background: "#FFC8331A",
                                                                                    borderRadius: "100px",
                                                                                    padding: "3px 10px",
                                                                                    color: "#FFBA00",
                                                                                }}
                                                                            >
                                                                                <span className="bold">
                                                                                    Live development: {item.development}10
                                                                                </span>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <div>
                                                                        <LoadRangePicker data={item} />
                                                                        {/* {<Button variant="contained" style={{
                                                                    width: "100%"
                                                                }} className='btn btn-primary'>
                                                                    Run Strategy
                                                                </Button>} */}
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    </>
                                                ))}

                                            {subStrategies.length > 0 && viewType === "list" &&
                                                <DataTable data={subStrategies} />
                                            }

                                        </Row>
                                    </Row>
                                </Card>
                            </>
                        </Col>
                    </div>
                </div>
                {Object.keys(strategyData).length !== 0 && (
                    <StrategyOutcomes
                        show={show}
                        setShow={setShow}
                        handleClose={handleClose}
                        data={strategyData}
                        strategyName={strategyName}
                    />
                )}
                {showPublish && (
                    <PublishStrategyModal
                        type="publish"
                        id={currStrategyId}
                        showPublish={showPublish}
                        setShowPublish={setShowPublish}
                        setShow={setShow}
                        handlePublishModal={handlePublishModal}
                    />
                )}

                {strategies.length === 0 && isLoaded && (
                    <div className="text-center bg-white" style={{ color: "#EAEAEA" }}>
                        <h3>
                            No strategies found!
                            <div className="text-center">
                                <Button
                                    variant="primary btn btn-primary mt-3"
                                    onClick={() => navigate("/strategy")}
                                >
                                    Create New
                                </Button>
                            </div>
                        </h3>
                    </div>
                )}

                <Modal show={showModal} onHide={handleModalClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Results</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card>
                            <Card.Body>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                    <th>
                                    <InnerModal isOpen={isInnerOpen} onClose={() => setInnerOpen(false)} />
                                    </th>
                                    </tr>
                                        <tr>
                                            <th>#</th>
                                            <th>Run Date</th>
                                            <th>Strategy Status</th>
                                            <th>Overall PNL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resultRow.map((row, index) => (
                                            <React.Fragment key={row.id}>
                                                <tr onClick={() => toggleRow(index)} style={{ cursor: 'pointer' }}>
                                                    <td><FontAwesomeIcon icon={faArrowDown} /></td>
                                                    <td>{row.run_timestamp}</td>
                                                    <td>{row.strategy_status}</td>
                                                    <td>{row.overall_pnl}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4">
                                                        <Collapse in={openRows[index]}>
                                                            <div>
                                                                {row.day_recs.map((rowData, idx) => (
                                                                    <div
                                                                        key={idx} // Add a unique key for each element when using map
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between',
                                                                            padding: '10px',
                                                                            borderBottom: '1px solid #ddd',
                                                                        }}
                                                                    >
                                                                        <div style={{ flex: 1 }}>

                                                                        </div>
                                                                        <div style={{ flex: 1 }}>
                                                                            {rowData?.run_date}
                                                                        </div>
                                                                        <div style={{ flex: 1 }}>
                                                                            {rowData?.day_status}
                                                                        </div>
                                                                        <div style={{ flex: 1 }}>
                                                                            {rowData?.day_pnl}
                                                                        </div>
                                                                        <div style={{ flex: 1 }}>
                                                                            <a href="#" onClick={(e) => { e.preventDefault(); handleOpenPosModal(rowData?.strategy_bt_id, rowData?.run_date); }}>
                                                                                View Position
                                                                            </a>

                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Collapse>
                                                    </td>

                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Toaster setIsShow={setIsShow} isShow={isShow} msg="No result found!" />
            </div>
        </>
    );
}

export default Strategies;
