import React, { useState } from "react";
import { Button, Dropdown, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { Handle, Position } from "reactflow";
import decisionImg1 from '../../../assets/images/decision1.png';
import openPosition from '../../../assets/images/add.png';
import closePosition from '../../../assets/images/close.png';
import notification from '../../../assets/images/notification.png';
import updatePosition from '../../../assets/images/update.png';
import tags from '../../../assets/images/tags.png';
import repeat from '../../../assets/images/repeat.png'
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ColorSelectorNode = ((data) => {
  const [selectedOption, setSelectedOption] = useState("");
  const handleDropdownSelect = (eventKey, str, json) => {
    data.setExpandedL(true);
    let data_id = data.id;
    if (data.data.type.replace(/\d/g, '') != "start") {
      // let ids = data.id.split(".").splice(data.id.split(".").length - 1, 1);
      const lastDotIndex = data.id.lastIndexOf('.');
      if (lastDotIndex !== -1 && lastDotIndex !== 0) {
        // Remove the last element with a dot
        data_id = data.id.substring(0, lastDotIndex);
      }
    }
    getParentObject(data_id)
    data.setJsonKey(data_id);
    data.setSelectedItem(eventKey);
    data.setClickStr(str);
    console.log(data, eventKey, str, json)
    data.setId(data.id)
  };

  const getParentObject = (id) => {
    function traverseTree(node) {
      if (node == null) {
        return '1'
      }

      let incomingId = node[Object?.keys(node)[0]]?.ID;
      if (incomingId == id) {
        data.setParentNode(node[Object?.keys(node)[0]])
        return node[Object?.keys(node)[0]];
      }
      else {
        traverseTree(node[Object.keys(node)[0]]?.YES);
        if (node[Object.keys(node)[0]]?.NO != undefined && Object.keys(node[Object.keys(node)[0]]?.NO).length > 0) {
          traverseTree(node[Object.keys(node)[0]]?.NO);
        }
      }
    }
    traverseTree(data.dataObj, id);
  }

  const handleStartSelect = (eventKey) => {
    data.setSelectedItem(eventKey);
    // data.handleOpenModal();
    data.setClickStr("start");
    data.setExpandedL(true);
  };


  const TextWithEllipsis = ({ text, maxLength }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    const truncatedText = isExpanded ? text : text.slice(0, maxLength);

    return (
      <div>
        <span>{truncatedText}</span>
        {!isExpanded && text.length > maxLength && (
          <span onClick={toggleExpand} style={{ cursor: 'pointer', color: 'blue' }}>...</span>
        )}
      </div>
    );
  };

  return (
    <>
      {data.data.type.replace(/\d/g, '') != "start" && <Handle
        type="target"
        position={Position.Top}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable="true"
        onClick={() => console.log("cliked!!")}
      />}
      {data.data.type.replace(/\d/g, '') != "start" && <div style={{ border: "1px solid lightblue", padding: "15px", borderRadius: "15px", minWidth: "100px", background: "#fff" }} className={data.data.category != "sub" ? " w-100" : "w-circle"}>
        {<span style={{
          position: "absolute",
          left: "40%",
          // right: "auto",
          top: "-17px",
          border: "none",
          padding: "1px",
          background: "#fff",
          borderRadius: "100%",
        }}>
          {data.data.type.replace(/\d/g, '') == "CONDITION" && data.data.category != "sub" && <img src={decisionImg1} />}
          {data.data.type.replace(/\d/g, '') == "ENTRY" && data.data.category != "sub" && <img src={openPosition} />}
          {data.data.type.replace(/\d/g, '') == "EXIT" && data.data.category != "sub" && <img src={closePosition} />}
          {data.data.type.replace(/\d/g, '') == "UPDATE" && data.data.category != "sub" && <img width={25} src={updatePosition} />}
          {data.data.type.replace(/\d/g, '') == "REPEAT" && data.data.category != "sub" && <img width={25} src={repeat} style={{ background: "#fff", textAlign: "center" }} />}
          {data.data.type.replace(/\d/g, '') == "END" && data.data.category != "sub" && <FontAwesomeIcon style={{ height: "22px", paddingTop: "5px" }} className="text-danger" icon={faPowerOff} />}
          {data.data.type.replace(/\d/g, '') == "TAGS" && data.data.category != "sub" && <img width={25} src={tags} style={{ background: "#fff", textAlign: "center" }} />}
        </span>}
        {data.data.type.replace(/\d/g, '') == "REPEAT" && data.data.category != "sub" && <span>Repeat </span>}
        <TextWithEllipsis text={data.data?.label} maxLength={20} />
      </div>}
      {data.data.type.replace(/\d/g, '') == "start" && <div style={{ border: "none", padding: "15px", minWidth: "100px" }}>
      </div>}
      {data.data.type.replace(/\d/g, '') != "REPEAT" && data.data.category != "sub" && <ToggleButtonGroup
        type="radio"
        name="options"
      // value={selectedOption}
      >
        <Handle
          type="source"
          position="bottom"
          // className={data.data.type.replace(/\d/g, '') === "start" ? "" : "hide"}
          id="a"
          style={{
            background: "trasparent",
            width: "5px",
            height: "5px",
            borderRadius: "3px",
            padding: "1px",
            top: "-15px",
            cursor: "pointer",
            // left: "-53px",
            border: "none"
          }}
          isConnectable="true"
        >
          <ToggleButton
            variant={selectedOption === 'yes' ? 'outline-primary' : 'outline-secondary'}
            value='yes'
            className={data.data.type.replace(/\d/g, '') === "start" ? '' : (selectedOption === 'no') ? 'active hide' : 'hide'}
            style={{ border: "none" }}
          // onClick={() => handleFlowchart('YES')}
          >
            {/* onSelect={(e) => handleStrategySelect(e, 'YES', props.data)} */}
            <Dropdown className='btn-sm- hide' onSelect={(e) => data.data.type.replace(/\d/g, '') == "start" ? handleStartSelect(e) : handleDropdownSelect(e, 'YES', data.buildJson)}>
              {(!data.isYes || data.data.type.replace(/\d/g, '') == "start") && <Dropdown.Toggle id="dropdown-basic" style={{
                position: "absolute",
                // left: "-53px",
                left: (data.data.type.replace(/\d/g, '') === "start") ? "-42px" : (data.data.type.replace(/\d/g, '') != "CONDITION") ? "-32px" : "-53px",
                top: "-28px",
                backgroundColor: "lightblue !important",
                border: "1px solid blue",
              }}>
                {data.data.type.replace(/\d/g, '') == "start" ? "Start" : "Y"}
              </Dropdown.Toggle>}
              <Dropdown.Menu className="border-0 shadow-none">
                <Dropdown.Item href="#" eventKey="decision">
                  {<img src={decisionImg1} width='18' />} Decision
                </Dropdown.Item>
                <Dropdown.Item href="#" eventKey="open-position">
                  <img src={openPosition} width='18' /> Open Position
                </Dropdown.Item>
                <Dropdown.Item href="#" eventKey="close-position">
                  <img src={closePosition} width='18' /> Close Position
                </Dropdown.Item>
                <Dropdown.Item href="#" eventKey="exist-position">
                  <img src={updatePosition} width='18' /> Update Exist Positions
                </Dropdown.Item>
                <Dropdown.Item href="#" eventKey="notification">
                  <img src={notification} width='18' /> Notification
                </Dropdown.Item >
                <Dropdown.Item href="#" eventKey="tags">
                  <img src={tags} width='18' /> Tags
                </Dropdown.Item>
                <Dropdown.Item href="#" eventKey="repeat">
                  <img src={repeat} width='18' /> Repeat
                </Dropdown.Item>
                <Dropdown.Item href="#" eventKey="end">
                  <FontAwesomeIcon className="text-danger" icon={faPowerOff} /> &nbsp;End
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button className="start-btn" style={{
              position: "absolute",
              top: "-25px",
              left: "-25px",
            }} onClick={data.data.type.replace(/\d/g, '') == "start" ? () => handleStartSelect("decision") : () => handleDropdownSelect("decision", 'YES', data.buildJson)} >{data.data.type.replace(/\d/g, '') == "start" ? <span style={{ background: '#FFC833', padding: "8px 16px 8px 16px", borderRadius: "8px" }}>Start</span> : "Y"}</Button>
          </ToggleButton>
        </Handle>
        {data.data.type.replace(/\d/g, '') == "CONDITION" && <Handle
          type="source"
          position="bottom"
          id="b"
          // className="hide"
          style={{
            background: "trasparent",
            width: "5px",
            height: "5px",
            borderRadius: "3px",
            padding: "1px",
            top: "-15px",
            cursor: "pointer",
            // left: "-5px",
            border: "none",
            padding: "0px"
          }}
          isConnectable="true"
          onClick={() => console.log("cliked here11!!!")}
        >
          <ToggleButton
            variant={selectedOption === 'yes' ? 'outline-primary' : 'outline-secondary'}
            value='yes'
            className={selectedOption === 'no' ? 'active hide' : 'hide'}
            style={{ border: "none" }}
          // onClick={() => handleFlowchart('YES')}
          >
            {/* onSelect={(e) => handleStrategySelect(e, 'YES', props.data)} */}
            <Dropdown className='btn-sm' onSelect={(e) => handleDropdownSelect(e, 'NO', data.buildJson)}>
              {!data.isNo && <Dropdown.Toggle variant="primary" style={{
                position: "absolute",
                padding: "0px",
                position: "absolute",
                left: "-10px",
                top: "-28px"
              }} id="dropdown-basic">
                N
              </Dropdown.Toggle>}
              <Dropdown.Menu className="border-0 shadow-none">
                <Dropdown.Item href="#" eventKey="decision">
                  {<img src={decisionImg1} width='18' />} Decision
                </Dropdown.Item>
                <Dropdown.Item href="#" eventKey="open-position">
                  <img src={openPosition} width='18' /> Open Position
                </Dropdown.Item>
                <Dropdown.Item href="#" eventKey="close-position">
                  <img src={closePosition} width='18' /> Close Position
                </Dropdown.Item>
                <Dropdown.Item href="#" eventKey="exist-position">
                  <img src={updatePosition} width='18' /> Update Exist Positions
                </Dropdown.Item>
                <Dropdown.Item href="#" eventKey="notification">
                  <img src={notification} width='18' /> Notification
                </Dropdown.Item >
                <Dropdown.Item href="#" eventKey="tags">
                  <img src={tags} width='18' /> Tags
                </Dropdown.Item>
                <Dropdown.Item href="#" eventKey="repeat">
                  <img src={repeat} width='18' /> Repeat
                </Dropdown.Item>
                <Dropdown.Item href="#" eventKey="end">
                  <FontAwesomeIcon className="text-danger" icon={faPowerOff} /> &nbsp;End
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* <Button onClick={handleDropdownSelect("decision", 'YES', data.buildJson)} >N</Button> */}
          </ToggleButton>
        </Handle>}
      </ToggleButtonGroup >}


      {/* Y/N button */}
      {data.data.category === "sub" && data.data.type.replace(/\d/g, '') != "REPEAT" && data.data.type.replace(/\d/g, '') != "END" && <ToggleButtonGroup
        type="radio"
        name="options"
      // value={selectedOption}
      >
        <Handle
          type="source"
          position="bottom"
          id="a"
          style={{
            background: "trasparent",
            width: "0px",
            height: "0px",
            borderRadius: "100px !important",
            padding: "1px",
            top: "-15px",
            cursor: "pointer",
            border: "none",
            background: "transparent"
          }}
          isConnectable="true"
        >
          {data.data.label == 'Y' && <ToggleButton
            variant={selectedOption === 'yes' ? 'outline-primary' : 'outline-secondary'}
            value='yes'
            // style={{ border: "none" }}
            style={{
              position: "absolute",
              left: "-15px",
              top: "-40px",
              background: "#fff",
              border: "none",
              borderRadius: "100px",
              width: "35px",
              height: "35px",
              padding: "0px"
            }}
          // onClick={() => handleFlowchart('YES')}
          >
            {/* onSelect={(e) => handleStrategySelect(e, 'YES', props.data)} */}
            <Button onClick={(e) => data.data.type.replace(/\d/g, '') == "start" ? handleStartSelect(e) : handleDropdownSelect(e, 'YES', data.buildJson)} className="btn-link" style={{
              backgroundColor: "#fff"
            }}
              variant={selectedOption === 'yes' ? 'outline-primary' : 'outline-secondary'}
            ><span className="btn-link">
                {data.data.type.replace(/\d/g, '') == "start" ? "Start" : "Y"}
              </span></Button>
            {/* <Button onClick={handleDropdownSelect("decision", 'YES', data.buildJson)} >Y</Button> */}
          </ToggleButton>}
        </Handle>
        {data.data.label === "N" && <Handle
          type="source"
          position="bottom"
          id="b"
          // className="hide"
          style={{
            background: "trasparent",
            width: "0px",
            height: "0px",
            borderRadius: "3px",
            padding: "1px",
            top: "-15px",
            cursor: "pointer",
            // left: "-5px",
            border: "none",
            background: "transparent"
          }}
          isConnectable="true"
          onClick={() => console.log("cliked here11!!!")}
        >
          <ToggleButton
            variant={selectedOption === 'yes' ? 'outline-primary' : 'outline-secondary'}
            value='yes'
            style={{
              position: "absolute",
              left: "-15px",
              top: "-40px",
              background: "#fff",
              border: "none",
              borderRadius: "100px",
              width: "35px",
              height: "35px",
              padding: "0px"
            }}
          >
            {/* onSelect={(e) => handleStrategySelect(e, 'YES', props.data)} */}

            <Button onClick={(e) => handleDropdownSelect(e, 'NO', data.buildJson)} className="btn-link" style={{
              backgroundColor: "#fff"
            }}
              variant={selectedOption === 'yes' ? 'outline-primary' : 'outline-secondary'}
            ><span className="btn-link">
                {data?.data.label ? data?.data.label : "N"}
              </span></Button>
          </ToggleButton>
        </Handle>}
      </ToggleButtonGroup >}
    </>
  );
});

export default ColorSelectorNode;