import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import logo from '../assets/images/FlowTickr_Logo.png'
import trimmed_logo from '../assets/images/trimmed_logo.png'
import home from '../assets/images/home.svg'
import strategy from '../assets/images/strategies.svg'
import contact from '../assets/images/contact.svg'
import help from '../assets/images/help.svg'
import marketplace from '../assets/images/marletplace.svg'
// import { GoogleLogout } from 'react-google-login';
import { CLIENT_ID } from '../config';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';


function Sidebar(props) {

    // const { signOut } = useGoogleOneTapLogin();

    const navigate = useNavigate();
    // console.log("useGoogleOAuth", auth2)


    const handleLogout = () => {
        window.google.accounts.id.disableAutoSelect();
        localStorage.setItem("login", false);
        localStorage.setItem("user_details", JSON.stringify({}));
        navigate("/")
        window.location.reload()
    };

    const navigateStrategy = () => {
        navigate("/strategy")
    }

    return (
        <div style={{ textAlign: "center" }} >
            <div className='text-left mt-2'>
                {props.expanded && <a href='/' style={{ margin: "20px" }}>
                    <img src={logo} width={'110'} />
                </a>}
                {!props.expanded && <a href='/' style={{ margin: "20px" }}>
                    <img src={trimmed_logo} width={'25'} style={{
                        margin: "12px 0px 0px 6px"
                    }} />
                </a>}
            </div>
            <Nav
                className={props.expanded ? " flex-column text-left" : 'flex-column text-center'} style={{
                    padding: "25px 0px",
                    width: "100%"
                }}>
                <Nav.Link className={window.location.pathname === '/' ? "sidebar-link active" : "sidebar-link"} href="/" ><img width={"15px"} src={home} /> {props.expanded && <span className='sidebar-title'>Home</span>}</Nav.Link>
                <Nav.Link className="sidebar-link" href="/strategies" ><img width={"15px"} src={strategy} /> {props.expanded && <span className='sidebar-title'> Strategies</span>}</Nav.Link>
                <Nav.Link className="sidebar-link" href="/marketplace" ><img width={"15px"} src={marketplace} /> {props.expanded && <span className='sidebar-title'> Market Place</span>}</Nav.Link>
                <Nav.Link className="sidebar-link" href="/contact"><img width={"15px"} src={contact} /> {props.expanded && <span className='sidebar-title'> Contact</span>}</Nav.Link>
                <Nav.Link className="sidebar-link" href="/help"><img width={"15px"} src={help} /> {props.expanded && <span className='sidebar-title'> Help</span>}</Nav.Link>
                <Nav.Link className="sidebar-link-" href="#">
                    <button className='p-0 btn btn-new-strategy' onClick={navigateStrategy}>
                        <span className='text-primary'><span className='sidebar-title'>
                            <span className='icon-plus' style={props.expanded ? { marginRight: "10px" } : {}}> <FontAwesomeIcon icon={faPlus} />
                            </span>
                            {props.expanded && <span className='text-dark' style={{
                                paddingRight: "15px",
                                fontWeight: "600"
                            }}>New Strategy</span>}</span>
                        </span></button>
                </Nav.Link>
            </Nav>
        </div >
    );
}

export default Sidebar;
