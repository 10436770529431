import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../Strategy.css';
import decisionValues from "../../openPosition.json";

function ClosePosition(props) {

    const cancelData = () => {
        props.setExpandedR(!props.expandedR);
        props.setExpandedL(!props.expandedL);
    }

    const saveData = () => {
        if ((props.clickStr === 'start' || props.clickStr === 'YES' || props.clickStr === 'NO') && props.type === 'close-position') {
            let count = props.closeCnt + 1;
            props.setCloseCnt(count);
            // remove the position of closed and update the state
            let updatePositions = props.openPositions;
            let curr_pos = checkboxes.filter(item => item.checked).map(item => item.id);
            updatePositions.map((item) => {
                if (curr_pos.includes(item.POS)) {
                    item.CLOSE = 1;
                    item.CLOSE_ID = [...item.CLOSE_ID, props.clickStr === 'start' ? '1' : (props.clickStr === 'YES' ? props.id + '.1' : props.id + '.2')];
                    // updatePositions = (updatePositions.filter(val => item !== val));
                }
            });
            props.setOpenPositions(updatePositions);
            let json = {
                ['EXIT' + count]: {
                    TMP_ID: 'EXIT' + count,
                    CONTENT: curr_pos.map(ele => "Position " + ele),
                    EXIT_POS: curr_pos.map(ele => "POSITION" + ele),
                    YES: {},
                    NO: {},
                    ID: props.clickStr == 'start' ? '1' : props.id
                },
                TYPE: "EXIT",
                KEY: 'EXIT' + count,
            }
            if (props.clickStr === 'start') {
                props.setBuildJSON(json);
                let updateCnt = (props.iterateCnt + 1);
                props.setIterateCnt(updateCnt);
                props.setParentNode(json['EXIT' + count]);
            } else if (props.clickStr === 'YES') {
                findAndUpdateObj(props, json, props.clickStr);
            } else if (props.clickStr === 'NO') {
                findAndUpdateObj(props, json, props.clickStr);
            }
        }
        props.setIsUpdateJson(!props.isUpdateJson);
        props.setExpandedR(false)
        props.setExpandedL(false)
        // props.handleClose(true)
    }
    function findAndUpdateObj(obj, json, clickStr) {
        function traverseTree(node, json, id, nodeType) {
            if (node === null || Object.keys(node).length === 0) {
                return;
            }
            if (node[Object.keys(node)[0]]?.ID === id) {
                if (nodeType === 'YES') {
                    node[Object.keys(node)[0]]['YES'] = json;
                } else {
                    node[Object.keys(node)[0]]['NO'] = json;
                }
                return 0;
            }
            traverseTree(node[Object.keys(node)[0]]?.YES, json, id, clickStr);
            traverseTree(node[Object.keys(node)[0]]?.NO, json, id, clickStr);
        }
        let lastDotIndex = props.id.lastIndexOf(".");
        let desiredId = props.id.substring(0, lastDotIndex);
        traverseTree(obj.buildJSON, json, desiredId, clickStr);
        return obj;
    }

    const [checkboxes, setCheckboxes] = useState([]);
    useEffect(() => {
        let checkboxData = []
        props.openPositions.forEach(item => {
            let compareId = props.clickStr === 'start' ? '1' : (props.clickStr === 'YES' ? props.id + '.1' : props.id + '.2')
            let preCloseId = '';
            item.CLOSE_ID?.map((id) => {
                if (compareId.startsWith(id)) {
                    preCloseId = id;
                }
            })

            if (item.CLOSE === 0 && compareId.startsWith(item.ID)) {
                checkboxData.push({
                    id: item.POS,
                    label: `Position ${item.POS}`,
                    checked: false,
                })
                // Update values when CLOSE is 0
            } else if (((item.CLOSE === 1 && !compareId.startsWith(preCloseId)) || (item.CLOSE === 1 && preCloseId === "")) && compareId.startsWith(item.ID)) {
                checkboxData.push({
                    id: item.POS,
                    label: `Position ${item.POS}`,
                    checked: false,
                })
            }
        });
        setCheckboxes(checkboxData)
    }, [])

    const handleCheckboxChange = (index) => {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[index] = {
            ...updatedCheckboxes[index],
            checked: !updatedCheckboxes[index].checked,
        };
        setCheckboxes(updatedCheckboxes);
    };
    return (
        <>
            {checkboxes.length > 0 && <Row>
                <Col md={12}>
                    <Row className='text-left'>
                        <Col md={12}>
                            <h6>Choose Open Positions</h6>
                        </Col>
                    </Row>
                    <div>
                        {checkboxes.map((checkbox, index) => (
                            <div key={checkbox.id}>
                                <input
                                    type="checkbox"
                                    id={`checkbox-${checkbox.id}`}
                                    checked={checkbox.checked}
                                    onChange={() => handleCheckboxChange(index)}
                                />
                                <label htmlFor={`checkbox-${checkbox.id}`}>&nbsp;{checkbox.label}</label>
                            </div>
                        ))}
                    </div>
                </Col>
                <Col md={12} className='text-right mt-3'>
                    <button className='btn pl-0 btn-transparent border text-dark' style={{ marginRight: "10px", padding: "8px 13px" }} onClick={cancelData} >
                        Cancel
                    </button>
                    <button className='btn pl-0 btn-primary' onClick={saveData} >
                        Save
                    </button>
                </Col>
            </Row>}
        </>
    );
}

export default ClosePosition;
