import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import decisionValues from "../../decision.json";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css'
const DecisionInputes = (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    // const [props.mainIndex, setprops.mainIndex] = useState('');
    const options = decisionValues.fieldData[props.label.split(",")[1]];

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const apiUrl = 'https://0fajarpurnama0.github.io/assets/json/quote.json';
                const apiUrl = 'https://FLOWTICKR.s3.ap-south-1.amazonaws.com/strategy_jsons/openPosition.json';
                const response = await fetch(apiUrl);
                console.log(response)
                const jsonData = await response.json();
                console.log(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // fetchData();
    }, []);

    let filteredOptions = [];

    filteredOptions = (props.label.split(",")[1] !== "number" && props.label.split(",")[1] !== "dttm" && props.label.split(",")[1] !== "time" && props.label.split(",")[1] !== "text" && props.label.split(",")[1] !== "position") ? options.filter(option =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];
    if (props.label.split(",")[1] === "position") {
        let result = props.openPositions.length>1? ['ALL']:[];
        props.openPositions.forEach(item => {
            let pos = item["POS"];
            result.push(`POSITION${pos}`);
        });
        filteredOptions = result;
    } else if (props.label.split(",")[1] === "label") {
        let result = [];
        props.tagNames.forEach(item => {
            result.push(item);
        });
        filteredOptions = result;
    }

    const updateInput = (val, type) => {
        let preData = props.decisionValue;
        if (type == "date") {
            setSelectedDate(val);
            preData[props.mainIndex][props.index] = moment(val).format("MM-DD-YYYY HH:mm");
        } else if (type == "time") {
            setSelectedTime(val);
            preData[props.mainIndex][props.index] = moment(val).format("HH:mm");
        } else {
            preData[props.mainIndex][props.index] = val;
        }
        props.setDecisionValue(preData);
        // setIsValueUpdate(!isValueUpdate);
    }


    const handleSelectChange = (event) => {
        const { options } = event.target;
        const selectedValues = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedValues.push(options[i].value);
            }
        }
        let preData = props.decisionValue;
        preData[props.mainIndex][props.index] = event.target.value;
        props.setDecisionValue(preData);
    };

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState();

    return (
        <>
            <span className='mt-2 pt-2 decition-input-wrapper'>
                <span className={(props.selectedInput?.split(",")[0] == props.index) ? 'show-' : 'hide-'}>
                    <Form.Label sm={2} className='font-weight-bold text-left' style={{ fontWeight: '600', textTransform: "capitalize", position: "absolute", top: "25px" }}>
                        {props.label?.split(",")[0]}
                    </Form.Label>
                    {props.type == 'number' &&
                        <Form.Control type="number" value={props.decisionValue[props.mainIndex][props.index]}
                            onChange={e => updateInput(e.target.value)} placeholder="Enter a number" />}
                </span>
                <span className={(props.selectedInput?.split(",")[0] == props.index) ? 'show-' : 'hide-'}>
                    {props.type === 'search' && <><input
                        type="text"
                        placeholder="Search"
                        size="sm"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        style={{
                            padding: '3px',
                            borderRadius: '5px',
                            border: '1px solid #EAEAEA',
                            width: '30%', display: 'none'
                        }}
                    />
                        <select className='form-control' onChange={e => updateInput(e.target.value)} value={props.decisionValue[props.mainIndex][props.index]}>
                            {filteredOptions.map((option, index) => (
                                <option key={index} value={option} style={{
                                    textTransform: "lowercase"
                                }}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </>
                    }
                </span>
                <span className={(props.selectedInput?.split(",")[0] == props.index) ? 'show-' : 'hide-'}>
                    {props.type == 'multiselect' && <Form.Select
                        multiple
                        value={props.decisionValue[props.mainIndex][props.index]}
                        onChange={handleSelectChange}
                    >
                        {filteredOptions.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </Form.Select>}
                </span>
                <span className={(props.selectedInput?.split(",")[0] == props.index) ? 'show-' : 'hide-'}>
                    {/* {filteredOptions[0]}
                    {props.decisionValue[props.mainIndex][props.index]} */}
                    {props.type == 'select' && <Form.Select
                        value={props.decisionValue[props.mainIndex][props.index]}
                        onChange={e => updateInput(e.target.value)}
                    >
                        <option key="0" value="">
                            Select a vaue
                        </option>
                        {filteredOptions.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </Form.Select>}
                </span>
                <span className={(props.selectedInput?.split(",")[0] == props.index) ? 'show-' : 'hide-'}>
                    {props.type == 'dttm' && <span className='date-time-picker'>
                        <DatePicker
                            selected={selectedDate}
                            // onChange={handleChange}
                            show-TimeSelect
                            timeIntervals={5}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className='form-control date-picker'
                            onChange={e => updateInput(e, "date")}
                        />
                    </span>}
                </span>
                <span className={(props.selectedInput?.split(",")[0] == props.index) ? 'show-' : 'hide-'}>
                    {props.type == 'time' && <span className='date-time-picker'>
                        <TimePicker
                            show-Hour
                            show-Second={false}
                            defaultValue={selectedTime}
                            onChange={e => updateInput(e, "time")}
                        />
                    </span>}
                </span>
                <span className={(props.selectedInput?.split(",")[0] == props.index) ? 'show-' : 'hide-'}>
                    {props.type == 'text' && <span>
                        <input type='text' className='form-control' onChange={(e) => updateInput(e.target.value)} />
                    </span>}
                </span>
            </span>
        </>
    );
};

export default DecisionInputes;
